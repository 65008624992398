<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Записи аудита</h1>
        <h1 v-else id="title-source">Поиск записей аудита</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <NumberField
                        id="search-user-id"
                        labelText="Идентификатор пользователя:"
                        v-model="searchParameters.userId" />
                    <SelectField
                        id="search-method"
                        labelText="Метод:"
                        v-bind:options="methodOptions"
                        v-model="searchParameters.method" />
                    <TextField id="search-path" labelText="Путь:" v-model="searchParameters.path" />
                    <NumberField id="search-status-code" labelText="Ответ:" v-model="searchParameters.statusCode" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button
                        text="Искать"
                        v-bind:useThrobber="false"
                        v-bind:enabled="isSearchValid"
                        v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <AuditEntriesList v-bind:entries="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SelectField from '../../../inputs/SelectField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import TextField from '../../../inputs/TextField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import AuditEntriesList from './AuditEntriesList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'AuditEntriesPage',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        SelectField,
        NumberField,
        TextField,
        ExpandableContent,
        PageController,
        AuditEntriesList,
        RequestWrapper,
    },
    data: function() {
        return {
            methodOptions: [
                { value: undefined, text: 'Любой' },
                { value: 'GET', text: 'GET' },
                { value: 'POST', text: 'POST' },
                { value: 'PUT', text: 'PUT' },
                { value: 'PATCH', text: 'PATCH' },
                { value: 'DELETE', text: 'DELETE' },
            ],
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    computed: {
        isSearchValid: function() {
            return (
                this.searchParameters.userId != undefined ||
                this.searchParameters.method != undefined ||
                this.searchParameters.path != undefined ||
                this.searchParameters.statusCode != undefined
            )
        },
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                userId: Number(this.$route.query.userId) || undefined,
                method: this.$route.query.method || '',
                path: this.$route.query.path || '',
                statusCode: Number(this.$route.query.statusCode) || undefined,
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            let query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/administration/audit/entries', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {}
                    if (this.searchParameters.userId) request.userIdExact = this.searchParameters.userId
                    if (this.searchParameters.method) request.methodExact = this.searchParameters.method
                    if (this.searchParameters.path) request.pathExact = this.searchParameters.path
                    if (this.searchParameters.statusCode) request.statusCodeExact = this.searchParameters.statusCode

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/administration/audit/entries/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            this.searchParameters.method = this.searchParameters.method && this.searchParameters.method.trim()
            this.searchParameters.path = this.searchParameters.path.trim()

            let query = { mode: 'search' }
            if (this.searchParameters.userId) query.userId = this.searchParameters.userId
            if (this.searchParameters.method) query.method = this.searchParameters.method
            if (this.searchParameters.path) query.path = this.searchParameters.path
            if (this.searchParameters.statusCode) query.statusCode = this.searchParameters.statusCode

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>