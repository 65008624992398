<template>
    <div class="button button-container">
        <button
            v-bind:class="{ 'button-throbbing': isThrobbing, destructive: destructive }"
            v-bind:disabled="!enabled"
            v-on:click.prevent="clickHandler">
            <div class="button-layout">
                <img v-if="icon != ''" alt="icon" v-bind:src="require(`../../assets/${icon}`)" />
                <span>{{ text }}</span>
            </div>
        </button>
        <div class="button-result" v-show="successText != '' || failureText != ''">
            <div v-if="successText != ''" class="button-result-success">{{ successText }}</div>
            <div v-if="failureText != ''" class="button-result-failure">{{ failureText }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: String,
        enabled: {
            type: Boolean,
            default: true,
        },
        destructive: {
            type: Boolean,
            default: false,
        },
        useThrobber: {
            type: Boolean,
            default: true,
        },
        icon: {
            type: String,
            default: '',
        },
        successText: {
            type: String,
            default: '',
        },
        failureText: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            isThrobbing: false,
        }
    },
    methods: {
        clickHandler: function() {
            if (this.useThrobber) {
                if (this.isThrobbing) return
                this.isThrobbing = true
            }
            this.$emit('click', () => (this.isThrobbing = false))
        },
    },
}
</script>

<style scoped>
.button-container {
    display: flex;
    align-items: center;
}

.screen-s .button-container {
    flex-direction: column;
}

button {
    flex-shrink: 0;
    min-width: 100px;
    min-height: 38px;
    padding: 0px 25px;
    border: none;
    border-radius: 6px;
    background-color: #396ca3;
    color: #d8e5f2;
    cursor: pointer;
    font-size: inherit;
}

.screen-s button {
    width: 100%;
}

button[disabled] {
    background-color: #232e3b;
    color: #465a70;
    cursor: default;
}

button:not([disabled]):hover,
button:focus {
    background-color: #3d86d4;
    outline: none;
}

button.destructive {
    background-color: #b04038;
    color: #e8d0cf;
}

button.destructive[disabled] {
    background-color: #401a18;
    color: #946360;
}

button.destructive:not([disabled]):hover,
button.destructive:focus {
    background-color: #d1625a;
}

.button-layout {
    display: flex;
    justify-content: center;
    align-items: center;
}

button img {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
}

.button-throbbing {
    color: transparent !important;
    background-image: url('../../assets/inputs/button-throbber.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 70%;
    cursor: progress;
}

.button-throbbing img {
    display: none;
}

.button-result {
    flex-grow: 0;
    margin-left: 20px;
    line-height: 1.1;
}

.screen-s .button-result {
    margin-left: 0px;
    margin: 10px 0px;
}

.button-result-success {
    color: #2a6;
}

.button-result-failure {
    color: #d99;
}
</style>
