<template>
    <div>
        <h1 id="title-source">Детали регистратора #{{ registratorId }}</h1>

        <RequestWrapper
            v-bind:data="registrator"
            v-bind:errorText="registratorErrorText"
            v-bind:errorCode="registratorErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Название">
                        {{ registrator.name }}
                    </ItemDetail>
                    <ItemDetail name="Ссылка на сайт">
                        <ExternalLink v-bind:url="registrator.site" />
                    </ItemDetail>
                    <ItemDetail name="E-mail для связи">
                        <EmailAddressLink v-bind:address="registrator.email" />
                    </ItemDetail>
                </WrappedContent>
                <ItemDetail name="Шаблон ссылки на заказ">
                    {{ registrator.productCheckoutLinkTemplate || '&lt; Не установлена &gt;' }}
                </ItemDetail>
                <ButtonsBlock class="access-level-admin">
                    <Button
                        text="Редактировать"
                        v-on:click="$router.push(`/licensing/registrators/${registratorId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/registrators/${registratorId}/delete`)" />
                </ButtonsBlock>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/orders?mode=search&registratorId=${registratorId}`">
                            Ордеры, сделанные через этого регистратора
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&registratorId=${registratorId}`">
                            Лицензии, выписанные на ордеры, сделанные через этого регистратора
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            v-bind:to="`/licensing/product-mappings?mode=search&registratorId=${registratorId}`">
                            Маппинги на продукты этого регистратора
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/registrators/${registratorId}`">
                            Записи аудита на этого регистратора
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'RegistratorViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        ExternalLink,
        EmailAddressLink,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            registratorId: this.$route.params.id,
            registrator: undefined,
            registratorErrorText: undefined,
            registratorErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/registrators/${this.registratorId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.registrator = data))
                        break
                    default:
                        this.registratorErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.registratorErrorText = error))
    },
}
</script>