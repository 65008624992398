<template>
    <div id="environment">
        <div v-if="environment && environment != 'Production'" class="environment-title">
            {{ environment }}
        </div>
    </div>
</template>

<script>
import { getRequest } from '../../common.js'

export default {
    name: 'Environment',
    data: function() {
        return {
            environment: undefined,
        }
    },
    created: function() {
        getRequest('/api/environment').then(res => {
            switch (res.status) {
                case 200:
                    res.json().then(data => (this.environment = data.environment))
                    break
                default:
                    this.environment = 'Error retrieving environment'
                    break
            }
        })
    },
}
</script>

<style scoped>
#environment {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    line-height: 1.1em;
    border-radius: 5px;
    background-image: repeating-linear-gradient(-45deg, #111, #111 19px, #ffdd01 20px, #ffdd01 39px, #111 40px);
}

.environment-title {
    padding: 0px 10px;
    font-size: 0.9em;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
}
</style>