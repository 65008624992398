<template>
    <div>
        <h1 id="title-source">Отчёты</h1>
        <h2>Лицензирование</h2>
        <ReportersList apiBasePath="/api/licensing/reporters" />
        <h2>Телеметрия</h2>
        <ReportersList apiBasePath="/api/telemetry/reporters" />
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import ReportersList from './ReportersList.vue'

export default {
    name: 'ReportersPage',

    components: {
        ReportersList,
    },

    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>