<template>
    <div>
        <h1 id="title-source">Детали электронного письма #{{ emailId }}</h1>

        <RequestWrapper v-bind:data="email" v-bind:errorText="emailErrorText" v-bind:errorCode="emailErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Дата и время добавления">
                        <DateTime v-bind:dt="email.added" />
                    </ItemDetail>
                    <ItemDetail name="Дата и время обновления статуса">
                        <DateTime v-bind:dt="email.statusChanged" />
                    </ItemDetail>
                    <ItemDetail name="Статус">
                        {{ statusToString(email.status) }}
                    </ItemDetail>
                    <ItemDetail name="Сделано попыток отправки">
                        {{ email.sendAttempts }}
                    </ItemDetail>
                    <ItemDetail name="Тело">
                        <div>HTML: {{ email.bodyInfo.htmlLength }} байт</div>
                        <div>Текст: {{ email.bodyInfo.textLength }} байт</div>
                    </ItemDetail>
                    <ItemDetail name="Вложения">
                        <div v-if="email.attachmentsInfo.length > 0">
                            <div v-for="(attachment, index) in email.attachmentsInfo" v-bind:key="index">
                                {{ attachment.fileName }} ({{ attachment.dataSize }} байт)
                            </div>
                        </div>
                        <div v-else>Отсутствуют</div>
                    </ItemDetail>
                    <ItemDetail name="Приложение-отправитель">
                        {{ email.clientApp }}
                    </ItemDetail>
                </WrappedContent>
                <ItemDetail name="Тема">
                    {{ email.subject }}
                </ItemDetail>
                <ItemDetail name="Отправитель">
                    <EmailAddressLink
                        v-bind:address="email.sender.address"
                        v-bind:displayName="email.sender.displayName" />
                </ItemDetail>
                <ItemDetail name="Получатели" v-if="email.recipients.length > 0">
                    <EmailAddressLink
                        v-for="recipient in email.recipients"
                        v-bind:key="recipient.address"
                        v-bind:address="recipient.address"
                        v-bind:displayName="recipient.displayName" />
                </ItemDetail>
                <ItemDetail name="Получатели (копия)" v-if="email.ccRecipients.length > 0">
                    <EmailAddressLink
                        v-for="recipient in email.ccRecipients"
                        v-bind:key="recipient.address"
                        v-bind:address="recipient.address"
                        v-bind:displayName="recipient.displayName" />
                </ItemDetail>
                <ItemDetail name="Получатели (скрытая копия)" v-if="email.bccRecipients.length > 0">
                    <EmailAddressLink
                        v-for="recipient in email.bccRecipients"
                        v-bind:key="recipient.address"
                        v-bind:address="recipient.address"
                        v-bind:displayName="recipient.displayName" />
                </ItemDetail>
                <ButtonsBlock>
                    <ButtonLink
                        v-bind:href="`/api/notification/emails/${emailId}/eml`"
                        v-bind:download="`Email #${emailId}.eml`"
                        text="Скачать EML" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import DateTime from '../../../misc/DateTime.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import ButtonLink from '../../../inputs/ButtonLink.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'EmailViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        DateTime,
        EmailAddressLink,
        ButtonLink,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            emailId: this.$route.params.id,
            email: undefined,
            emailErrorText: undefined,
            emailErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/notification/emails/${this.emailId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.email = data))
                        break
                    default:
                        this.emailErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.emailErrorText = error))
    },
    methods: {
        statusToString: function(status) {
            switch (status) {
                case 'New':
                    return 'Ожидает отправки'
                case 'Sending':
                    return 'Отправляется'
                case 'Sent':
                    return 'Отправлено'
                case 'Failed':
                    return 'Не удалось отправить'
                default:
                    return status.toLowerCase()
            }
        },

        getEmailAddressTitle: function(address) {
            if (!address.displayName) return address.address
            return `${address.displayName} <${address.address}>`
        },
    },
}
</script>