<template>
    <PopupMenu
        v-if="startIp && endIp"
        v-bind:title="title || `${startIp} – ${endIp}`"
        v-bind:expandedTitle="`ASN-блок ${startIp} – ${endIp}`">
        <router-link v-bind:to="asnBlocksStatsSearchUri">Статистика веб-сессий</router-link>
        <br />
        <router-link v-bind:to="ipsStatsSearchUri">Статистика веб-сессий по IP-адресам</router-link>
        <br />
        <router-link v-bind:to="statsHistoryUri">История статистики веб-сессий</router-link>
        <div class="popup-menu-content-separator"></div>
        <router-link v-bind:to="whitelistSearchUri">Искать в белом списке</router-link>
        <br />
        <router-link v-bind:to="blacklistSearchUri">Искать в чёрном списке</router-link>
        <br />
        <router-link v-bind:to="whitelistCreateUri">Внести в белый список</router-link>
        <br />
        <router-link v-bind:to="blacklistCreateUri">Внести в чёрный список</router-link>
        <div class="popup-menu-content-separator"></div>
        <ExternalLink v-bind:url="ipInfoIoUri" v-bind:text="`${startIp} на ipinfo.io`" />
    </PopupMenu>
</template>

<script>
import PopupMenu from '../../misc/PopupMenu.vue'
import ExternalLink from '../../misc/ExternalLink.vue'

export default {
    name: 'AsnBlockPopupMenu',

    components: {
        PopupMenu,
        ExternalLink,
    },

    props: {
        title: String,
        startIp: String,
        endIp: String,
    },

    data: function() {
        return {
            asnBlocksStatsSearchUri: `/reputation/stats/asn-blocks?mode=search&startIp=${this.startIp}&endIp=${this.endIp}`,
            ipsStatsSearchUri: `/reputation/stats/ips?mode=search&asnBlockStartIp=${this.startIp}&asnBlockEndIp=${this.endIp}`,
            statsHistoryUri: `/reputation/stats/asn-blocks/${this.startIp}-${this.endIp}/history`,
            whitelistSearchUri: `/reputation/lists/ips/white?mode=search&ip=${this.startIp}`,
            blacklistSearchUri: `/reputation/lists/ips/black?mode=search&ip=${this.startIp}`,
            whitelistCreateUri: `/reputation/lists/ips/white?mode=create&startIp=${this.startIp}&endIp=${this.endIp}`,
            blacklistCreateUri: `/reputation/lists/ips/black?mode=create&startIp=${this.startIp}&endIp=${this.endIp}`,
            ipInfoIoUri: `https://ipinfo.io/${this.startIp}`,
        }
    },
}
</script>