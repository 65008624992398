<template>
    <div>
        <ItemsList>
            <div class="item" v-for="product in products" v-bind:key="product.productId">
                <div class="item-details">
                    <span class="item-title">{{ product.name }}</span>
                    <br />
                    Домен:
                    <router-link v-bind:to="`/licensing/product-domains/${product.productDomain.productDomainId}/view`">
                        {{ product.productDomain.name }}
                    </router-link>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/products/${product.productId}/view`">Подробнее</router-link>
                    <router-link
                        v-bind:to="`/licensing/products/${product.productId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/products/${product.productId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'

export default {
    name: 'ProductsList',
    components: {
        ItemsList,
    },
    props: {
        products: {
            type: Array,
            default: undefined,
        },
    },
}
</script>