var loginRegex = new RegExp('^[\\w_\\.]{4,32}$')
var emailRegex = new RegExp('^.+@.+\\..+$')
var urlRegex = new RegExp('^(https|http):\\/\\/[\\S]+$')

export { loginRegex, urlRegex, emailRegex }

export function getRequest(url) {
    return fetch(
        url,
        {
            method: 'GET'
        })
}

export function postRequest(url, body) {
    return fetch(
        url,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
}

export function putRequest(url, body) {
    return fetch(
        url,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
}

export function patchRequest(url, body) {
    return fetch(
        url,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
}

export function deleteRequest(url) {
    return fetch(
        url,
        {
            method: 'DELETE'
        })
}

function checkUnauthorized(promise) {
    return promise
        .then(res => {
            if (res.status !== 401) return res

            // может быть несколько запросов одновременно,
            // но редирект на форму входа должен быть один
            if (location.pathname !== '/signin') {
                res.json().then(data => {
                    var query = {}
                    if (location.pathname != '/') query.returnUrl = location.pathname + location.search
                    if (data.reason == 'SessionExpired') query.reason = 'expired'
                    location.href = buildUrl('/signin', query)
                })
            }

            throw 'API responded with 401 Unauthorized'
        })
}

export function getAuthorizedRequest(url) {
    return checkUnauthorized(getRequest(url))
}

export function postAuthorizedRequest(url, body) {
    return checkUnauthorized(postRequest(url, body))
}

export function putAuthorizedRequest(url, body) {
    return checkUnauthorized(putRequest(url, body))
}

export function patchAuthorizedRequest(url, body) {
    return checkUnauthorized(patchRequest(url, body))
}

export function deleteAuthorizedRequest(url) {
    return checkUnauthorized(deleteRequest(url))
}

export function buildUrl(url, params) {
    if (params === undefined) return url
    if (Object.keys(params) == 0) return url
    let query = Object.keys(params)
        .map(k => `${k}=${encodeURI(params[k])}`)
        .join('&')
    return `${url}?${query}`
}

export function userStatusToString(status) {
    switch (status) {
        case 'Normal': return 'Нормальный'
        case 'Disabled': return 'Отключен'
        default: return status
    }
}

export function userRoleToString(role) {
    switch (role) {
        case 'Admin': return 'Администратор'
        case 'Support': return 'Саппорт'
        case 'Viewer': return 'Наблюдатель'
        default: return role
    }
}

export function orderStatusToString(status) {
    switch (status) {
        case 'Normal': return 'Нормальный'
        case 'Chargeback': return 'Чарджбэк'
        case 'Refund': return 'Рефанд'
        case 'PartialRefund': return 'Частичный рефанд'
        default: return status
    }
}

export function customerLanguageToString(language) {
    switch (language) {
        case 'Arabic': return 'Арабский'
        case 'Chinese': return 'Китайский'
        case 'Danish': return 'Датский'
        case 'Dutch': return 'Нидерландский'
        case 'English': return 'Английский'
        case 'Finnish': return 'Финский'
        case 'French': return 'Французский'
        case 'German': return 'Немецкий'
        case 'Greek': return 'Греческий'
        case 'Hebrew': return 'Иврит'
        case 'Hindi': return 'Хинди (Индийский)'
        case 'Indonesian': return 'Индонезийский'
        case 'Italian': return 'Итальянский'
        case 'Japanese': return 'Японский'
        case 'Korean': return 'Корейский'
        case 'Malay': return 'Малайский'
        case 'Norwegian': return 'Норвежский'
        case 'Polish': return 'Польский'
        case 'Portuguese': return 'Португальский'
        case 'Romanian': return 'Румынский'
        case 'Russian': return 'Русский'
        case 'Spanish': return 'Испанский'
        case 'Swedish': return 'Шведский'
        case 'Thai': return 'Тайский'
        case 'Turkish': return 'Турецкий'
        case 'Vietnamese': return 'Вьетнамский'
        default: return language
    }
}

export function isValidDateTime(s) {
    var timestamp = Date.parse(s)
    return !isNaN(timestamp)
}

export function validateLogin(login) {
    if (!login) {
        return 'Введите логин'
    }
    else if (login.length < 4 || login.length > 32) {
        return 'От 4 до 32 символов'
    }
    else if (!loginRegex.test(login)) {
        return 'Только латинские буквы, цифры, _ и точка'
    }

    return ''
}

export function validatePassword(password) {
    if (!password.trim()) {
        return 'Введите пароль'
    }
    else if (password.length < 8 || password.length > 72) {
        return 'От 8 до 72 символов'
    }
    else if ((password.match('[0-9]') || []).length === 0 ||
        (password.match('[a-z]') || []).length === 0 ||
        (password.match('[A-Z]') || []).length === 0) {
        return 'Слишком просто'
    }

    return ''
}

export function validateEmail(email) {
    if (!email) {
        return 'Введите e-mail'
    }
    else if (!emailRegex.test(email)) {
        return 'Некорректный формат'
    }

    return ''
}

export function validateUrl(url) {
    if (!url) {
        return 'Введите ссылку'
    }
    else if (!urlRegex.test(url)) {
        return 'Некорректный формат'
    }

    return ''
}

export function getItemsPageSize() {
    return localStorage.getItem('itemsPageSize') || 20
}

export function setItemsPageSize(value) {
    localStorage.setItem('itemsPageSize', value)
}

export function updateTitle() {
    var el = document.getElementById('title-source')
    document.title = el ? el.innerText : 'BackOffice'
}

export function getScreenSize() {
    var width = window.innerWidth
    if (width >= 1100) return 'l'
    else if (width > 750) return 'm'
    else return 's'
}

export function signout(router) {
    postAuthorizedRequest('/api/auth/signout', undefined)
        .then(res => {
            if (res.ok) router.push('/signin')
        })
}

export function truncateString(s, len) {
    if (s.length > len) {
        return s.slice(0, len) + "..."
    } else {
        return s
    }
}

export function getRandomHexString(len) {
    const bytes = crypto.getRandomValues(new Uint8Array(len))
    const array = Array.from(bytes)
    const hexPairs = array.map(b => b.toString(16).padStart(2, '0'))
    return hexPairs.join('')
}

var userInfo = {
    profile: undefined,
    isAdmin: function () {
        return this.profile != undefined && this.profile.role == 'Admin'
    },
    isAdminOrSupport: function () {
        return this.profile != undefined && (this.profile.role == 'Admin' || this.profile.role == 'Support')
    },
    load: function () {
        getAuthorizedRequest('/api/me')
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => this.profile = data)
                        break
                    default:
                        this.$router.replace(`/error/${res.status}`)
                        break
                }
            })
    }
}

export { userInfo }