<template>
    <div class="web-session-compare-widget">
        <a v-if="!isAdded" v-on:click="add" class="compare add">Сравнить отпечаток</a>
        <a v-else v-on:click="remove" class="compare remove">Убрать из сравнения</a>
        <router-link v-if="isGoVisible" to="/telemetry/web-sessions/compare" class="compare go"
            >Открыть сравнение</router-link
        >
    </div>
</template>

<script>
import {
    getIsSessionCompareAdded,
    getIsSessionsCompareAvailable,
    addSessionCompare,
    removeSessionCompare,
} from './compare-fingerprints.js'

export default {
    name: 'CompareWidget',

    props: {
        webSessionId: {
            type: Number,
            required: true,
        },
    },

    data: function() {
        let added = getIsSessionCompareAdded(this.webSessionId)

        return {
            isAdded: added,
            isGoVisible: added && getIsSessionsCompareAvailable(),
        }
    },

    methods: {
        add: function() {
            addSessionCompare(this.webSessionId)
            this.isAdded = true
            this.isGoVisible = getIsSessionsCompareAvailable()
        },

        remove: function() {
            removeSessionCompare(this.webSessionId)
            this.isAdded = false
            this.isGoVisible = false
        },
    },
}
</script>

<style scoped>
.web-session-compare-widget {
    display: flex;
    flex-wrap: wrap;
}

a {
    display: inline-block;
    cursor: pointer;
}

a:not(:last-child) {
    margin-right: 20px;
}

a.compare {
    padding-left: 22px;
    background-position: left center;
    background-size: 1em auto;
    background-repeat: no-repeat;
}

a.add {
    background-image: url('../../../../assets/telemetry/web-sessions/session-compare-add.svg');
}

a.remove {
    background-image: url('../../../../assets/telemetry/web-sessions/session-compare-remove.svg');
}

a.go {
    background-image: url('../../../../assets/telemetry/web-sessions/session-compare-go.svg');
}
</style>