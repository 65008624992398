<template>
    <PopupMenu v-if="asnId" v-bind:title="title || `ASN ${asnId}`" v-bind:expandedTitle="`ASN ${asnId}`">
        <router-link v-bind:to="asnsStatsSearchUri">Статистика веб-сессий</router-link>
        <br />
        <router-link v-bind:to="asnBlocksStatsSearchUri">Статистика веб-сессий по блокам</router-link>
        <br />
        <router-link v-bind:to="ipsStatsSearchUri">Статистика веб-сессий по IP-адресам</router-link>
        <br />
        <router-link v-bind:to="statsHistoryUri">История статистики веб-сессий</router-link>
        <div class="popup-menu-content-separator"></div>
        <router-link v-bind:to="whitelistSearchUri">Искать в белом списке</router-link>
        <br />
        <router-link v-bind:to="blacklistSearchUri">Искать в чёрном списке</router-link>
        <br />
        <router-link v-bind:to="whitelistCreateUri">Внести в белый список</router-link>
        <br />
        <router-link v-bind:to="blacklistCreateUri">Внести в чёрный список</router-link>
        <div class="popup-menu-content-separator"></div>
        <router-link v-bind:to="ipInfoUri">Посмотреть IP-информацию</router-link>
    </PopupMenu>
</template>

<script>
import PopupMenu from '../../misc/PopupMenu.vue'

export default {
    name: 'AsnPopupMenu',

    components: {
        PopupMenu,
    },

    props: {
        title: String,
        asnId: Number,
    },

    data: function() {
        return {
            asnsStatsSearchUri: `/reputation/stats/asns?mode=search&asnId=${this.asnId}`,
            asnBlocksStatsSearchUri: `/reputation/stats/asn-blocks?mode=search&asnId=${this.asnId}`,
            ipsStatsSearchUri: `/reputation/stats/ips?mode=search&asnId=${this.asnId}`,
            statsHistoryUri: `/reputation/stats/asns/${this.asnId}/history`,
            whitelistSearchUri: `/reputation/lists/asns/white?mode=search&asnId=${this.asnId}`,
            blacklistSearchUri: `/reputation/lists/asns/black?mode=search&asnId=${this.asnId}`,
            whitelistCreateUri: `/reputation/lists/asns/white?mode=create&asnId=${this.asnId}`,
            blacklistCreateUri: `/reputation/lists/asns/black?mode=create&asnId=${this.asnId}`,
            ipInfoUri: `/ipinfo/asns/${this.asnId}`,
        }
    },
}
</script>