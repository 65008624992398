<template>
    <div>
        <ItemsList>
            <div class="item" v-for="licenseType in licenseTypes" v-bind:key="licenseType.licenseTypeId">
                <div class="item-details">
                    <div class="license-type-sublicense" v-if="licenseType.isSubLicense">SubLicense</div>
                    <span class="item-title">{{ licenseType.name }}</span>
                    <br />
                    Срок действия:
                    <span v-if="licenseType.expirationPeriodDays != null"
                        >{{ licenseType.expirationPeriodDays }} дней</span
                    >
                    <span v-else>не ограничен</span>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/license-types/${licenseType.licenseTypeId}/view`"
                        >Подробнее</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/license-types/${licenseType.licenseTypeId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/license-types/${licenseType.licenseTypeId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'

export default {
    name: 'LicenseTypesList',
    components: {
        ItemsList,
    },
    props: {
        licenseTypes: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.license-type-sublicense {
    color: #d49f35;
}
</style>