<template>
    <div>
        <h1 id="title-source">IP-адрес {{ ipAddress }}</h1>

        <RequestWrapper v-bind:data="ip" v-bind:errorText="ipErrorText" v-bind:errorCode="ipErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="ASN">
                        <span v-if="ip.asn.status == 'Available'">
                            <AsnPopupMenu v-if="ip.asn.data" v-bind:asnId="ip.asn.data.asnId" />
                            <span v-else> &lt; Не найден &gt; </span>
                        </span>
                        <span v-else>
                            <i>&lt; Информация по ASN недоступна &gt;</i>
                        </span>
                    </ItemDetail>
                    <ItemDetail name="Блок">
                        <span v-if="ip.asnBlock.status == 'Available'">
                            <AsnBlockPopupMenu
                                v-if="ip.asnBlock.data"
                                v-bind:startIp="ip.asnBlock.data.startIpAddress"
                                v-bind:endIp="ip.asnBlock.data.endIpAddress" />
                            <span v-else> &lt; Не найден &gt; </span>
                        </span>
                        <span v-else>
                            <i>&lt; Информация по ASN недоступна &gt;</i>
                        </span>
                    </ItemDetail>
                    <ItemDetail name="Организация">
                        <span v-if="ip.asn.status == 'Available'">
                            {{ (ip.asn.data && ip.asn.data.organization) || '&lt; Не найдена &gt;' }}
                        </span>
                        <span v-else>
                            <i>&lt; Информация по ASN недоступна &gt;</i>
                        </span>
                    </ItemDetail>
                </WrappedContent>

                <h2>Геолокация</h2>
                <WrappedContent>
                    <ItemDetail name="Страна">
                        <span v-if="ip.country.status == 'Available'">
                            {{
                                (ip.country.data &&
                                    `${ip.country.data.countryName} (${ip.country.data.countryCode})`) ||
                                '&lt; Не найдена &gt;'
                            }}
                        </span>
                        <span v-else>
                            <i>&lt; Информация по странам недоступна &gt;</i>
                        </span>
                    </ItemDetail>
                    <ItemDetail name="Город">
                        <span v-if="ip.city.status == 'Available'">
                            {{ (ip.city.data && ip.city.data.cityName) || '&lt; Не найден &gt;' }}
                        </span>
                        <span v-else>
                            <i>&lt; Информация по городам недоступна &gt;</i>
                        </span>
                    </ItemDetail>
                </WrappedContent>

                <IpAddressMenu v-bind:ip="ipAddress" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import ItemDetail from '../ItemDetail.vue'
import AsnPopupMenu from '../reputation/AsnPopupMenu.vue'
import AsnBlockPopupMenu from '../reputation/AsnBlockPopupMenu.vue'
import IpAddressMenu from '../reputation/IpAddressMenu.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../common.js'

export default {
    name: 'IpAddressViewPage',

    components: {
        WrappedContent,
        ItemDetail,
        AsnPopupMenu,
        AsnBlockPopupMenu,
        IpAddressMenu,
        RequestWrapper,
    },

    data: function() {
        return {
            ipAddress: this.$route.params.ip,
            ip: undefined,
            ipErrorText: undefined,
            ipErrorCode: undefined,
        }
    },

    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/ipinfo/ips/${this.ipAddress}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.ip = data))
                        break
                    default:
                        this.ipErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.ipErrorText = error))
    },
}
</script>