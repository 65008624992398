<template>
    <div v-if="ip">
        <h2>Меню IP-адреса</h2>
        <ul v-if="path != reputationStatusUri">
            <li>
                <router-link v-bind:to="reputationStatusUri">Статус репутации</router-link>
            </li>
        </ul>
        <ul>
            <li>
                <router-link v-bind:to="webSessionsSearchUri">Веб-сессии</router-link>
            </li>
            <li>
                <router-link v-bind:to="ipsStatsSearchUri">Статистика веб-сессий</router-link>
            </li>
            <li>
                <router-link v-bind:to="statsHistoryUri">История статистики веб-сессий</router-link>
            </li>
        </ul>
        <ul>
            <li>
                <router-link v-bind:to="whitelistSearchUri">Искать в белом списке</router-link>
            </li>
            <li>
                <router-link v-bind:to="blacklistSearchUri">Искать в чёрном списке</router-link>
            </li>
            <li>
                <router-link v-bind:to="whitelistCreateUri">Внести в белый список</router-link>
            </li>
            <li>
                <router-link v-bind:to="blacklistCreateUri">Внести в чёрный список</router-link>
            </li>
        </ul>
        <ul>
            <li v-if="path != ipInfoUri">
                <router-link v-bind:to="ipInfoUri">Посмотреть IP-информацию</router-link>
            </li>
            <li>
                <ExternalLink v-bind:url="ipInfoIoUri" v-bind:text="`Посмотреть на ipinfo.io`" />
            </li>
        </ul>
    </div>
</template>

<script>
import ExternalLink from '../../misc/ExternalLink.vue'

export default {
    name: 'IpAddressMenu',

    components: {
        ExternalLink,
    },

    props: {
        ip: String,
    },

    data: function() {
        return {
            reputationStatusUri: `/reputation/statuses/ips/${this.ip}`,
            webSessionsSearchUri: `/telemetry/web-sessions?mode=search&ip=${this.ip}`,
            ipsStatsSearchUri: `/reputation/stats/ips?mode=search&ip=${this.ip}`,
            statsHistoryUri: `/reputation/stats/ips/${this.ip}/history`,
            whitelistSearchUri: `/reputation/lists/ips/white?mode=search&ip=${this.ip}`,
            blacklistSearchUri: `/reputation/lists/ips/black?mode=search&ip=${this.ip}`,
            whitelistCreateUri: `/reputation/lists/ips/white?mode=create&startIp=${this.ip}&endIp=${this.ip}`,
            blacklistCreateUri: `/reputation/lists/ips/black?mode=create&startIp=${this.ip}&endIp=${this.ip}`,
            ipInfoUri: `/ipinfo/ips/${this.ip}`,
            ipInfoIoUri: `https://ipinfo.io/${this.ip}`,
            path: window.location.pathname,
        }
    },
}
</script>