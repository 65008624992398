<template>
    <div>
        <RequestWrapper
            v-bind:data="maintainers"
            v-bind:errorText="maintainersErrorText"
            v-bind:errorCode="maintainersErrorCode">
            <template v-slot:default>
                <div v-if="maintainers.length > 0">
                    <WrappedContent>
                        <Maintainer
                            v-for="maintainer in maintainers"
                            v-bind:key="maintainer.name"
                            v-bind:maintainer="maintainer"
                            v-bind:apiBasePath="`${apiBasePath}/${maintainer.name}`" />
                    </WrappedContent>
                </div>
                <div v-else>
                    <i>Нет фоновых служб.</i>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Maintainer from './Maintainer.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../common.js'

export default {
    name: 'MaintainersList',

    components: {
        Maintainer,
        WrappedContent,
        RequestWrapper,
    },

    props: {
        apiBasePath: {
            type: String,
            required: true,
        },
    },

    data: function() {
        return {
            maintainers: undefined,
            maintainersErrorText: undefined,
            maintainersErrorCode: undefined,
            updateIntervalId: undefined,
        }
    },

    mounted: function() {
        if (!this.maintainers) this.updateMaintainers()
        this.startUpdateLoop()
        document.addEventListener('visibilitychange', this.documentVisibilityChangeHandler)
    },

    beforeDestroy: function() {
        this.stopUpdateLoop()
        document.removeEventListener('visibilitychange', this.documentVisibilityChangeHandler)
    },

    methods: {
        documentVisibilityChangeHandler: function() {
            switch (document.visibilityState) {
                case 'visible':
                    this.startUpdateLoop()
                    break
                case 'hidden':
                    this.stopUpdateLoop()
                    break
            }
        },

        startUpdateLoop: function() {
            if (this.updateIntervalId) return
            this.updateIntervalId = setInterval(this.updateMaintainers, 5000)
        },

        stopUpdateLoop: function() {
            if (!this.updateIntervalId) return
            clearInterval(this.updateIntervalId)
            this.updateIntervalId = undefined
        },

        updateMaintainers: function() {
            getAuthorizedRequest(this.apiBasePath)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.maintainers = data
                                this.maintainersErrorText = undefined
                                this.maintainersErrorCode = undefined
                            })
                            break
                        default:
                            this.maintainersErrorText = undefined
                            this.maintainersErrorCode = res.status
                            break
                    }
                })
                .catch(error => {
                    this.maintainersErrorText = error
                    this.maintainersErrorCode = undefined
                })
        },
    },
}
</script>