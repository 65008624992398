<template>
    <div>
        <h1 v-if="mode != 'search'" id="title-source">{{ viewTitle }}</h1>
        <h1 v-else id="title-source">{{ searchTitle }}</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <NumberField
                        id="search-asn-id"
                        v-bind:min="1"
                        v-bind:max="2147483647"
                        labelText="ASN:"
                        v-model="searchParameters.asnIdExact" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button
                        text="Искать"
                        v-bind:useThrobber="false"
                        v-bind:enabled="isSearchValid"
                        v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <ExpandableContent v-bind:expanded="mode == 'create'" summaryText="Добавить ASN">
            <ListedAsnItem
                v-bind:listType="listType"
                v-bind:item="newItem"
                mode="create"
                v-on:create="itemCreateHandler" />
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <ListedAsnsList v-bind:listType="listType" v-bind:items="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import NumberField from '../../../inputs/NumberField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import ListedAsnsList from './ListedAsnsList.vue'
import ListedAsnItem from './ListedAsnItem.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'

import {
    getAuthorizedRequest,
    postAuthorizedRequest,
    buildUrl,
    getItemsPageSize,
    setItemsPageSize,
} from '../../../../common.js'

export default {
    name: 'ListedAsnsPage',

    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        NumberField,
        ExpandableContent,
        PageController,
        ListedAsnsList,
        ListedAsnItem,
        RequestWrapper,
    },

    data: function() {
        var listType = this.$route.params.listType

        var viewTitle = undefined
        var searchTitle = undefined

        switch (listType) {
            case 'white':
                viewTitle = 'Белый список ASN'
                searchTitle = 'Поиск по белому списку ASN'
                break
            case 'black':
                viewTitle = 'Чёрный список ASN'
                searchTitle = 'Поиск по чёрному списку ASN'
                break
        }

        return {
            listType: listType,
            viewTitle: viewTitle,
            searchTitle: searchTitle,
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
            apiPathBase: `/api/reputation/lists/asns/${listType}`,
            newItem: {},
        }
    },

    computed: {
        isSearchValid: function() {
            return this.searchParameters.asnIdExact != undefined
        },
    },

    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },

    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },

    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'

            if (this.mode == 'search') {
                this.searchParameters = {
                    asnIdExact: Number(this.$route.query.asnId) || undefined,
                }
            } else if (this.mode == 'create') {
                this.newItem = {
                    enabled: true,
                    asnId: Number(this.$route.query.asnId) || undefined,
                }
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            var query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                case 'create':
                    getAuthorizedRequest(buildUrl(this.apiPathBase, query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {}
                    if (this.searchParameters.asnIdExact) request.asnIdExact = this.searchParameters.asnIdExact

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl(`${this.apiPathBase}/search`, query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            let query = {
                mode: 'search',
                asnId: undefined,
            }

            if (this.searchParameters.asnIdExact) query.asnId = this.searchParameters.asnIdExact

            this.$router.push({ path: this.$route.path, query: query })
        },

        itemCreateHandler: function(item) {
            this.itemsPagination.items.splice(0, 0, item)
        },
    },
}
</script>