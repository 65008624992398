export function getSessionVerdict(session) {
    if (session.isFair === true) return 'fair'
    if (session.isFair === false) return 'unfair'
    return 'none'
}

export function getSessionVerdictTitle(session) {
    if (session.isFair === true) return 'Сессия считается честной'
    if (session.isFair === false) return 'Сессия считается зловредной'
    return 'Сессия ещё не проанализирована'
}

export function getSessionVerdictString(session) {
    if (session.isFair === true) return 'Честная'
    if (session.isFair === false) return 'Зловредная'
    return 'Ещё не проанализирована'
}