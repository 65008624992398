<template>
    <component v-bind:is="layout" id="app">
        <router-view v-bind:layout.sync="layout" />
    </component>
</template>

<script>
export default {
    name: 'App',
    data: function() {
        return {
            layout: 'div',
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        sizeClass: function() {
            if (this.windowWidth <= 750) return 'screen-s'
            if (this.windowWidth > 750 && this.windowWidth < 1100) return 'screen-m'
            return 'screen-l'
        },
    },
    mounted: function() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResizeHandler)
        })

        document.body.className = this.sizeClass
    },
    beforeDestroy: function() {
        window.removeEventListener('resize', this.windowResizeHandler)
    },
    methods: {
        windowResizeHandler: function() {
            this.windowWidth = window.innerWidth
            document.body.className = this.sizeClass
        },
    },
}
</script>

<style>
body {
    background-color: #111;
}

#app {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #999;
}

a {
    color: #718dab;
    text-decoration: none;
}

a:hover {
    color: #99bade;
    cursor: pointer;
}

h1,
h2,
h3 {
    color: #ccc;
}

.screen-s h1,
.screen-s h2,
.screen-s h3 {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

ul {
    padding-left: 20px;
    list-style-type: square;
}

.hidden {
    display: none;
}

.screen-l::-webkit-scrollbar,
.screen-l *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
}

/* скролл-бар страницы */
.screen-l::-webkit-scrollbar-track {
    background-color: #282828;
}

/* скролл-бар прокручиваемых элементов внутри страницы */
.screen-l *::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #181818;
}

.screen-l::-webkit-scrollbar-thumb,
.screen-l *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #405061;
}

.screen-l::-webkit-scrollbar-corner,
.screen-l *::-webkit-scrollbar-corner {
    background-color: transparent;
    border-color: transparent;
}
</style>