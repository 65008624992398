<template>
    <div>
        <ItemsList>
            <div class="item" v-for="customer in customers" v-bind:key="customer.customerId">
                <div class="item-date">
                    <DateTime v-bind:dt="customer.added" />
                </div>
                <div class="item-details">
                    <span class="item-title">{{ customer.email }}</span>
                    <span v-if="customer.name != undefined">
                        <br />
                        Имя: {{ customer.name }}
                    </span>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/customers/${customer.customerId}/view`">Подробнее</router-link>
                    <router-link
                        v-bind:to="`/licensing/customers/${customer.customerId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/customers/${customer.customerId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'

export default {
    name: 'CustomersList',
    components: {
        ItemsList,
        DateTime,
    },
    props: {
        customers: {
            type: Array,
            default: undefined,
        },
    },
}
</script>