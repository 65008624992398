<template>
    <div class="wrapped-content">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'WrappedContent',
}
</script>

<style scoped>
.wrapped-content {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
}

.wrapped-content > * {
    align-self: stretch;
    width: calc(50% - 5px);
    margin-bottom: 5px;
}

.screen-l .wrapped-content > *:nth-child(odd),
.screen-m .wrapped-content > *:nth-child(odd) {
    margin-right: 10px;
}

.screen-s .wrapped-content > * {
    width: 100%;
    margin-right: 0px;
}
</style>
