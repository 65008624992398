<template>
    <div>
        <h1 id="title-source">
            Удаление
            <router-link v-bind:to="`/licensing/product-domains/${productDomainId}/view`">
                домена продуктов #{{ productDomainId }}
            </router-link>
        </h1>

        <RequestWrapper
            v-bind:data="productDomain"
            v-bind:errorText="productDomainErrorText"
            v-bind:errorCode="productDomainErrorCode">
            <template v-slot:default>
                <ul>
                    <li>
                        <RequestInlineWrapper
                            loadingText="Поиск связанных ордеров..."
                            v-bind:data="linkedOrders"
                            v-bind:errorText="linkedOrdersErrorText"
                            v-bind:errorCode="linkedOrdersErrorCode">
                            <template v-slot:default>
                                <span v-if="linkedOrders.itemsCount > 0">
                                    Найдено ордеров с продуктами из этого домена:
                                    {{ linkedOrders.itemsCount }} (<router-link
                                        v-bind:to="`/licensing/orders?mode=search&productDomainId=${productDomainId}`"
                                        target="_blank"
                                        >посмотреть</router-link
                                    >)
                                </span>
                                <span v-else> Не найдено ордеров с продуктами из этого домена </span>
                            </template>
                        </RequestInlineWrapper>
                    </li>
                    <li>
                        <RequestInlineWrapper
                            loadingText="Поиск связанных лицензий..."
                            v-bind:data="linkedLicenses"
                            v-bind:errorText="linkedLicensesErrorText"
                            v-bind:errorCode="linkedLicensesErrorCode">
                            <template v-slot:default>
                                <span v-if="linkedLicenses.itemsCount > 0">
                                    Найдено выписанных лицензий на продукты из этого домена:
                                    {{ linkedLicenses.itemsCount }} (<router-link
                                        v-bind:to="`/licensing/licenses?mode=search&productDomainId=${productDomainId}`"
                                        target="_blank"
                                        >посмотреть</router-link
                                    >)
                                </span>
                                <span v-else> Не найдено выписанных лицензий на продукты из этого домена </span>
                            </template>
                        </RequestInlineWrapper>
                    </li>
                    <li>
                        <RequestInlineWrapper
                            loadingText="Поиск связанных продуктов..."
                            v-bind:data="linkedProducts"
                            v-bind:errorText="linkedProductsErrorText"
                            v-bind:errorCode="linkedProductsErrorCode">
                            <template v-slot:default>
                                <span v-if="linkedProducts.itemsCount > 0">
                                    Найдено продуктов из этого домена: {{ linkedProducts.itemsCount }} (<router-link
                                        v-bind:to="`/licensing/products?mode=search&productDomainId=${productDomainId}`"
                                        target="_blank"
                                        >посмотреть</router-link
                                    >)
                                </span>
                                <span v-else> Не найдено продуктов из этого домена </span>
                            </template>
                        </RequestInlineWrapper>
                    </li>
                    <li>
                        <RequestInlineWrapper
                            loadingText="Поиск связанных маппингов продуктов..."
                            v-bind:data="linkedProductMappings"
                            v-bind:errorText="linkedProductMappingsErrorText"
                            v-bind:errorCode="linkedProductMappingsErrorCode">
                            <template v-slot:default>
                                <span v-if="linkedProductMappings.itemsCount > 0">
                                    Найдено маппингов продуктов с продуктами из этого домена:
                                    {{ linkedProductMappings.itemsCount }} (<router-link
                                        v-bind:to="`/licensing/product-mappings?mode=search&productDomainId=${productDomainId}`"
                                        target="_blank"
                                        >посмотреть</router-link
                                    >)
                                </span>
                                <span v-else> Не найдено маппингов продуктов с продуктами из этого домена </span>
                            </template>
                        </RequestInlineWrapper>
                    </li>
                </ul>
                <Warning v-if="totalLinkedItems > 0">
                    При удалении домена продуктов все перечисленные связанные с ним объекты будут также удалены.
                </Warning>
                <ButtonsBlock>
                    <Button
                        text="Подтверждаю удаление"
                        destructive
                        v-bind:failureText="deleteFailure"
                        v-on:click="performDelete" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import RequestInlineWrapper from '../../../misc/RequestInlineWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, deleteAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductDomainDeletePage',
    components: {
        Warning,
        Button,
        ButtonsBlock,
        RequestWrapper,
        RequestInlineWrapper,
    },
    data: function() {
        return {
            productDomainId: this.$route.params.id,
            productDomain: undefined,
            productDomainErrorText: undefined,
            productDomainErrorCode: undefined,
            linkedOrders: undefined,
            linkedOrdersErrorText: undefined,
            linkedOrdersErrorCode: undefined,
            linkedLicenses: undefined,
            linkedLicensesErrorText: undefined,
            linkedLicensesErrorCode: undefined,
            linkedProducts: undefined,
            linkedProductsErrorText: undefined,
            linkedProductsErrorCode: undefined,
            linkedProductMappings: undefined,
            linkedProductMappingsErrorText: undefined,
            linkedProductMappingsErrorCode: undefined,
            deleteFailure: '',
        }
    },
    computed: {
        totalLinkedItems: function() {
            let total = 0
            if (this.linkedOrders != undefined) total += this.linkedOrders.itemsCount
            if (this.linkedLicenses != undefined) total += this.linkedLicenses.itemsCount
            if (this.linkedProducts != undefined) total += this.linkedProducts.itemsCount
            if (this.linkedProductMappings != undefined) total += this.linkedProductMappings.itemsCount
            return total
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/product-domains/${this.productDomainId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.productDomain = data))
                        break
                    default:
                        this.productDomainErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productDomainErrorText = error))

        let searchRequest = { productDomainIdExact: Number(this.productDomainId) }

        postAuthorizedRequest('/api/licensing/orders/search?pageSize=1', searchRequest)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.linkedOrders = data))
                        break
                    default:
                        this.linkedOrdersErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.linkedOrdersErrorText = error))

        postAuthorizedRequest('/api/licensing/licenses/search?pageSize=1', searchRequest)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.linkedLicenses = data))
                        break
                    default:
                        this.linkedLicensesErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.linkedLicensesErrorText = error))

        postAuthorizedRequest('/api/licensing/products/search?pageSize=1', searchRequest)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.linkedProducts = data))
                        break
                    default:
                        this.linkedProductsErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.linkedProductsErrorText = error))

        postAuthorizedRequest('/api/licensing/product-mappings/search?pageSize=1', searchRequest)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.linkedProductMappings = data))
                        break
                    default:
                        this.linkedProductMappingsErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.linkedProductMappingsErrorText = error))
    },
    methods: {
        performDelete: function(callback) {
            this.deleteFailure = ''

            deleteAuthorizedRequest(`/api/licensing/product-domains/${this.productDomainId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.$router.push('/licensing/product-domains')
                            break
                        default:
                            this.deleteFailure = `Плохой ответ на запрос (${res.status})`
                            callback()
                            break
                    }
                })
                .catch(() => (this.deleteFailure = 'Не удалось выполнить запрос'))
        },
    },
}
</script>