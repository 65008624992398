<template>
    <div class="recaptcha-wrapper">
        <div class="recaptcha-container">
            <VueRecaptcha
                v-bind:sitekey="sitekey"
                loadRecaptchaScript
                theme="dark"
                language="ru"
                v-on:verify="recaptchaVerify"
                v-on:expired="recaptchaExpired" />
            <FieldNotifications v-bind:errorText="errorText" />
        </div>
        <!-- пустое место, чтобы выровнять рекаптчу и ошибку по горизонтали -->
        <div />
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import FieldNotifications from './FieldNotifications.vue'

export default {
    name: 'Recaptcha',
    props: {
        sitekey: String,
        errorText: {
            type: String,
            default: '',
        },
    },
    components: {
        VueRecaptcha,
        FieldNotifications,
    },
    created: function() {
        this.$emit('input', '')
    },
    methods: {
        recaptchaVerify: function(response) {
            this.$emit('input', response)
        },
        recaptchaExpired: function() {
            this.$emit('input', '')
        },
    },
}
</script>

<style scoped>
.recaptcha-wrapper {
    display: flex;
}
</style>