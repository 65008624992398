<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание домена продуктов</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/product-domains/${productDomainId}/view`"
                >домена продуктов #{{ productDomainId }}</router-link
            >
        </h1>

        <RequestWrapper
            v-bind:data="productDomain"
            v-bind:errorText="productDomainErrorText"
            v-bind:errorCode="productDomainErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="product-domain-name"
                            labelText="Название:"
                            v-bind:errorText="nameValidationError"
                            required
                            v-model="productDomain.name" />
                        <TextField
                            id="product-domain-company-name"
                            labelText="Название компании:"
                            v-bind:errorText="companyNameValidationError"
                            required
                            v-model="productDomain.companyName" />
                        <TextField
                            id="product-domain-site"
                            labelText="Сайт:"
                            v-bind:errorText="siteValidationError"
                            required
                            v-model="productDomain.site" />
                        <TextField
                            id="product-domain-support-link"
                            labelText="Страница тех. поддержки:"
                            v-bind:errorText="supportLinkValidationError"
                            v-model="productDomain.supportLink" />
                        <TextField
                            id="product-domain-notification-email"
                            labelText="E-mail отправителя уведомлений:"
                            v-bind:errorText="notificationEmailValidationError"
                            required
                            v-model="productDomain.notificationEmail" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/product-domains/${productDomainId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'
import { validateEmail, validateUrl } from '../../../../common.js'

export default {
    name: 'ProductDomainEditPage',
    components: {
        WrappedContent,
        TextField,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productDomainId: this.$route.params.id,
            nameValidationError: '',
            companyNameValidationError: '',
            siteValidationError: '',
            supportLinkValidationError: '',
            notificationEmailValidationError: '',
            productDomain: undefined,
            productDomainErrorText: undefined,
            productDomainErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.productDomainId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.productDomain = {
                name: '',
                companyName: '',
                site: '',
                supportLink: '',
                notificationEmail: '',
            }
        } else {
            getAuthorizedRequest(`/api/licensing/product-domains/${this.productDomainId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.productDomain = {
                                    name: data.name,
                                    companyName: data.companyName,
                                    site: data.site,
                                    supportLink: data.supportLink,
                                    notificationEmail: data.notificationEmail,
                                }
                            })
                            break
                        default:
                            this.productDomainErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.productDomainErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.productDomain.name = this.productDomain.name.trim()
            this.productDomain.companyName = this.productDomain.companyName.trim()
            this.productDomain.site = this.productDomain.site.trim()
            this.productDomain.supportLink =
                (this.productDomain.supportLink && this.productDomain.supportLink.trim()) || undefined
            this.productDomain.notificationEmail = this.productDomain.notificationEmail.trim()

            var validationOk = true

            if (!this.productDomain.name) {
                this.nameValidationError = 'Введите название'
                validationOk = false
            } else {
                this.nameValidationError = ''
            }

            if (!this.productDomain.companyName) {
                this.companyNameValidationError = 'Введите название компании'
                validationOk = false
            } else {
                this.companyNameValidationError = ''
            }

            this.siteValidationError = validateUrl(this.productDomain.site)
            if (this.siteValidationError) {
                validationOk = false
            }

            if (this.productDomain.supportLink) {
                this.supportLinkValidationError = validateUrl(this.productDomain.supportLink)
                if (this.supportLinkValidationError) {
                    validationOk = false
                }
            }

            this.notificationEmailValidationError = validateEmail(this.productDomain.notificationEmail)
            if (this.notificationEmailValidationError) {
                validationOk = false
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/product-domains', this.productDomain)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/product-domains/${data.productDomainId}/view`)
                                )
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/product-domains/${this.productDomainId}`, this.productDomain)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные домена продуктов сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>