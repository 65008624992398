<template>
    <div class="web-session-event" v-bind:class="classes">
        <div class="web-session-event-summary" v-on:click="isExpanded = !isExpanded">
            <div class="web-session-event-expanded-marker">
                <div class="web-session-event-expanded-marker-icon" />
            </div>
            <div class="web-session-event-type" />
            <div class="web-session-event-text">{{ summaryText }}</div>
            <div class="web-session-event-time">
                <DateTime v-bind:dt="event.clientTime" />
            </div>
        </div>
        <div class="web-session-event-details" v-show="isExpanded">
            <div class="web-session-event-details-space" />
            <div class="web-session-event-details-content">
                URL: <ExternalLink v-bind:url="event.url" /><br />
                Время на клиенте: {{ event.clientTime }}<br />
                Получено сервером: {{ event.added }}<br />
                Вкладка: {{ event.tabId }}<br />
                Порядковый номер: {{ event.serialNumber }}<br />
                Тип: {{ event.type }}<br />
                <div v-if="event.arguments">
                    Аргументы:<br />
                    <ul>
                        <li v-for="kv in Object.entries(event.arguments)" v-bind:key="kv[0]">
                            {{ kv[0] }} = {{ kv[1] }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateTime from '../../../misc/DateTime.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import { truncateString } from '../../../../common.js'

export default {
    name: 'EventExpandable',
    components: {
        DateTime,
        ExternalLink,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        var event = this.event
        var args = event.arguments
        var tag = args?.tag?.toLowerCase()
        var type = args?.type?.toLowerCase()

        var textInputTypes = [
            'color',
            'date',
            'datetime-local',
            'email',
            'file',
            'month',
            'number',
            'password',
            'range',
            'search',
            'tel',
            'text',
            'time',
            'url',
            'week',
        ]

        var buttonInputTypes = ['button', 'submit', 'reset', 'image']

        return {
            isExpanded: false,
            displayId:
                (args?.id && `id=${args?.id}`) ?? (args?.name && `name=${args?.name}`) ?? `<${tag?.toLowerCase()}>`,
            linkDisplayText: truncateString(String((args?.text ?? args?.title ?? args?.href) || ''), 30),
            buttonDisplayText: truncateString(String((args?.text ?? args?.value) || ''), 30),
            displayValue: truncateString(String((args?.checked ?? args?.value) || ''), 30), // первое для чекбоксов
            displayUrl: truncateString(event.url || '', 50),
            isLink: tag == 'a',
            isTextInput: (tag == 'input' && textInputTypes.indexOf(type) >= 0) || tag == 'textarea',
            isButton: (tag == 'input' && buttonInputTypes.indexOf(type) >= 0) || tag == 'button',
            isCheckbox: tag == 'input' && type == 'checkbox',
            isDownload: args?.download ?? false,
        }
    },
    computed: {
        classes: function() {
            var result = {
                'web-session-event-expanded': this.isExpanded,
                'web-session-event-checkbox': this.isCheckbox,
                'web-session-event-download': this.isDownload,
            }
            result[`web-session-event-type-${this.event.type}`] = true
            return result
        },

        summaryText: function() {
            var event = this.event
            var args = event.arguments

            switch (event.type) {
                case 'PageOpen':
                    return `Открыта страница '${this.displayUrl}'`
                case 'PageVisible':
                    return `Cтраница отображена`
                case 'PageHidden':
                    return `Страница скрыта`
                case 'PageScroll':
                    return `Выполнено ${args?.count} скроллов до позиции ${args?.top}`
                case 'ElementClick':
                    if (this.isLink) return `Клик по ссылке '${this.linkDisplayText}'`
                    else if (this.isButton) return `Клик по кнопке '${this.buttonDisplayText}'`
                    else return `Клик по элементу [${this.displayId}]`
                case 'InputChange':
                    if (this.isTextInput) return `Ввод текста '${this.displayValue}' в поле [${this.displayId}]`
                    else if (this.isCheckbox)
                        return `${this.displayValue ? 'Установлена' : 'Снята'} галочка чекбокса [${this.displayId}]`
                    else return `Ввод значения '${this.displayValue}' в поле [${this.displayId}]`
                default:
                    return event.type
            }
        },
    },
}
</script>

<style scoped>
ul {
    margin: 0px 0px 0px 5px;
}

li {
    padding-left: 5px;
}

.web-session-event {
    margin: 0px;
    padding: 5px 0px;
    width: 100%;
    box-sizing: border-box;
}

.web-session-event:not(:last-child) {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.25);
}

.web-session-event-summary {
    display: flex;
    cursor: pointer;
    line-height: 1;
}

.web-session-event-expanded-marker {
    flex-grow: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    width: 16px;
}

.web-session-event-expanded-marker-icon {
    width: 16px;
    height: 16px;
    background-image: url('../../../../assets/misc/expandable-arrow.svg');
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(0.75turn);
}

.web-session-event-expanded .web-session-event-expanded-marker-icon {
    transform: none;
}

.web-session-event-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    color: #718dab;
    overflow: hidden;
    white-space: nowrap;
}

.web-session-event-summary:hover .web-session-event-text {
    color: #99bade;
}

.web-session-event-time {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    text-align: right;
    font-family: monospace;
    font-size: 0.9em;
}

.screen-s .web-session-event-time {
    width: 80px;
}

.web-session-event-type {
    flex-grow: 0;
    min-width: 50px;
    min-height: 30px;
    background-position: center center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
}

.web-session-event-type-PageOpen .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-page-open.svg');
}

.web-session-event-type-PageVisible .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-page-visible.svg');
}

.web-session-event-type-PageHidden .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-page-hidden.svg');
}

.web-session-event-type-PageScroll .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-page-scroll.svg');
}

.web-session-event-type-ElementClick .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-click.svg');
}

.web-session-event-type-InputChange .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-change-text.svg');
}

.web-session-event-checkbox .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-change-checkbox.svg') !important;
}

.web-session-event-download .web-session-event-type {
    background-image: url('../../../../assets/telemetry/web-sessions/event-click-download.svg') !important;
}

.web-session-event-details {
    display: flex;
}

.web-session-event-details-space {
    flex-shrink: 0;
    width: 66px;
}

.web-session-event-details-content {
    flex-grow: 1;
    margin-top: 10px;
    margin-right: 30px;
    word-break: break-word;
}
</style>
