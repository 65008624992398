<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание клиента</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/customers/${customerId}/view`">клиента #{{ customerId }}</router-link>
        </h1>

        <RequestWrapper
            v-bind:data="customer"
            v-bind:errorText="customerErrorText"
            v-bind:errorCode="customerErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="customer-email"
                            labelText="E-mail:"
                            v-bind:errorText="emailValidationError"
                            required
                            v-model="customer.email" />
                        <TextField
                            id="customer-cc-email"
                            labelText="Дополнительный e-mail:"
                            v-bind:errorText="ccEmailValidationError"
                            v-model="customer.ccEmail" />
                        <TextField id="customer-name" labelText="Имя:" v-model="customer.name" />
                        <TextField id="customer-company" labelText="Компания:" v-model="customer.company" />
                        <TextField id="customer-zip" labelText="Почтовый индекс:" v-model="customer.zip" />
                        <TextField id="customer-country" labelText="Страна:" v-model="customer.country" />
                        <TextField id="customer-state" labelText="Штат:" v-model="customer.state" />
                        <TextField id="customer-city" labelText="Город:" v-model="customer.city" />
                        <TextField id="customer-street-address" labelText="Адрес:" v-model="customer.streetAddress" />
                        <SelectField
                            id="customer-language"
                            labelText="Язык:"
                            v-bind:options="languageOptions"
                            v-model="customer.language" />
                        <TextField id="customer-comment" labelText="Комментарий:" v-model="customer.comment" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/customers/${customerId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SelectField from '../../../inputs/SelectField.vue'
import TextField from '../../../inputs/TextField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'
import { validateEmail, customerLanguageToString } from '../../../../common.js'

export default {
    name: 'CustomerEditPage',
    components: {
        WrappedContent,
        SelectField,
        TextField,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            languageOptions: [
                { value: undefined, text: 'Не установлен' },
                { value: 'English', text: customerLanguageToString('English') },
                { value: 'Arabic', text: customerLanguageToString('Arabic') },
                { value: 'Vietnamese', text: customerLanguageToString('Vietnamese') },
                { value: 'Greek', text: customerLanguageToString('Greek') },
                { value: 'Danish', text: customerLanguageToString('Danish') },
                { value: 'Hebrew', text: customerLanguageToString('Hebrew') },
                { value: 'Indonesian', text: customerLanguageToString('Indonesian') },
                { value: 'Spanish', text: customerLanguageToString('Spanish') },
                { value: 'Italian', text: customerLanguageToString('Italian') },
                { value: 'Chinese', text: customerLanguageToString('Chinese') },
                { value: 'Korean', text: customerLanguageToString('Korean') },
                { value: 'Malay', text: customerLanguageToString('Malay') },
                { value: 'German', text: customerLanguageToString('German') },
                { value: 'Dutch', text: customerLanguageToString('Dutch') },
                { value: 'Norwegian', text: customerLanguageToString('Norwegian') },
                { value: 'Polish', text: customerLanguageToString('Polish') },
                { value: 'Portuguese', text: customerLanguageToString('Portuguese') },
                { value: 'Romanian', text: customerLanguageToString('Romanian') },
                { value: 'Russian', text: customerLanguageToString('Russian') },
                { value: 'Thai', text: customerLanguageToString('Thai') },
                { value: 'Turkish', text: customerLanguageToString('Turkish') },
                { value: 'Finnish', text: customerLanguageToString('Finnish') },
                { value: 'French', text: customerLanguageToString('French') },
                { value: 'Hindi', text: customerLanguageToString('Hindi') },
                { value: 'Swedish', text: customerLanguageToString('Swedish') },
                { value: 'Japanese', text: customerLanguageToString('Japanese') },
            ],
            customerId: this.$route.params.id,
            emailValidationError: '',
            ccEmailValidationError: '',
            customer: undefined,
            customerErrorText: undefined,
            customerErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.customerId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.customer = {
                email: '',
                ccEmail: undefined,
                name: '',
                comment: '',
                company: '',
                zip: '',
                country: '',
                state: '',
                city: '',
                streetAddress: '',
                language: 'English',
            }
        } else {
            getAuthorizedRequest(`/api/licensing/customers/${this.customerId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.customer = {
                                    email: data.email,
                                    ccEmail: data.ccEmail,
                                    name: data.name,
                                    comment: data.comment,
                                    company: data.company,
                                    zip: data.zip,
                                    country: data.country,
                                    state: data.state,
                                    city: data.city,
                                    streetAddress: data.streetAddress,
                                    language: data.language,
                                }
                            })
                            break
                        default:
                            this.customerErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.customerErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.customer.email = this.customer.email.trim()
            this.customer.ccEmail = (this.customer.ccEmail && this.customer.ccEmail.trim()) || undefined

            var validationOk = true

            this.emailValidationError = validateEmail(this.customer.email)
            if (this.emailValidationError) {
                validationOk = false
            }

            if (this.customer.ccEmail) {
                this.ccEmailValidationError = validateEmail(this.customer.ccEmail)
                if (this.ccEmailValidationError) {
                    validationOk = false
                }
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/customers', this.customer)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/customers/${data.customerId}/view`)
                                )
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/customers/${this.customerId}`, this.customer)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные клиента сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>