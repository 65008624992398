<template>
    <div>
        <h1>Добро пожаловать в BackOffice!</h1>
        <h2>Что это такое?</h2>
        <p>
            Бэк-офисное приложение связано с инфраструктурными бэк-энд сервисами, отвечающими за лицензирование,
            рассылку e-mail клиентам, приём и обработку пользовательской телеметрии и тд. Тут вы можете настроить
            продукты, генерацию ключей лицензий, просматривать обработанные заказы, информацию о клиентах, и другое.
        </p>
        <h2>Использование сайта</h2>
        <p>
            Используйте навигационное меню для перехода по секциям. В настройках профиля вы можете поменять логин,
            пароль, имя и фамилию, а также просмотреть текущие активные сессии и прервать их при необходимости.
        </p>
        <p>
            Сайт адаптирован для мобильных браузеров, так что не стесняйтесь использовать его с планшетов и смартфонов.
        </p>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'

export default {
    name: 'IndexPage',
    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>