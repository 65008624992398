<template>
    <div>
        <ItemsList>
            <div class="item" v-for="webSession in webSessions" v-bind:key="webSession.webSessionId">
                <div class="item-date">
                    <DateTime v-bind:dt="webSession.added" />
                </div>
                <div class="item-details">
                    <span class="item-title">{{ webSession.ipAddress }} @ {{ webSession.site }}</span>
                    <br />
                    <FlagsWidget v-bind:session="webSession" />
                    <span v-if="webSession.referer">
                        Referer:
                        <ExternalLink v-bind:url="webSession.referer" v-bind:maxLength="40" />
                        <br />
                    </span>
                    <CompareWidget v-bind:webSessionId="webSession.webSessionId" />
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/telemetry/web-sessions/${webSession.webSessionId}/view`"
                        >Подробнее</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import CompareWidget from './CompareWidget.vue'
import FlagsWidget from './FlagsWidget.vue'

export default {
    name: 'WebSessionsList',
    components: {
        ItemsList,
        DateTime,
        ExternalLink,
        CompareWidget,
        FlagsWidget,
    },
    props: {
        webSessions: {
            type: Array,
            default: undefined,
        },
    },
}
</script>