<template>
    <div class="item-detail">
        <div class="item-detail-name">{{ name }}:</div>
        <div class="item-detail-value">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemDetail',
    props: {
        name: String,
    },
}
</script>

<style scoped>
.item-detail {
    margin-bottom: 15px;
}

.item-detail-name {
    color: #bbb;
    font-weight: bold;
}

.item-detail-value {
    word-break: break-word;
}
</style>
