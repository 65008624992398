<template>
    <a class="button" v-bind:class="{ destructive: destructive }">
        <div class="button-layout">
            <img v-if="icon != ''" alt="icon" v-bind:src="require(`../../assets/${icon}`)" />
            <span>{{ text }}</span>
        </div>
    </a>
</template>

<script>
export default {
    name: 'ButtonLink',
    props: {
        text: String,
        destructive: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped>
a {
    display: inline-flex;
    min-width: 100px;
    min-height: 38px;
    padding: 0px 25px;
    border: none;
    border-radius: 6px;
    background-color: #396ca3;
    color: #d8e5f2;
    cursor: pointer;
    font-size: inherit;
}

a.disabled {
    background-color: #232e3b;
    color: #465a70;
    cursor: default;
}

a:hover,
a:focus {
    background-color: #3d86d4;
    outline: none;
}

a.destructive {
    background-color: #b04038;
    color: #e8d0cf;
}

a.destructive:hover,
a.destructive:focus {
    background-color: #d1625a;
}

.button-layout {
    display: flex;
    justify-content: center;
    align-items: center;
}

button img {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
}
</style>