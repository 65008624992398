<template>
    <div>
        <h1 id="title-source">IP-информация</h1>
        <WrappedContent>
            <SubSection title="Информация об ASN">
                <form>
                    <NumberField id="asn-id" labelText="Введите идентификатор ASN:" v-model="asnId" />
                    <Button
                        text="Перейти"
                        v-bind:useThrobber="false"
                        v-bind:enabled="asnId != undefined"
                        v-on:click="viewAsn" />
                </form>
            </SubSection>
            <SubSection title="Информация об IP-адресе">
                <form>
                    <IpAddressField id="ip-address" labelText="Введите IP-адрес:" v-model="ipAddress" />
                    <Button
                        text="Перейти"
                        v-bind:useThrobber="false"
                        v-bind:enabled="ipAddress != undefined"
                        v-on:click="viewIp" />
                </form>
            </SubSection>
        </WrappedContent>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import SubSection from '../../misc/SubSection.vue'
import NumberField from '../../inputs/NumberField.vue'
import IpAddressField from '../../inputs/IpAddressField.vue'
import Button from '../../inputs/Button.vue'

export default {
    name: 'IpInfoPage',
    components: {
        WrappedContent,
        SubSection,
        NumberField,
        IpAddressField,
        Button,
    },
    data: function() {
        return {
            asnId: undefined,
            ipAddress: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
    methods: {
        viewAsn: function() {
            this.$router.push(`/ipinfo/asns/${this.asnId}`)
        },
        viewIp: function() {
            this.$router.push(`/ipinfo/ips/${this.ipAddress}`)
        },
    },
}
</script>