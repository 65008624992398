<template>
    <div id="layout-content">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Content',
}
</script>

<style scoped>
#layout-content {
    flex-grow: 1;
    padding: 10px 30px 50px 30px;
    box-sizing: border-box;
    background-color: #222;
    /* чтобы в контенте работали скролл-бары при overflow */
    overflow: hidden;
}

.mobile-menu-overlayed {
    filter: blur(1px);
}

.screen-m #layout-content,
.screen-s #layout-content {
    margin-top: 80px;
}

.screen-s #layout-content {
    padding: 0px 10px 50px 10px;
}
</style>