<template>
    <div>
        <h1 id="title-source">
            Удаление
            <router-link v-bind:to="`/licensing/licenses/${licenseId}/view`"> лицензии #{{ licenseId }} </router-link>
        </h1>

        <RequestWrapper v-bind:data="license" v-bind:errorText="licenseErrorText" v-bind:errorCode="licenseErrorCode">
            <template v-slot:default>
                <Warning>
                    Удаление лицензии лишь стирает о ней информацию из нашей системы. Ключ, отправленный клиенту,
                    продолжит действовать.
                </Warning>
                <ButtonsBlock>
                    <Button
                        text="Подтверждаю удаление"
                        destructive
                        v-bind:failureText="deleteFailure"
                        v-on:click="performDelete" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, deleteAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'LicenseDeletePage',
    components: {
        Warning,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            licenseId: this.$route.params.id,
            license: undefined,
            licenseErrorText: undefined,
            licenseErrorCode: undefined,
            deleteFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/licenses/${this.licenseId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.license = data))
                        break
                    default:
                        this.licenseErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.licenseErrorText = error))
    },
    methods: {
        performDelete: function(callback) {
            this.deleteFailure = ''

            deleteAuthorizedRequest(`/api/licensing/licenses/${this.licenseId}`).then(res => {
                switch (res.status) {
                    case 200:
                        this.$router.push('/licensing/licenses')
                        break
                    default:
                        this.deleteFailure = `Плохой ответ на запрос (${res.status})`
                        callback()
                        break
                }
            })
        },
    },
}
</script>