<template>
    <div>
        <ItemsList>
            <div class="item" v-for="entry in entries" v-bind:key="entry.entryId">
                <div class="item-date">
                    <DateTime v-bind:dt="entry.startTime" />
                </div>
                <div class="item-details">
                    <span class="item-title"> {{ entry.method }} {{ entry.path }} </span>
                    <br />
                    Пользователь:
                    <router-link v-bind:to="`/administration/users/${entry.userId}/view`">
                        {{ `${entry.userLogin || ''} #${entry.userId}`.trim() }}
                    </router-link>
                    <br />
                    Сессия: {{ entry.userSessionId }}
                </div>
                <div>
                    <span class="entry-status" v-bind:class="`entry-status-${String(entry.statusCode).substr(0, 1)}`">
                        {{ entry.statusCode }}
                    </span>
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'

export default {
    name: 'AuditEntriesList',
    components: {
        ItemsList,
        DateTime,
    },
    props: {
        entries: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.entry-status {
    color: #ddd;
    font-size: 1em;
}

.entry-status-2 {
    color: #2a6;
}

.entry-status-4 {
    color: #dd9;
}

.entry-status-5 {
    color: #d99;
}
</style>