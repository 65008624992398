<template>
    <div>
        <ItemsList>
            <div class="item" v-for="productMapping in productMappings" v-bind:key="productMapping.productMappingId">
                <div class="item-details">
                    <span class="item-title"
                        >{{ productMapping.registrator.name }} {{ productMapping.registratorProductId }}</span
                    >
                    <br />
                    Продукт:
                    <router-link v-bind:to="`/licensing/products/${productMapping.product.productId}/view`">
                        {{ productMapping.product.name }}
                    </router-link>
                    <br />
                    Тип лицензии:
                    <router-link
                        v-bind:to="`/licensing/license-types/${productMapping.licenseType.licenseTypeId}/view`">
                        {{ productMapping.licenseType.name }}
                    </router-link>
                    <span v-if="productMapping.renewalLicenseType">
                        ➔
                        <router-link
                            v-bind:to="`/licensing/license-types/${productMapping.renewalLicenseType.licenseTypeId}/view`">
                            {{ productMapping.renewalLicenseType.name }}
                        </router-link>
                    </span>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/product-mappings/${productMapping.productMappingId}/view`"
                        >Подробнее</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-mappings/${productMapping.productMappingId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-mappings/${productMapping.productMappingId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'

export default {
    name: 'ProductMappingsList',
    components: {
        ItemsList,
    },
    props: {
        productMappings: {
            type: Array,
            default: undefined,
        },
    },
}
</script>