<template>
    <div>
        <h1 id="title-source">Фоновые службы</h1>
        <h2>Лицензирование</h2>
        <MaintainersList apiBasePath="/api/licensing/maintainers" />
        <h2>Телеметрия</h2>
        <MaintainersList apiBasePath="/api/telemetry/maintainers" />
        <h2>Репутация</h2>
        <MaintainersList apiBasePath="/api/reputation/maintainers" />
        <h2>IP-информация</h2>
        <MaintainersList apiBasePath="/api/ipinfo/maintainers" />
        <h2>Уведомления</h2>
        <MaintainersList apiBasePath="/api/notification/maintainers" />
        <h2>Бэк-офис</h2>
        <MaintainersList apiBasePath="/api/maintainers" />
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import MaintainersList from './MaintainersList.vue'

export default {
    name: 'MaintainersPage',

    components: {
        MaintainersList,
    },

    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>