<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Веб-сессии</h1>
        <h1 v-else id="title-source">Поиск веб-сессий</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <TextField id="search-site" labelText="Сайт:" v-model="searchParameters.site" />
                    <TextField id="search-ip" labelText="IP-адрес:" v-model="searchParameters.ip" />
                    <TextField
                        id="search-session-ext-id"
                        labelText="Внешний ID сессии:"
                        v-model="searchParameters.externalId" />
                    <TextField
                        id="search-track-ext-id"
                        labelText="Внешний ID пользователя:"
                        v-model="searchParameters.userTrackExternalId" />
                    <TextField id="search-referer" labelText="Referer:" v-model="searchParameters.referer" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button
                        text="Искать"
                        v-bind:useThrobber="false"
                        v-bind:enabled="isSearchValid"
                        v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <WebSessionsList v-bind:webSessions="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import WebSessionsList from './WebSessionsList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'WebSessions',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        TextField,
        ExpandableContent,
        PageController,
        WebSessionsList,
        RequestWrapper,
    },
    data: function() {
        return {
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    computed: {
        isSearchValid: function() {
            return (
                (this.searchParameters.site || '').trim() != '' ||
                (this.searchParameters.ip || '').trim() != '' ||
                (this.searchParameters.externalId || '').trim() != '' ||
                (this.searchParameters.userTrackExternalId || '').trim() != '' ||
                (this.searchParameters.referer || '').trim() != ''
            )
        },
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                site: this.$route.query.site || '',
                ip: this.$route.query.ip || '',
                externalId: this.$route.query.externalId || '',
                userTrackExternalId: this.$route.query.userTrackExternalId || '',
                referer: this.$route.query.referer || '',
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            var query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/telemetry/web-sessions', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {}
                    if (this.searchParameters.site) request.sitePartial = this.searchParameters.site
                    if (this.searchParameters.ip) request.ipAddressExact = this.searchParameters.ip
                    if (this.searchParameters.externalId) request.externalIdExact = this.searchParameters.externalId
                    if (this.searchParameters.userTrackExternalId)
                        request.userTrackExternalIdExact = this.searchParameters.userTrackExternalId
                    if (this.searchParameters.referer) request.refererPartial = this.searchParameters.referer

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/telemetry/web-sessions/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            this.searchParameters.site = this.searchParameters.site.trim()
            this.searchParameters.ip = this.searchParameters.ip.trim()
            this.searchParameters.externalId = this.searchParameters.externalId.trim()
            this.searchParameters.userTrackExternalId = this.searchParameters.userTrackExternalId.trim()

            let query = { mode: 'search' }
            if (this.searchParameters.site) query.site = this.searchParameters.site
            if (this.searchParameters.ip) query.ip = this.searchParameters.ip
            if (this.searchParameters.externalId) query.externalId = this.searchParameters.externalId
            if (this.searchParameters.userTrackExternalId)
                query.userTrackExternalId = this.searchParameters.userTrackExternalId
            if (this.searchParameters.referer) query.referer = this.searchParameters.referer

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>