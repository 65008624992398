<template>
    <div class="reporter">
        <RequestWrapper
            v-bind:data="reporter"
            v-bind:errorText="reporterErrorText"
            v-bind:errorCode="reporterErrorCode">
            <template v-slot:default>
                <SubSection v-bind:title="reporter.displayName">
                    {{ reporter.description }}
                    <div v-if="!isBusy" class="reporter-state">Готов к работе.</div>
                    <div v-else class="reporter-state reporter-state-busy">Отчёт генерируется...</div>

                    <ExpandableContent summaryText="Создать отчёт">
                        <form>
                            <WrappedContent>
                                <DateTimeField
                                    id="create-report-start"
                                    labelText="Начало периода:"
                                    required
                                    v-bind:errorText="createStartValidationError"
                                    v-model="createReportParams.start" />
                                <DateTimeField
                                    id="create-report-end"
                                    labelText="Конец периода:"
                                    required
                                    v-bind:errorText="createEndValidationError"
                                    v-model="createReportParams.end" />
                            </WrappedContent>
                            <CheckBox
                                v-bind:id="`create-report-send-me-${reporter.name}`"
                                labelText="Отправить отчёт мне на e-mail"
                                v-model="createReportParams.sendMe" />
                            <ButtonsBlock>
                                <Button
                                    v-bind:text="createButtonText"
                                    v-bind:enabled="!isBusy"
                                    v-on:click="performCreate"
                                    v-bind:successText="createRequestSuccess"
                                    v-bind:failureText="createRequestFailure" />
                            </ButtonsBlock>
                        </form>
                    </ExpandableContent>

                    <RequestInlineWrapper
                        loadingText="Загрузка последних отчётов..."
                        v-bind:data="reports"
                        v-bind:errorText="reportsErrorText"
                        v-bind:errorCode="reportsErrorCode">
                        <template v-slot:default>
                            <ExpandableContent summaryText="Последние отчёты">
                                <div v-if="reports.length > 0">
                                    <div v-for="report in reports" v-bind:key="report.reportId">
                                        <a
                                            v-bind:href="`${apiBasePath}/reports/${report.reportId}`"
                                            v-bind:download="report.file.name">
                                            {{ report.displayName }}
                                        </a>
                                        <span class="report-description">[<DateTime v-bind:dt="report.added" />]</span>
                                    </div>
                                </div>
                                <div v-else>
                                    <i>Нет последних отчётов.</i>
                                </div>
                            </ExpandableContent>
                        </template>
                    </RequestInlineWrapper>
                </SubSection>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import SubSection from '../../misc/SubSection.vue'
import ExpandableContent from '../../misc/ExpandableContent.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import DateTimeField from '../../inputs/DateTimeField.vue'
import CheckBox from '../../inputs/CheckBox.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import DateTime from '../../misc/DateTime.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import RequestInlineWrapper from '../../misc/RequestInlineWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, userInfo } from '../../../common.js'

export default {
    name: 'Reporter',

    components: {
        SubSection,
        ExpandableContent,
        WrappedContent,
        DateTimeField,
        CheckBox,
        Button,
        ButtonsBlock,
        DateTime,
        RequestWrapper,
        RequestInlineWrapper,
    },

    props: {
        apiBasePath: {
            type: String,
            required: true,
        },
        reporterData: {
            type: String,
            default: undefined,
        },
    },

    data: function() {
        return {
            reporter: this.reporterData,
            reporterErrorText: this.reporterData,
            reporterErrorCode: this.reporterData,
            reports: undefined,
            reportsErrorText: undefined,
            reportsErrorCode: undefined,
            updateIntervalId: undefined,
            createReportParams: {
                start: undefined,
                end: undefined,
                sendMe: false,
            },
            createStartValidationError: '',
            createEndValidationError: '',
            createRequestSuccess: '',
            createRequestFailure: '',
        }
    },

    mounted: function() {
        if (!this.reporter) this.updateReporter()
        this.updateReports()
        this.startUpdateLoop()
        document.addEventListener('visibilitychange', this.documentVisibilityChangeHandler)
    },

    beforeDestroy: function() {
        this.stopUpdateLoop()
        document.removeEventListener('visibilitychange', this.documentVisibilityChangeHandler)
    },

    computed: {
        isBusy: function() {
            return this.reporter.state != 'Idle'
        },

        createButtonText: function() {
            return this.isBusy ? 'Генератор занят' : 'Создать'
        },

        reporterStyle: function() {
            return {
                'reporter-busy': this.isBusy,
            }
        },
    },

    methods: {
        documentVisibilityChangeHandler: function() {
            switch (document.visibilityState) {
                case 'visible':
                    this.startUpdateLoop()
                    break
                case 'hidden':
                    this.stopUpdateLoop()
                    break
            }
        },

        startUpdateLoop: function() {
            if (this.updateIntervalId) return
            this.updateIntervalId = setInterval(this.updateData, 5000)
        },

        stopUpdateLoop: function() {
            if (!this.updateIntervalId) return
            clearInterval(this.updateIntervalId)
            this.updateIntervalId = undefined
        },

        updateData: function() {
            this.updateReporter()
            this.updateReports()
        },

        updateReporter: function() {
            getAuthorizedRequest(this.apiBasePath)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.reporter = data
                                this.reporterErrorText = undefined
                                this.reporterErrorCode = undefined
                            })
                            break
                        default:
                            this.reporterErrorText = undefined
                            this.reporterErrorCode = res.status
                            break
                    }
                })
                .catch(error => {
                    this.reporterErrorText = error
                    this.reporterErrorCode = undefined
                })
        },

        updateReports: function() {
            getAuthorizedRequest(`${this.apiBasePath}/reports`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.reports = data
                                this.reportsErrorText = undefined
                                this.reportsErrorCode = undefined
                            })
                            break
                        default:
                            this.reportsErrorText = undefined
                            this.reportsErrorCode = res.status
                            break
                    }
                })
                .catch(error => {
                    this.reportsErrorText = error
                    this.reportsErrorCode = undefined
                })
        },

        performCreate: function(callback) {
            this.createRequestSuccess = ''
            this.createRequestFailure = ''

            var validationOk = true

            if (!this.createReportParams.start) {
                this.createStartValidationError = 'Укажите начало периода'
                validationOk = false
            } else {
                this.createStartValidationError = ''
            }

            if (!this.createReportParams.end) {
                this.createEndValidationError = 'Укажите конец периода'
                validationOk = false
            } else {
                this.createEndValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            var createReportRequest = {
                start: this.createReportParams.start,
                end: this.createReportParams.end,
                emailRecipients: [],
            }

            if (this.createReportParams.sendMe) {
                createReportRequest.emailRecipients = [
                    {
                        Address: userInfo.profile.email,
                        DisplayName:
                            `${userInfo.profile.firstName || ''} ${userInfo.profile.lastName || ''}`.trim() ||
                            undefined,
                    },
                ]
            }

            postAuthorizedRequest(`${this.apiBasePath}/reports`, createReportRequest)
                .then(res => {
                    switch (res.status) {
                        case 202:
                            this.createRequestSuccess = 'Отчёт поставлен в очередь на генерацию'
                            break
                        default:
                            this.createRequestFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.createRequestFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },
    },
}
</script>

<style scoped>
.reporter-state {
    margin-top: 5px;
}

.reporter-state:not(.reporter-state-busy) {
    opacity: 0.5;
}

.reporter-state-busy {
    color: #080;
    animation: reporterBusyAnimation 2s ease 0s infinite normal none;
}

@keyframes reporterBusyAnimation {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0.5;
    }
}

.report-description {
    margin-left: 10px;
}
</style>