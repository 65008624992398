<template>
    <div class="form-field select-field" v-bind:class="{ 'select-field-disabled': !enabled }">
        <label v-bind:for="id">{{ labelText }}<span class="select-field-required" v-if="required"> *</span></label>
        <div class="select-field-container">
            <select
                v-bind:class="{ 'ios-select': iOS }"
                v-bind:id="id"
                v-bind:name="name || id"
                v-bind:disabled="!enabled"
                v-bind:value="value"
                v-on:input="inputHandler($event.target.value)">
                <option v-for="option in options" v-bind:key="option.value" v-bind:value="option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>
        <FieldNotifications v-bind:errorText="errorText" />
    </div>
</template>

<script>
import FieldNotifications from './FieldNotifications.vue'

export default {
    name: 'SelectField',
    components: {
        FieldNotifications,
    },
    props: {
        value: undefined,
        options: Array,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        labelText: String,
        errorText: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            iOS: navigator.userAgent.match('iPad|iPhone|iPod') != -1,
        }
    },
    methods: {
        inputHandler: function(value) {
            this.$emit('input', value != '' ? value : undefined)
        },
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

select {
    padding: 4px 4px;
    width: 100%;
    height: 36px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
    outline: none;
    color: #a2bfdb;
    font: inherit;
}

select:focus,
.select-field:not(.select-field-disabled) select:hover {
    border-color: #7491b0;
}

.select-field:not(.select-field-disabled) .select-field-required {
    color: #dda;
}

.select-field-notifications {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 18px;
    font-size: 0.8em;
}

.select-field-notification-error {
    margin: 0px 10px 0px 0px;
    padding: 0px 8px 2px 8px;
    border-radius: 0px 0px 4px 4px;
    background-color: #41556b;
    color: #faa;
}

.select-field-disabled label {
    color: #555;
}

.select-field-disabled select {
    color: #465a70;
    border-color: #2c394a;
    background-color: #232e3b;
}

.ios-select {
    -webkit-appearance: none;
    background-image: url('../../assets/inputs/select-arrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 1.5em;
}
</style>
