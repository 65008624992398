<template>
    <div id="footer">
        <router-link to="/changes">{{ versionText }}</router-link>
        <span id="copyright"> © 2024 Recovery Toolbox</span>
    </div>
</template>

<script>
import { getRequest } from '../../common.js'

export default {
    name: 'Footer',
    data: function() {
        return {
            version: undefined,
        }
    },
    computed: {
        versionText: function() {
            if (this.version == undefined) return ''
            return `Версия ${this.version.version}`
        },
    },
    created: function() {
        getRequest('/api/version')
            .then(res => res.json())
            .then(data => (this.version = data))
    },
}
</script>

<style scoped>
#footer {
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 50px;
    font-size: 0.9em;
    color: #666;
}

#copyright {
    white-space: pre;
}

.screen-s #footer {
    justify-content: center;
}
</style>