<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание ордера</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/orders/${orderId}/view`">ордера #{{ orderId }}</router-link>
        </h1>

        <RequestWrapper v-bind:data="order" v-bind:errorText="orderErrorText" v-bind:errorCode="orderErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="order-purchased"
                            labelText="Дата и время покупки:"
                            required
                            v-bind:errorText="purchasedValidationError"
                            v-model="order.purchased" />
                        <SelectField
                            id="order-status"
                            labelText="Статус:"
                            required
                            v-bind:options="statusOptions"
                            v-model="order.status" />
                        <SearchField
                            id="order-customer"
                            labelText="Клиент:"
                            required
                            v-bind:errorText="customerValidationError"
                            textKey="email"
                            valueKey="customerId"
                            v-bind:getInfoUrl="`/api/licensing/customers/${order.customerId}`"
                            searchUrl="/api/licensing/customers/search"
                            searchKey="emailPartial"
                            v-model="order.customerId" />
                        <SearchField
                            id="order-product"
                            labelText="Продукт:"
                            required
                            v-bind:errorText="productValidationError"
                            textKey="name"
                            valueKey="productId"
                            v-bind:getInfoUrl="`/api/licensing/products/${order.productId}`"
                            searchUrl="/api/licensing/products/search"
                            searchKey="namePartial"
                            v-model="order.productId" />
                        <SearchField
                            id="order-license-type"
                            labelText="Тип лицензии:"
                            required
                            v-bind:errorText="licenseTypeValidationError"
                            textKey="name"
                            valueKey="licenseTypeId"
                            v-bind:getInfoUrl="`/api/licensing/license-types/${order.licenseTypeId}`"
                            searchUrl="/api/licensing/license-types/search"
                            searchKey="namePartial"
                            v-model="order.licenseTypeId" />
                        <NumberField
                            id="order-licenses-quantity"
                            labelText="Количество лицензий:"
                            required
                            min="1"
                            max="10000"
                            v-bind:errorText="licensesQuantityValidationError"
                            v-model="order.licensesQuantity" />
                        <SearchField
                            id="order-registrator"
                            labelText="Регистратор:"
                            textKey="name"
                            valueKey="registratorId"
                            v-bind:getInfoUrl="`/api/licensing/registrators/${order.registratorId}`"
                            searchUrl="/api/licensing/registrators/search"
                            searchKey="namePartial"
                            v-model="order.registratorId" />
                        <TextField
                            id="order-registrator-order-id"
                            labelText="Идентификатор у регистратора:"
                            v-model="order.registratorOrderId" />
                        <TextField id="order-comment" labelText="Комментарий:" v-model="order.comment" />
                        <CheckBox id="order-test" labelText="Тестовый ордер" v-model="order.isTest" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/orders/${orderId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SelectField from '../../../inputs/SelectField.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import SearchField from '../../../inputs/SearchField.vue'
import CheckBox from '../../../inputs/CheckBox.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'
import { isValidDateTime, orderStatusToString } from '../../../../common.js'

export default {
    name: 'OrderEditPage',
    components: {
        WrappedContent,
        SelectField,
        TextField,
        NumberField,
        SearchField,
        CheckBox,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            statusOptions: [
                { value: 'Normal', text: orderStatusToString('Normal') },
                { value: 'Chargeback', text: orderStatusToString('Chargeback') },
                { value: 'Refund', text: orderStatusToString('Refund') },
                { value: 'PartialRefund', text: orderStatusToString('PartialRefund') },
            ],
            orderId: this.$route.params.id,
            purchasedValidationError: '',
            customerValidationError: '',
            productValidationError: '',
            licenseTypeValidationError: '',
            licensesQuantityValidationError: '',
            order: undefined,
            orderErrorText: undefined,
            orderErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.orderId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.order = {
                status: 'Normal',
                comment: '',
                purchased: new Date().toISOString(),
                customerId: undefined,
                productId: undefined,
                licenseTypeId: undefined,
                licensesQuantity: 1,
                registratorId: undefined,
                registratorOrderId: '',
                isTest: false,
            }
        } else {
            getAuthorizedRequest(`/api/licensing/orders/${this.orderId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.order = {
                                    orderId: data.orderId,
                                    added: data.added,
                                    status: data.status,
                                    comment: data.comment,
                                    purchased: data.purchased,
                                    customerId: data.customer.customerId,
                                    productId: data.product.productId,
                                    licenseTypeId: data.licenseType.licenseTypeId,
                                    licensesQuantity: data.licensesQuantity,
                                    registratorId: data.registrator && data.registrator.registratorId,
                                    registratorOrderId: data.registratorOrderId || '',
                                    isTest: data.isTest || false,
                                }
                            })
                            break
                        default:
                            this.orderErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.orderErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.order.purchased = this.order.purchased.trim()
            this.order.registratorOrderId =
                (this.order.registratorOrderId && this.order.registratorOrderId.trim()) || undefined

            var validationOk = true

            if (!this.order.purchased) {
                this.purchasedValidationError = 'Введите дату и время покупки'
                validationOk = false
            } else if (!isValidDateTime(this.order.purchased)) {
                this.purchasedValidationError = 'Формат: YYYY-MM-DDThh:mm:ssZ'
                validationOk = false
            } else {
                this.purchasedValidationError = ''
            }

            if (!this.order.customerId) {
                this.customerValidationError = 'Укажите клиента'
                validationOk = false
            } else {
                this.customerValidationError = ''
            }

            if (!this.order.productId) {
                this.productValidationError = 'Укажите продукт'
                validationOk = false
            } else {
                this.productValidationError = ''
            }

            if (!this.order.licenseTypeId) {
                this.licenseTypeValidationError = 'Укажите тип лицензии'
                validationOk = false
            } else {
                this.licenseTypeValidationError = ''
            }

            if (!this.order.licensesQuantity) {
                this.licensesQuantityValidationError = 'Введите количество'
                validationOk = false
            } else {
                this.licensesQuantityValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/orders', this.order)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data => this.$router.push(`/licensing/orders/${data.orderId}/view`))
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/orders/${this.orderId}`, this.order)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные ордера сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },
    },
}
</script>