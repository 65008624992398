<template>
    <div v-if="currentMode == 'view'" class="item listed-asn" v-bind:class="itemClass">
        <div class="item-details">
            <div class="item-title">
                <a
                    v-if="!isDeleted"
                    href="javascript:;"
                    v-bind:class="statusStyle"
                    class="listed-asn-status"
                    v-on:click="performSwitchEnabled"
                    >⬤</a
                ><span v-else v-bind:class="statusStyle" class="listed-asn-status">⬤</span>
                <span class="listed-asn-number">{{ currentItem.asnId }}</span>
                <RequestInlineWrapper
                    v-bind:data="item"
                    v-bind:errorTextTitle="requestErrorTextTitle"
                    v-bind:errorText="requestErrorText"
                    v-bind:errorCodeTitle="requestErrorCodeTitle"
                    v-bind:errorCode="requestErrorCode" />
            </div>
            <div v-if="currentItem.comment">
                <span>Комментарий: {{ currentItem.comment }}</span>
            </div>
            <div v-if="currentItem.enabled && currentItem.disableAfter">
                <span>Отключить: <DateTime v-bind:dt="currentItem.disableAfter" /></span>
            </div>
        </div>
        <div v-if="!isDeleted" class="item-links">
            <a href="javascript:;" v-on:click="switchToEditMode">Изменить</a>
            <a href="javascript:;" v-on:click="performDelete">Удалить</a>
        </div>
        <div v-else>
            <a href="javascript:;" v-on:click="performRecover">Восстановить</a>
        </div>
    </div>
    <div v-else class="item listed-asn" v-bind:class="itemClass">
        <form v-bind:id="`listed-asn-${editItem.listedAsnId}`" class="listed-asn-form">
            <CheckBox
                class="listed-asn-enabled-checkbox"
                labelText="Включено"
                v-bind:id="`listed-asn-enabled-${editItem.listedAsnId}`"
                v-model="editItem.enabled" />
            <WrappedContent>
                <NumberField
                    v-bind:id="`listed-asn-asn-id-${editItem.listedAsnId}`"
                    labelText="ASN:"
                    required
                    v-bind:min="1"
                    v-bind:max="2147483647"
                    v-bind:errorText="asnIdValidationError"
                    v-model="editItem.asnId" />
                <TextField
                    v-bind:id="`listed-asn-comment-${editItem.listedAsnId}`"
                    labelText="Комментарий:"
                    placeholderText="Рекомендуется сделать пояснение"
                    v-model="editItem.comment" />
                <DateTimeField
                    v-bind:id="`listed-asn-disable-after-${editItem.listedAsnId}`"
                    v-bind:periodPresets="disableAfterPresets"
                    labelText="Дата/время автоотключения:"
                    placeholderText="Только вручную"
                    v-model="editItem.disableAfter" />
            </WrappedContent>
            <ButtonsBlock>
                <Button
                    text="Сохранить"
                    v-on:click="performSave"
                    v-bind:successText="saveRequestSuccess"
                    v-bind:failureText="saveRequestFailure" />
            </ButtonsBlock>
            <a v-if="currentMode != 'create'" href="javascript:;" v-on:click="switchToViewMode">🡨 Назад</a>
        </form>
    </div>
</template>

<script>
import DateTime from '../../../misc/DateTime.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import NumberField from '../../../inputs/NumberField.vue'
import CheckBox from '../../../inputs/CheckBox.vue'
import DateTimeField from '../../../inputs/DateTimeField.vue'
import TextField from '../../../inputs/TextField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestInlineWrapper from '../../../misc/RequestInlineWrapper.vue'

import {
    postAuthorizedRequest,
    putAuthorizedRequest,
    deleteAuthorizedRequest,
    patchAuthorizedRequest,
} from '../../../../common.js'

export default {
    name: 'ListedAsnItem',

    components: {
        DateTime,
        WrappedContent,
        NumberField,
        CheckBox,
        DateTimeField,
        TextField,
        Button,
        ButtonsBlock,
        RequestInlineWrapper,
    },

    props: {
        listType: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            default: 'view',
        },
        item: {
            type: Object,
            default: undefined,
        },
    },

    data: function() {
        var apiBasePath = `/api/reputation/lists/asns/${this.listType}`

        return {
            apiBasePath: apiBasePath,
            apiItemPath: `${apiBasePath}/${this.item?.listedAsnId || 'new'}`,
            currentMode: this.mode,
            currentItem: this.initItem(undefined, this.item),
            editItem: this.initItem(undefined, this.item),
            asnIdValidationError: '',
            saveRequestSuccess: undefined,
            saveRequestFailure: undefined,
            isProcessing: false,
            isDeleted: false,
            disableAfterPresets: [
                {
                    count: 3,
                    unit: 'day',
                },
                {
                    count: 1,
                    unit: 'week',
                },
                {
                    count: 2,
                    unit: 'week',
                },
                {
                    count: 1,
                    unit: 'month',
                },
                {
                    count: 3,
                    unit: 'month',
                },
                {
                    count: 6,
                    unit: 'month',
                },
                {
                    count: 1,
                    unit: 'year',
                },
            ],
            requestErrorTextTitle: undefined,
            requestErrorText: undefined,
            requestErrorCodeTitle: undefined,
            requestErrorCode: undefined,
        }
    },

    computed: {
        itemClass: function() {
            return {
                'listed-asn-processing': this.isProcessing,
                'listed-asn-deleted': this.isDeleted,
            }
        },

        statusStyle: function() {
            return {
                'listed-asn-status-enabled': this.currentItem.enabled,
            }
        },
    },

    methods: {
        initItem: function(item1, item2) {
            item1 = item1 || {}
            item1.listedAsnId = item2?.listedAsnId || 0
            item1.asnId = item2?.asnId || undefined
            item1.enabled = item2?.enabled == undefined ? true : item2?.enabled
            item1.disableAfter = item2?.disableAfter || undefined
            item1.comment = item2?.comment || undefined
            return item1
        },

        switchToEditMode: function() {
            if (this.isProcessing) return

            this.initItem(this.editItem, this.currentItem)
            this.asnIdValidationError = ''
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.currentMode = 'edit'
        },

        switchToViewMode: function() {
            if (this.isProcessing) return

            this.currentMode = 'view'
        },

        performSave: function(callback) {
            if (this.isProcessing) return

            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''

            if (!this.editItem.disableAfter) this.editItem.disableAfter = undefined

            if (this.editItem.comment) this.editItem.comment = this.editItem.comment.trim()
            else this.editItem.comment = undefined

            var validationOk = true

            if (!this.editItem.asnId) {
                this.asnIdValidationError = 'Введите ASN ID'
                validationOk = false
            } else {
                this.asnIdValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            this.isProcessing = true

            var request = undefined

            switch (this.currentMode) {
                case 'create':
                    request = postAuthorizedRequest(this.apiBasePath, this.editItem)
                    break
                case 'edit':
                    request = putAuthorizedRequest(this.apiItemPath, this.editItem)
                    break
                default:
                    return
            }

            request
                .then(res => {
                    switch (res.status) {
                        case 200:
                        case 201:
                            res.json().then(data => {
                                this.currentItem = data
                                this.apiItemPath = `${this.apiBasePath}/${this.currentItem.listedAsnId}`

                                this.saveRequestSuccess = 'Данные сохранены'
                                this.isProcessing = false

                                if (this.currentMode == 'create') {
                                    this.$emit('create', this.currentItem)
                                    this.initItem(this.editItem)
                                }
                            })

                            break
                        case 400:
                        case 409:
                            res.json()
                                .then(data => {
                                    switch (data.reason) {
                                        case 'AsnIsWhitelisted':
                                            this.saveRequestFailure = 'Указанный ASN уже состоит в белом списке'
                                            break
                                        case 'AsnIsBlacklisted':
                                            this.saveRequestFailure = 'Указанный ASN уже состоит в чёрном списке'
                                            break
                                        default:
                                            this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                            break
                                    }

                                    this.isProcessing = false
                                })
                                .catch(reason => {
                                    this.saveRequestFailure = reason
                                    this.isProcessing = false
                                })
                            break
                        default:
                            this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                            this.isProcessing = false
                            break
                    }
                })
                .catch(() => {
                    this.saveRequestFailure = 'Не удалось выполнить запрос'
                    this.isProcessing = false
                })
                .then(callback)
        },

        resetRequestError: function() {
            this.requestErrorText = undefined
            this.requestErrorCode = undefined
        },

        setRequestErrorText: function(title, text) {
            this.requestErrorTextTitle = title
            this.requestErrorText = text
        },

        setRequestErrorCode: function(title, code) {
            this.requestErrorCodeTitle = title
            this.requestErrorCode = code
        },

        performDelete: function() {
            if (this.isProcessing) return

            this.resetRequestError()
            this.isProcessing = true

            deleteAuthorizedRequest(this.apiItemPath)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.isProcessing = false
                            this.isDeleted = true
                            break
                        default:
                            this.setRequestErrorCode('Запрос на удаление завершился кодом ', res.status)
                            this.$router.push(`/error/${res.status}`)
                            break
                    }
                })
                .catch(error => this.setRequestErrorText('Не удалось выполнить запрос на удаление: ', error))
                .then(() => (this.isProcessing = false))
        },

        performRecover: function() {
            if (this.isProcessing) return

            this.resetRequestError()
            this.isProcessing = true

            postAuthorizedRequest(this.apiBasePath, this.currentItem)
                .then(res => {
                    switch (res.status) {
                        case 201:
                            res.json().then(data => {
                                this.currentItem = data
                                this.apiItemPath = `${this.apiBasePath}/${this.currentItem.listedAsnId}`
                            })

                            this.isProcessing = false
                            this.isDeleted = false
                            break
                        default:
                            this.setRequestErrorCode('Запрос на восстановление завершился кодом ', res.status)
                            break
                    }
                })
                .catch(error => this.setRequestErrorText('Не удалось выполнить запрос на восстановление: ', error))
                .then(() => (this.isProcessing = false))
        },

        performSwitchEnabled: function() {
            if (this.isProcessing) return

            this.resetRequestError()
            this.isProcessing = true

            patchAuthorizedRequest(this.apiItemPath, { enabled: !this.currentItem.enabled })
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.currentItem = data
                            })

                            this.isProcessing = false
                            this.isDeleted = false
                            break
                        default:
                            this.setRequestErrorCode('Запрос на переключение завершился кодом ', res.status)
                            break
                    }
                })
                .catch(error => this.setRequestErrorText('Не удалось выполнить запрос на переключение: ', error))
                .then(() => (this.isProcessing = false))
        },
    },
}
</script>

<style scoped>
.listed-asn-form {
    width: 100%;
}

.listed-asn-enabled-checkbox {
    margin-bottom: 20px;
}

.listed-asn-status {
    margin-right: 10px;
}

.listed-asn-status-enabled {
    color: #8cbf52;
}

.listed-asn-status:not(.listed-asn-status-enabled) {
    color: #1c2610;
}

.listed-asn-number {
    margin-right: 10px;
}

.listed-asn:not(.listed-asn-deleted) .listed-asn-status:hover {
    text-shadow: #fc0 0 0 10px;
}

.listed-asn-processing {
    filter: grayscale(1);
    opacity: 0.6;
}

.listed-asn-deleted .item-details {
    filter: sepia(1);
    opacity: 0.25;
}

.request-inline-wrapper {
    font-weight: normal !important;
}
</style>