<template>
    <div class="token">
        <div><b>ID:</b> {{ token.passwordSetTokenId }}</div>
        <div><b>Истекает:</b> {{ new Date(token.expires).toLocaleString() }}</div>
    </div>
</template>

<script>
export default {
    name: 'PasswordSetToken',
    props: {
        token: Object,
    },
}
</script>

<style scoped>
.token {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #181818;
}

.token > div:not(:last-child) {
    margin-bottom: 5px;
}
</style>