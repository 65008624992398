<template>
    <div>
        <ItemsList>
            <div class="item" v-for="license in licenses" v-bind:key="license.licenseId">
                <div class="item-date">
                    <DateTime v-bind:dt="license.added" />
                </div>
                <div class="item-details">
                    <div class="order-test" v-if="license.order.isTest">Лицензия для тестового ордера</div>
                    <span class="item-title">
                        Ордер:
                        <router-link v-bind:to="`/licensing/orders/${license.order.orderId}/view`">
                            {{ getOrderTitle(license.order) }}
                        </router-link>
                        <span v-if="license.order.status != 'Normal'">
                            <OrderStatus v-bind:order="license.order" lowerCase />
                        </span>
                    </span>
                    <br />
                    Клиент:
                    <router-link v-bind:to="`/licensing/customers/${license.order.customer.customerId}/view`">
                        {{ license.order.customer.email }}
                    </router-link>
                    <br />
                    Продукт:
                    <router-link v-bind:to="`/licensing/products/${license.order.product.productId}/view`">
                        {{ license.order.product.name }}
                    </router-link>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/licenses/${license.licenseId}/view`">Подробнее</router-link>
                    <router-link
                        v-bind:to="`/licensing/licenses/${license.licenseId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'
import OrderStatus from '../orders/OrderStatus.vue'

export default {
    name: 'LicensesList',
    components: {
        ItemsList,
        DateTime,
        OrderStatus,
    },
    props: {
        licenses: {
            type: Array,
            default: undefined,
        },
    },
    methods: {
        getOrderTitle: function(order) {
            if (order.registrator == undefined) return `#${order.orderId}`
            return `${order.registrator.name}-${order.registratorOrderId}`
        },
    },
}
</script>

<style scoped>
.order-test {
    color: #b772cf;
}
</style>