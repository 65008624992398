<template>
    <div class="form-field number-field" v-bind:class="{ 'number-field-disabled': !enabled }">
        <label v-bind:for="id">{{ labelText }}<span class="number-field-required" v-if="required"> *</span></label>
        <div class="number-field-main-container">
            <div class="number-field-input-container">
                <input
                    type="number"
                    class="number-field-input"
                    v-bind:id="id"
                    v-bind:name="name || id"
                    v-bind:min="min"
                    v-bind:max="max"
                    v-bind:step="step"
                    v-bind:placeholder="placeholderText"
                    v-bind:disabled="!enabled"
                    v-bind:value="value"
                    v-on:blur="blurHandler"
                    v-on:input="$emit('input', Number($event.target.value) || undefined)" />
            </div>
        </div>
        <FieldNotifications v-bind:errorText="errorText" />
    </div>
</template>

<script>
import FieldNotifications from './FieldNotifications.vue'

export default {
    name: 'NumberField',
    components: {
        FieldNotifications,
    },
    props: {
        value: Number,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        min: {
            type: Number,
            default: undefined,
        },
        max: {
            type: Number,
            default: undefined,
        },
        step: {
            type: Number,
            default: 1,
        },
        labelText: String,
        placeholderText: {
            type: String,
            default: undefined,
        },
        errorText: {
            type: String,
            default: '',
        },
    },
    methods: {
        blurHandler: function() {
            var s = document.getElementById(this.id).value
            if (s == '') return
            var val = Number(s)
            if (isNaN(val)) return
            if (this.min !== undefined && val < this.min) {
                document.getElementById(this.id).value = String(this.min)
            }
            if (this.max !== undefined && val > this.max) {
                document.getElementById(this.id).value = String(this.max)
            }
        },
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

.number-field:not(.number-field-disabled) .number-field-required {
    color: #dda;
}

.number-field-main-container {
    display: flex;
    align-items: flex-start;
}

.number-field-input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
}

.number-field-input-container:focus-within,
.number-field:not(.number-field-disabled) .number-field-input-container:hover {
    border-color: #7491b0;
}

input {
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #a2bfdb;
    font: inherit;
}

input::placeholder {
    font-style: italic;
    color: #777;
}

.number-field-input {
    width: 100%;
}

.number-field-notifications {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 18px;
    font-size: 0.8em;
}

.number-field-notification-error {
    margin: 0px 10px 0px 0px;
    padding: 0px 8px 2px 8px;
    border-radius: 0px 0px 4px 4px;
    background-color: #41556b;
    color: #faa;
}

.number-field-disabled {
    color: #555;
}

.number-field-disabled .number-field-input-container {
    border-color: #2c394a;
    background-color: #232e3b;
}

.number-field-disabled input {
    color: #465a70;
}

/* костыль, чтобы автозаполнение в Chrome не меняло радикально стиль поля */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2bfdb;
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
}
</style>
