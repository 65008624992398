<template>
    <div>
        <div id="nonauthorized">
            <div id="nonauthorized-content">
                <Environment />
                <img id="logo" alt="RT logo" src="../../assets/layout/logo.svg" />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Environment from './Environment.vue'
import { updateTitle } from '../../common.js'

export default {
    name: 'NonAuthorized',
    components: {
        Environment,
    },
    mounted: function() {
        updateTitle()
    },
    updated: function() {
        updateTitle()
    },
}
</script>

<style>
#nonauthorized {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
}

.screen-s #nonauthorized {
    align-items: flex-start;
}

#nonauthorized-content {
    padding: 20px 50px;
    width: 350px;
    background-color: #222;
    border-radius: 20px;
    box-sizing: content-box;
}

.screen-s #nonauthorized-content {
    margin-top: 20px;
    border-radius: 0px;
}

#logo {
    margin-bottom: 20px;
}

#nonauthorized h1,
#nonauthorized h2,
#nonauthorized h3 {
    margin-top: 0px;
}
</style>