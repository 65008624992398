<template>
    <div class="reputation-flag-widget" v-bind:class="classByFlagName">
        <span v-if="showScope"> {{ flagScope }}: </span>
        <span> {{ flagName }} </span>
    </div>
</template>

<script>
export default {
    name: 'ReputationFlagWidget',

    props: {
        flag: {
            type: String,
            required: true,
        },
        showScope: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        flagScope: function() {
            var scope = this.flag.split(':')[0]
            switch (scope) {
                case 'Asn':
                    return 'ASN'
                case 'AsnBlock':
                    return 'Блок ASN'
                case 'StaticBlock':
                    return 'Статичный блок'
                case 'IpAddress':
                    return 'IP-адрес'
                default:
                    return scope
            }
        },
        flagName: function() {
            return this.flag.split(':')[1]
        },
        classByFlagName: function() {
            switch (this.flagName) {
                case 'Blacklisted':
                case 'UnfairWebSessions':
                case 'DirectWebSessions':
                    return { 'reputation-flag-widget-negative': true }
                case 'Whitelisted':
                    return { 'reputation-flag-widget-positive': true }
                default:
                    return { 'reputation-flag-widget-neutral': true }
            }
        },
    },
}
</script>

<style scoped>
.reputation-flag-widget {
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 2px;
    padding: 0px 5px;
    font-family: monospace;
}

.reputation-flag-widget-neutral {
    border-radius: 4px;
    background-color: #999;
    color: #111;
}

.reputation-flag-widget-negative {
    background-color: #c20;
    color: #ddd;
}

.reputation-flag-widget-positive {
    background-color: #4b2;
    color: #000;
}
</style>