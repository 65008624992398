<template>
    <div class="warning">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Warning',
}
</script>

<style scoped>
.warning {
    margin: 20px 0px;
    padding: 10px 10px 10px 50px;
    border: 1px dashed #dd9;
    border-radius: 6px;
    color: #dd9;
    background-image: url('../../assets/misc/warning.svg');
    background-position: 13px center;
    background-repeat: no-repeat;
    background-size: auto 24px;
}
</style>
