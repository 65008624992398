<template>
    <div class="form-field search-field" v-bind:class="{ 'search-field-disabled': !enabled || loadingInfo }">
        <label v-bind:for="id">{{ labelText }}<span class="search-field-required" v-if="required"> *</span></label>
        <div class="search-field-input-container">
            <input
                type="text"
                v-bind:id="id"
                v-bind:name="name || id"
                v-bind:placeholder="placeholderText"
                v-bind:disabled="!enabled || loadingInfo"
                v-bind:value="currentText"
                v-on:input.prevent="inputHandler($event.target.value)"
                v-on:keydown="keydownHandler($event)"
                v-on:focus="focusHandler"
                v-on:blur="blurHandler" />
            <div class="search-results-container" v-if="showSearchResults">
                <div v-if="searchResults != undefined && searchResults.length > 0" class="search-results">
                    <div class="search-results-title">Результаты поиска:</div>
                    <div
                        class="search-result"
                        v-for="(searchResult, index) in searchResults"
                        v-bind:key="searchResult[valueKey]"
                        v-bind:class="{ 'search-result-keyboard-selected': index == keyboardSelectedIndex }"
                        v-on:click="approveSearchResult(index)">
                        {{ searchResult[textKey] }}
                    </div>
                </div>
                <div v-else-if="searchResults != undefined && searchResults.length == 0" class="search-results">
                    <div class="search-results-title">Ничего не найдено</div>
                </div>
                <div v-else class="search-results-loader">
                    <Loader height="60px" />
                </div>
            </div>
        </div>
        <FieldNotifications v-bind:errorText="currentErrorText" />
    </div>
</template>

<script>
import FieldNotifications from './FieldNotifications.vue'
import Loader from '../misc/Loader.vue'
import { buildUrl, getAuthorizedRequest, postAuthorizedRequest } from '../../common.js'

export default {
    name: 'SearchField',

    components: {
        FieldNotifications,
        Loader,
    },

    props: {
        value: undefined,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        labelText: String,
        placeholderText: {
            type: String,
            default: 'Введите для поиска',
        },
        errorText: {
            type: String,
            default: '',
        },
        valueKey: String,
        textKey: String,
        getInfoUrl: String,
        searchUrl: String,
        searchKey: String,
        searchDelay: {
            type: Number,
            default: 500,
        },
        searchPageSize: {
            type: Number,
            default: 5,
        },
    },

    data: function() {
        return {
            approvedValue: this.value,
            approvedText: undefined,
            currentText: undefined,
            searchTimer: undefined,
            searchResults: undefined,
            showSearchResults: false,
            keyboardSelectedIndex: -1,
            loadingInfo: false,
            currentErrorText: this.errorText,
        }
    },

    computed: {
        searchUrlFull: function() {
            return buildUrl(this.searchUrl, { pageSize: this.searchPageSize })
        },
    },

    watch: {
        value: function(newValue) {
            this.value = newValue
            this.approvedValue = this.value
            this.approvedText = undefined
            this.currentText = undefined
            this.loadFromValue()
        },

        errorText: function(val) {
            this.currentErrorText = val
        },
    },

    created: function() {
        this.loadFromValue()
    },

    methods: {
        loadFromValue: function() {
            if (!this.value || !this.getInfoUrl) return

            this.loadingInfo = true
            this.currentText = 'Загрузка...'

            getAuthorizedRequest(this.getInfoUrl)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => this.setApproved(this.value, data[this.textKey]))
                            this.loadingInfo = false
                            break
                        default:
                            this.currentErrorText = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => {
                    this.currentErrorText = 'Не удалось выполнить запрос'
                })
        },

        focusHandler: function() {
            if (!this.currentText) this.inputHandler('')
        },

        blurHandler: function() {
            // при клике по результату в отображённом списке blur выстреливает раньше,
            // чем клик по результату. если сразу откатывать заполненное значение, то
            // значение поля "мерцает" - сначала откатывается, а потом ставится на выбранное.
            // при этом нет уверенности, что клик в принципе будет стабильно выстреливать,
            // так как тут скрывается элемент, по которому он был сделан.
            setTimeout(() => {
                clearTimeout(this.searchTimer)
                this.currentText = this.approvedText
                this.showSearchResults = false
            }, 100)
        },

        inputHandler: function(value) {
            this.currentText = value
            clearTimeout(this.searchTimer)
            if (value.trim() == '' && !this.required) this.setApproved(undefined, '')
            this.searchTimer = setTimeout(() => this.performSearch(value.trim()), this.searchDelay)
        },

        keydownHandler: function(event) {
            if (!this.showSearchResults) return
            switch (event.key) {
                case 'ArrowDown':
                    this.keyboardSelectedIndex = Math.min(++this.keyboardSelectedIndex, this.searchResults.length - 1)
                    event.preventDefault()
                    break
                case 'ArrowUp':
                    this.keyboardSelectedIndex = Math.max(--this.keyboardSelectedIndex, 0)
                    event.preventDefault()
                    break
                case 'Enter':
                    this.approveSearchResult(this.keyboardSelectedIndex)
                    event.preventDefault()
                    break
            }
        },

        performSearch: function(searchValue) {
            var body = {}
            body[this.searchKey] = searchValue

            this.searchResults = undefined
            this.showSearchResults = true
            this.keyboardSelectedIndex = -1
            postAuthorizedRequest(this.searchUrlFull, body)
                .then(res => res.json())
                .then(data => {
                    this.searchResults = data.items
                    this.searchTimer = undefined
                })
        },

        approveSearchResult: function(index) {
            var result = this.searchResults[index]
            this.setApproved(result[this.valueKey], result[this.textKey])
        },

        setApproved: function(value, text) {
            this.approvedValue = value
            this.approvedText = text
            this.currentText = this.approvedText
            this.showSearchResults = false
            this.keyboardSelectedIndex = -1
            this.currentErrorText = ''
            this.$emit('input', this.approvedValue)
        },
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

.search-field:not(.search-field-disabled) .search-field-required {
    color: #dda;
}

.search-field-input-container {
    position: relative;
    padding: 4px 8px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
}

.search-field-input-container:focus-within,
.search-field:not(.search-field-disabled) .search-field-input-container:hover {
    border-color: #7491b0;
}

input {
    width: 100%;
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #a2bfdb;
    font: inherit;
}

input::placeholder {
    font-style: italic;
    color: #777;
}

.search-results-container {
    position: absolute;
    top: 100%;
    left: 10px;
    background-color: #111;
    border: inherit;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 10;
}

.search-results-title {
    padding: 5px 10px;
    font-size: 0.9em;
}

.search-result {
    padding: 5px 10px;
    cursor: pointer;
    color: #718dab;
    line-height: 1;
}

.search-result:hover {
    color: #99bade;
}

.search-result:last-child {
    margin-bottom: 5px;
}

.search-result-keyboard-selected {
    background-color: #1f2a36;
}

.search-results-loader {
    width: 200px;
}

.search-field-notifications {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 18px;
    font-size: 0.8em;
}

.search-field-notification-error {
    margin: 0px 10px 0px 0px;
    padding: 0px 8px 2px 8px;
    border-radius: 0px 0px 4px 4px;
    color: #faa;
    background-color: #41556b;
}

.search-field-disabled {
    color: #555;
}

.search-field-disabled .search-field-input-container {
    border-color: #2c394a;
    background-color: #232e3b;
}

.search-field-disabled input {
    color: #465a70;
}

/* костыль, чтобы автозаполнение в Chrome не меняло радикально стиль поля */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2bfdb;
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
}
</style>
