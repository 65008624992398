<template>
    <span>
        <span v-if="dt">
            <span class="datetime-part">{{ dateString }} </span>
            <span class="datetime-part">{{ timeString }}</span>
        </span>
        <span v-else> &lt; Нет даты/времени &gt; </span>
    </span>
</template>

<script>
export default {
    name: 'DateTime',
    props: {
        dt: String,
    },
    computed: {
        dateString: function() {
            return new Date(this.dt).toLocaleDateString()
        },
        timeString: function() {
            return new Date(this.dt).toLocaleTimeString()
        },
    },
}
</script>

<style scoped>
.datetime-part {
    display: inline-block;
    margin-right: 5px;
}
</style>