<template>
    <div>
        <h1 id="title-source">Детали клиента #{{ customerId }}</h1>

        <RequestWrapper
            v-bind:data="customer"
            v-bind:errorText="customerErrorText"
            v-bind:errorCode="customerErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Дата и время добавления">
                        <DateTime v-bind:dt="customer.added" />
                    </ItemDetail>
                    <ItemDetail name="E-mail">
                        <EmailAddressLink v-bind:address="customer.email" />
                    </ItemDetail>
                    <ItemDetail name="Дополнительный e-mail">
                        <EmailAddressLink v-bind:address="customer.ccEmail" />
                    </ItemDetail>
                    <ItemDetail name="Имя">
                        {{ customer.name || 'Не установлено' }}
                    </ItemDetail>
                    <ItemDetail name="Компания">
                        {{ customer.company || 'Не установлена' }}
                    </ItemDetail>
                    <ItemDetail name="Почтовый индекс">
                        {{ customer.zip || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="Страна">
                        {{ customer.country || 'Не установлена' }}
                    </ItemDetail>
                    <ItemDetail name="Штат">
                        {{ customer.state || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="Город">
                        {{ customer.city || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="Адрес">
                        {{ customer.streetAddress || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="Язык">
                        {{ languageToString(customer.language) || 'Не установлен' }}
                    </ItemDetail>
                    <ItemDetail name="Комментарий">
                        {{ customer.comment || '&lt; Пусто &gt;' }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button text="Редактировать" v-on:click="$router.push(`/licensing/customers/${customerId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/customers/${customerId}/delete`)" />
                </ButtonsBlock>

                <h2>Внешние идентификаторы</h2>
                <RequestWrapper
                    v-bind:data="customerExts"
                    v-bind:errorText="customerExtsErrorText"
                    v-bind:errorCode="customerExtsErrorCode">
                    <template v-slot:default>
                        <WrappedContent v-if="customerExts.length > 0">
                            <div
                                v-for="customerExt in customerExts"
                                v-bind:key="customerExt.customerExtId"
                                class="ext-id">
                                <div>
                                    <b>Регистратор:</b>
                                    <router-link
                                        v-bind:to="`/licensing/registrators/${customerExt.registrator.registratorId}/view`">
                                        {{ customerExt.registrator.name }}
                                    </router-link>
                                </div>
                                <div><b>Идентификатор:</b> {{ customerExt.registratorCustomerId }}</div>
                            </div>
                        </WrappedContent>
                        <div v-else>Внешние привязки отсутствуют</div>
                    </template>
                </RequestWrapper>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/orders?mode=search&customerId=${customerId}`">
                            Ордеры, сделанные этим клиентом
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&customerId=${customerId}`">
                            Лицензии, выписанные для ордеров этого клиента
                        </router-link>
                    </li>
                    <li v-if="customer">
                        <router-link v-bind:to="`/notification/emails?mode=search&recipientEmail=${customer.email}`">
                            Письма, отправленные на основной e-mail клиента
                        </router-link>
                    </li>
                    <li v-if="customer && customer.ccEmail">
                        <router-link v-bind:to="`/notification/emails?mode=search&recipientEmail=${customer.ссEmail}`">
                            Письма, отправленные на дополнительный e-mail клиента
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/customers/${customerId}`">
                            Записи аудита на этого клиента
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import DateTime from '../../../misc/DateTime.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { customerLanguageToString } from '../../../../common.js'

export default {
    name: 'CustomerViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        DateTime,
        EmailAddressLink,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            customerId: this.$route.params.id,
            customer: undefined,
            customerErrorText: undefined,
            customerErrorCode: undefined,
            customerExts: undefined,
            customerExtsErrorText: undefined,
            customerExtsErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/customers/${this.customerId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.customer = data))
                        break
                    default:
                        this.customerErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.customerErrorText = error))

        postAuthorizedRequest('/api/licensing/customer-exts/search', { customerIdExact: Number(this.customerId) })
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.customerExts = data.items))
                        break
                    default:
                        this.customerExtsErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.customerExtsErrorText = error))
    },
    methods: {
        languageToString: function(language) {
            return customerLanguageToString(language)
        },
    },
}
</script>

<style scoped>
.ext-id {
    padding: 15px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #181818;
}
</style>