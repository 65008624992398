<template>
    <div>
        <h1 id="title-source">Пользователи</h1>
        <ButtonsBlock>
            <Button text="Создать пользователя" v-on:click="$router.push('/administration/users/create')" />
        </ButtonsBlock>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <UsersList v-bind:users="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import PageController from '../../PageController.vue'
import UsersList from './UsersList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'UsersPage',
    components: {
        Button,
        ButtonsBlock,
        PageController,
        UsersList,
        RequestWrapper,
    },
    data: function() {
        return {
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
        }
    },
    watch: {
        $route() {
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.loadItems()
    },
    methods: {
        loadItems: function() {
            this.itemsPagination = undefined
            var query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }
            var url = buildUrl('/api/administration/users', query)
            getAuthorizedRequest(url)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.itemsPagination = data))
                            break
                        default:
                            this.itemsPaginationErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.itemsPaginationErrorText = error))
        },
        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },
    },
}
</script>