<template>
    <div>
        <ItemsList>
            <div class="item" v-for="registrator in registrators" v-bind:key="registrator.registratorId">
                <div class="item-details">
                    <span class="item-title">{{ registrator.name }}</span>
                    <br />
                    Сайт: <ExternalLink v-bind:url="registrator.site" />
                    <br />
                    E-mail: <EmailAddressLink v-bind:address="registrator.email" />
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/registrators/${registrator.registratorId}/view`"
                        >Подробнее</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/registrators/${registrator.registratorId}/edit`"
                        class="access-level-admin"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/registrators/${registrator.registratorId}/delete`"
                        class="access-level-admin"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'

export default {
    name: 'RegistratorsList',
    components: {
        ItemsList,
        ExternalLink,
        EmailAddressLink,
    },
    props: {
        registrators: {
            type: Array,
            default: undefined,
        },
    },
}
</script>