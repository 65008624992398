<template>
    <span class="request-inline-wrapper">
        <span v-if="errorText != undefined" v-bind:style="{ height: height }" class="request-inline-wrapper-error">
            {{ errorTextTitle }}{{ errorText }}
        </span>
        <span v-else-if="errorCode != undefined" v-bind:style="{ height: height }" v-bind:class="errorCodeStyle">
            {{ errorCodeTitle }}{{ errorCode }}
        </span>
        <span v-else-if="data == undefined" v-bind:height="height">
            {{ loadingText }}
        </span>
        <slot v-else />
    </span>
</template>

<script>
export default {
    name: 'RequestInlineWrapper',
    props: {
        loadingText: {
            type: String,
            default: 'Загрузка...',
        },
        data: {
            default: undefined,
        },
        errorTextTitle: {
            type: String,
            default: 'Не удалось выполнить запрос: ',
        },
        errorCodeTitle: {
            type: String,
            default: 'Запрос завершился ошибкой c кодом ',
        },
        errorText: {
            type: String,
            default: undefined,
        },
        errorCode: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        errorCodeStyle: function() {
            var result = { 'request-inline-wrapper-error': true }
            result[`request-inline-wrapper-error-${this.errorCode.toString().substr(0, 1)}xx`] = true
            return result
        },
    },
}
</script>

<style scoped>
.request-inline-wrapper-error {
    color: #a50;
}

.request-inline-wrapper-error-4xx {
    color: #aa0;
}

.request-inline-wrapper-error-5xx {
    color: #a22;
}
</style>
