<template>
    <div class="web-session-flags-widget-container">
        <div class="web-session-flags-widget">
            <div v-bind:class="verdictClass" v-bind:title="verdictTitle" />
            <div
                v-if="hasFlag('HasPageScrollEvents')"
                class="web-session-flag web-session-flag-page-scroll-events"
                title="Есть скроллы страницы" />
            <div
                v-if="hasFlag('HasElementClickEvents')"
                class="web-session-flag web-session-flag-elements-click-events"
                title="Есть клики по элементам" />
            <div
                v-if="hasFlag('HasInputChangeEvents')"
                class="web-session-flag web-session-flag-input-change-events"
                title="Есть изменения полей ввода" />
        </div>
        <br />
    </div>
</template>

<script>
import { getSessionVerdict, getSessionVerdictTitle } from './verdict.js'

export default {
    name: 'FlagsWidget',

    props: {
        session: {
            type: Object,
            required: true,
        },
    },

    computed: {
        verdictClass: function() {
            var result = { 'web-session-flag': true }
            result[`web-session-flag-verdict-${getSessionVerdict(this.session)}`] = true
            return result
        },
        verdictTitle: function() {
            return getSessionVerdictTitle(this.session)
        },
    },

    methods: {
        hasFlag: function(flag) {
            return this.session.flags.indexOf(flag) >= 0
        },
    },
}
</script>

<style scoped>
.web-session-flags-widget-container {
    margin: 5px 0px 0px 0px;
}

.web-session-flags-widget {
    display: inline-block;
    height: 26px;
}

.web-session-flag {
    display: inline-block;
    height: 100%;
    width: 26px;
    background-color: #2a68;
    border-radius: 4px;
    background-position: center center;
    background-size: auto 70%;
    background-repeat: no-repeat;
}

.web-session-flag:not(:first-child) {
    margin-left: 2px;
}

.web-session-flag-verdict-none {
    background-color: #cb4;
    background-image: url('../../../../assets/telemetry/web-sessions/verdict-none.svg');
}

.web-session-flag-verdict-fair {
    background-color: #4c8;
    background-size: auto 90%;
    background-image: url('../../../../assets/telemetry/web-sessions/verdict-fair.svg');
}

.web-session-flag-verdict-unfair {
    background-color: #d64;
    background-size: auto 90%;
    background-image: url('../../../../assets/telemetry/web-sessions/verdict-unfair.svg');
}

.web-session-flag-page-scroll-events {
    background-image: url('../../../../assets/telemetry/web-sessions/event-page-scroll.svg');
}

.web-session-flag-elements-click-events {
    background-image: url('../../../../assets/telemetry/web-sessions/event-click.svg');
}

.web-session-flag-input-change-events {
    background-image: url('../../../../assets/telemetry/web-sessions/event-change-text.svg');
}
</style>