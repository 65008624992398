<template>
    <div>
        <ItemsList>
            <ListedIpAddressBlockItem
                v-for="item in items"
                v-bind:key="item.listedIpAddressBlockId"
                v-bind:listType="listType"
                v-bind:item="item"
                v-bind:mode="item.mode || 'view'" />
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import ListedIpAddressBlockItem from './ListedIpAddressBlockItem.vue'

export default {
    name: 'ListedIpAddressBlocksList',
    components: {
        ItemsList,
        ListedIpAddressBlockItem,
    },
    props: {
        listType: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: undefined,
        },
    },
}
</script>