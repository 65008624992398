<template>
    <div>
        <h1 id="title-source">Мой профиль</h1>

        <RequestWrapper v-bind:data="profile" v-bind:errorText="profileErrorText" v-bind:errorCode="profileErrorCode">
            <template v-slot:default>
                <div id="me-role"><b>Роль:</b> {{ roleString }}</div>
                <form>
                    <WrappedContent>
                        <TextField
                            id="login"
                            labelText="Логин:"
                            required
                            v-bind:enabled="!isRootAdmin"
                            v-bind:errorText="loginValidationError"
                            v-model="profile.login" />
                        <TextField
                            id="email"
                            labelText="Электронная почта:"
                            required
                            v-bind:errorText="emailValidationError"
                            v-model="profile.email" />
                        <TextField id="firstName" labelText="Имя:" v-model="profile.firstName" />
                        <TextField id="lastName" labelText="Фамилия:" v-model="profile.lastName" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="patchProfile"
                            v-bind:successText="profilePatchSuccess"
                            v-bind:failureText="profilePatchFailure" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>

        <h2>Изменить пароль</h2>
        <form>
            <WrappedContent>
                <TextField
                    id="password"
                    labelText="Пароль:"
                    required
                    isPassword
                    v-bind:enabled="!isRootAdmin"
                    v-bind:errorText="passwordValidationError"
                    v-model="password" />
            </WrappedContent>
            <PasswordRequirements />
            <ButtonsBlock>
                <Button
                    text="Изменить пароль"
                    v-bind:enabled="!isRootAdmin"
                    v-on:click="patchPassword"
                    v-bind:successText="passwordPatchSuccess"
                    v-bind:failureText="passwordPatchFailure" />
            </ButtonsBlock>
        </form>

        <h2>Активные сессии</h2>
        <RequestWrapper
            v-bind:data="sessions"
            v-bind:errorText="sessionsErrorText"
            v-bind:errorCode="sessionsErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <Session
                        v-for="session in sessions"
                        v-bind:key="session.sessionId"
                        v-bind:session="session"
                        v-bind:deleteFailureText="session.deleteFailure"
                        v-on:deleteClick="(callback) => deleteSession(session, callback)" />
                </WrappedContent>
            </template>
        </RequestWrapper>

        <ButtonsBlock>
            <Button text="Выйти" icon="layout/header/logout.svg" v-on:click="performSignout" />
        </ButtonsBlock>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import TextField from '../../inputs/TextField.vue'
import PasswordRequirements from '../../misc/PasswordRequirements.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import Session from '../administration/users/Session.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, patchAuthorizedRequest, deleteAuthorizedRequest, signout } from '../../../common.js'
import { userInfo, validateLogin, validateEmail, validatePassword, userRoleToString } from '../../../common.js'

export default {
    name: 'MePage',
    components: {
        TextField,
        PasswordRequirements,
        Button,
        ButtonsBlock,
        WrappedContent,
        Session,
        RequestWrapper,
    },
    data: function() {
        return {
            profile: undefined,
            profileErrorText: undefined,
            profileErrorCode: undefined,
            role: undefined,
            loginValidationError: '',
            emailValidationError: '',
            profilePatchSuccess: '',
            profilePatchFailure: '',
            password: '',
            passwordValidationError: '',
            passwordPatchSuccess: '',
            passwordPatchFailure: '',
            sessions: undefined,
            sessionsErrorText: undefined,
            sessionsErrorCode: undefined,
        }
    },
    computed: {
        roleString: function() {
            return userRoleToString(this.role)
        },
        isRootAdmin: function() {
            if (!this.profile) return false
            return this.profile.login.toLowerCase() == 'admin'
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.loadSessions()

        getAuthorizedRequest('/api/me')
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => {
                            this.role = data.role
                            this.profile = {
                                login: data.login,
                                email: data.email || '',
                                firstName: data.firstName || '',
                                lastName: data.lastName || '',
                            }
                        })
                        break
                    default:
                        this.profileErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.profileErrorText = error))
    },
    methods: {
        patchProfile: function(callback) {
            this.profile.login = this.profile.login.trim()
            this.profile.email = this.profile.email.trim()
            this.profile.firstName = this.profile.firstName.trim()
            this.profile.lastName = this.profile.lastName.trim()

            var validationOk = true

            this.loginValidationError = validateLogin(this.profile.login)
            if (this.loginValidationError) {
                validationOk = false
            }

            this.emailValidationError = validateEmail(this.profile.email)
            if (this.emailValidationError) {
                validationOk = false
            }

            if (!validationOk) {
                callback()
                return
            }

            this.profilePatchSuccess = ''
            this.profilePatchFailure = ''

            patchAuthorizedRequest('/api/me', this.profile)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            userInfo.profile.login = this.profile.login
                            userInfo.profile.email = this.profile.email
                            userInfo.profile.firstName = this.profile.firstName
                            userInfo.profile.lastName = this.profile.lastName
                            this.profilePatchSuccess = 'Данные профиля сохранены'
                            break
                        default:
                            this.profilePatchFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.profilePatchFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },

        patchPassword: function(callback) {
            var validationOk = true
            this.password = this.password.trim()

            this.passwordValidationError = validatePassword(this.password)
            if (this.passwordValidationError) {
                validationOk = false
            }

            if (!validationOk) {
                callback()
                return
            }

            this.passwordPatchSuccess = ''
            this.passwordPatchFailure = ''

            patchAuthorizedRequest('/api/me', { password: this.password })
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.passwordPatchSuccess = 'Пароль обновлён'
                            this.password = ''
                            this.loadSessions()
                            break
                        default:
                            this.passwordPatchFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.passwordPatchFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },

        loadSessions: function() {
            this.sessions = undefined
            getAuthorizedRequest('/api/me/sessions')
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                data.forEach(session => {
                                    session.deleteFailure = ''
                                })
                                this.sessions = data
                            })
                            break
                        default:
                            this.sessionsErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.sessionsErrorText = error))
        },

        deleteSession: function(session, callback) {
            deleteAuthorizedRequest(`/api/me/sessions/${session.sessionId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.loadSessions()
                            break
                        default:
                            session.deleteFailure = `Плохой ответ на запрос (${res.status})`
                    }
                })
                .catch(() => (session.deleteFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },

        performSignout: function() {
            signout(this.$router)
        },
    },
}
</script>

<style scoped>
#me-role {
    margin-bottom: 20px;
}
</style>