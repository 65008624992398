<template>
    <div class="form-field datetime-field" v-bind:class="{ 'datetime-field-disabled': !enabled }">
        <label v-bind:for="id">{{ labelText }}<span class="datetime-field-required" v-if="required"> *</span></label>
        <div class="datetime-field-main-container">
            <div class="datetime-field-input-container">
                <input
                    type="text"
                    class="datetime-field-input"
                    maxlength="28"
                    v-bind:class="computedClass"
                    v-bind:id="id"
                    v-bind:name="name || id"
                    v-bind:placeholder="placeholderText"
                    v-bind:disabled="!enabled"
                    v-model="currentValue"
                    v-on:input="inputHandler($event.target.value)" />
                <PopupMenu v-if="enabled && periodPresets" expandedTitle="Установить срок">
                    <span v-for="(preset, index) in periodPresets" v-bind:key="index">
                        <a href="javascript:;" class="popup-menu-closer" v-on:click="applyPreset(preset)">{{
                            getPresetTitle(preset)
                        }}</a>
                        <br />
                    </span>
                </PopupMenu>
            </div>
        </div>
        <FieldNotifications v-bind:errorText="errorText" />
    </div>
</template>

<script>
import PopupMenu from '../misc/PopupMenu.vue'
import FieldNotifications from './FieldNotifications.vue'
import { isValidDateTime } from '../../common.js'

export default {
    name: 'DateTimeField',

    components: {
        PopupMenu,
        FieldNotifications,
    },

    props: {
        value: String,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        labelText: String,
        placeholderText: {
            type: String,
            default: 'YYYY-MM-ddTHH:mm:ssZ',
        },
        errorText: {
            type: String,
            default: '',
        },
        periodPresets: {
            type: Array,
            default: undefined,
        },
    },

    watch: {
        value: function(newValue) {
            if (!this.isEmitting) this.currentValue = newValue
            this.isEmitting = false
        },
    },

    data: function() {
        return {
            currentValue: this.value,
            isEmitting: false,
        }
    },

    computed: {
        computedClass: function() {
            return {
                'datetime-field-input-invalid': !this.isValid,
                'datetime-field-input-empty': !this.currentValue,
            }
        },
        isValid: function() {
            return isValidDateTime(this.currentValue)
        },
    },

    methods: {
        inputHandler: function(value) {
            this.isEmitting = true
            this.$emit('input', this.isValid ? new Date(value).toISOString() : undefined)
        },

        getPresetTitle: function(preset) {
            switch (preset.unit) {
                case 'year':
                    return `${preset.count} лет`
                case 'month':
                    return `${preset.count} месяцев`
                case 'week':
                    return `${preset.count} недель`
                case 'day':
                    return `${preset.count} дней`
                case 'hour':
                    return `${preset.count} часов`
                case 'minute':
                    return `${preset.count} минут`
            }
        },

        applyPreset: function(preset) {
            var date = new Date()

            switch (preset.unit) {
                case 'year':
                    date.setUTCFullYear(date.getUTCFullYear() + preset.count)
                    break
                case 'month':
                    date.setUTCMonth(date.getUTCMonth() + preset.count)
                    break
                case 'week':
                    date.setUTCDate(date.getUTCDate() + preset.count * 7)
                    break
                case 'day':
                    date.setUTCDate(date.getUTCDate() + preset.count)
                    break
                case 'hour':
                    date.setUTCHours(date.getUTCHours() + preset.count)
                    break
                case 'minute':
                    date.setUTCMinutes(date.getUTCMinutes() + preset.count)
                    break
            }

            this.currentValue = date.toISOString()
            this.inputHandler(this.currentValue)
        },
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

.datetime-field:not(.datetime-field-disabled) .datetime-field-required {
    color: #dda;
}

.datetime-field-main-container {
    display: flex;
    align-items: flex-start;
}

.datetime-field-input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
}

.datetime-field-input-container:focus-within,
.datetime-field:not(.datetime-field-disabled) .datetime-field-input-container:hover {
    border-color: #7491b0;
}

input {
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #a2bfdb;
    font: inherit;
}

input::placeholder {
    font-style: italic;
    color: #777;
}

.datetime-field-input {
    width: calc(100% - 18px);
}

.datetime-field-input-invalid:not(.datetime-field-input-empty) {
    text-decoration: underline wavy rgba(255, 255, 0, 0.5) 1px;
}

.datetime-field-presets {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    color: #ddd;
    background-color: #41556b;
    cursor: pointer;
    opacity: 0.5;
    text-align: center;
    border-radius: 4px;
}

.datetime-field-input-container:focus-within .datetime-field-presets,
.datetime-field:not(.datetime-field-disabled) .datetime-field-input-container:hover .datetime-field-presets {
    opacity: 1;
}

.datetime-field-disabled {
    color: #555;
}

.datetime-field-disabled .datetime-field-input-container {
    border-color: #2c394a;
    background-color: #232e3b;
}

.datetime-field-disabled input {
    color: #465a70;
}

/* костыль, чтобы автозаполнение в Chrome не меняло радикально стиль поля */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2bfdb;
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
}
</style>
