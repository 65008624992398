const localStorageKey = 'webSessionsCompareFingerprints'

export function getSessionsCompareArray() {
    let arrayString = localStorage.getItem(localStorageKey)
    if (arrayString === null) return []
    let array = JSON.parse(arrayString)
    return Array.isArray(array) ? array : []
}

export function setSessionsCompareArray(array) {
    array.sort((a, b) => a - b)
    localStorage.setItem(localStorageKey, JSON.stringify(array))
}

export function getIsSessionsCompareAvailable() {
    return getSessionsCompareArray().length >= 2
}

export function getIsSessionCompareAdded(webSessionId) {
    return getSessionsCompareArray().indexOf(webSessionId) >= 0
}

export function addSessionCompare(webSessionId) {
    if (getIsSessionCompareAdded(webSessionId)) return
    let array = getSessionsCompareArray()
    array.push(webSessionId)
    setSessionsCompareArray(array)
}

export function removeSessionCompare(webSessionId) {
    while (getIsSessionCompareAdded(webSessionId)) {
        let array = getSessionsCompareArray()
        let index = array.indexOf(webSessionId);
        array.splice(index, 1);
        setSessionsCompareArray(array)
    }
}