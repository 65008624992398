<template>
    <div class="maintainer">
        <SubSection v-bind:title="maintainer.displayName">
            <div v-bind:class="stateStyle">{{ stateString }}</div>
            <form>
                <b>Запущено:</b> <DateTime v-bind:dt="maintainer.lastExecution" />
                <span v-if="maintainer.hasLastLog">(<a v-bind:href="`${this.apiBasePath}/last-log`">лог</a>)</span
                ><br />
                <b>Результат:</b> <span v-bind:class="resultStyle">{{ resultString || '&lt; Нет данных &gt;' }}</span
                ><br />
                <b>Заняло:</b> {{ maintainer.lastElapsed || '&lt; Нет данных &gt;' }}<br />
                <b>Запланировано:</b> <DateTime v-bind:dt="maintainer.nextExecution" />
                <ButtonsBlock>
                    <Button
                        text="Выполнить"
                        v-bind:enabled="canExecute"
                        v-on:click="performExecute"
                        v-bind:successText="executeRequestSuccess"
                        v-bind:failureText="executeRequestFailure" />
                </ButtonsBlock>
            </form>
        </SubSection>
    </div>
</template>

<script>
import SubSection from '../../misc/SubSection.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import DateTime from '../../misc/DateTime.vue'
import { postAuthorizedRequest } from '../../../common.js'

export default {
    name: 'Maintainer',

    components: {
        SubSection,
        Button,
        ButtonsBlock,
        DateTime,
    },

    props: {
        apiBasePath: {
            type: String,
            required: true,
        },
        maintainer: {
            type: String,
            default: undefined,
        },
    },

    data: function() {
        return {
            executeRequestSuccess: '',
            executeRequestFailure: '',
        }
    },

    computed: {
        canExecute: function() {
            switch (this.maintainer.state) {
                case 'Idle':
                case 'Delayed':
                    return true
                default:
                    return false
            }
        },

        stateString: function() {
            switch (this.maintainer.state) {
                case 'Disabled':
                    return 'Выключена'
                case 'Idle':
                    return 'Ожидает выполнения'
                case 'Delayed':
                    return 'Отложенный старт...'
                case 'Executing':
                    return 'Выполняется...'
                case 'Stopped':
                    return 'Завершена'
                case 'Crashed':
                    return 'Сбой в работе'
                default:
                    return this.maintainer.state || '< Неизвестно >'
            }
        },

        stateStyle: function() {
            var result = { 'maintainer-state': true }
            result[`maintainer-state-${this.maintainer.state}`] = true
            return result
        },

        resultString: function() {
            switch (this.maintainer.lastResult) {
                case 'Success':
                    return 'Успех'
                case 'Failure':
                    return 'Провал'
                case 'Partial':
                    return 'Частичный успех'
                case 'Skipped':
                    return 'Пропуск'
                default:
                    return undefined
            }
        },

        resultStyle: function() {
            var result = { 'maintainer-result': true }
            if (this.maintainer.lastResult) result[`maintainer-result-${this.maintainer.lastResult}`] = true
            return result
        },
    },

    methods: {
        performExecute: function(callback) {
            this.executeRequestSuccess = ''
            this.executeRequestFailure = ''

            postAuthorizedRequest(`${this.apiBasePath}/execute`)
                .then(res => {
                    switch (res.status) {
                        case 202:
                            this.executeRequestSuccess = 'Служба запущена'
                            break
                        default:
                            this.executeRequestFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => {
                    this.executeRequestFailure = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },
    },
}
</script>

<style scoped>
.maintainer-state {
    margin-top: 5px;
    margin-bottom: 5px;
}

.maintainer-state-Disabled {
    color: #666;
}

.maintainer-state-Idle {
    color: #ddd;
}

.maintainer-state-Delayed {
    color: #d80;
    animation: maintainerBusyAnimation 2s ease 0s infinite normal none;
}

.maintainer-state-Executing {
    color: #080;
    animation: maintainerBusyAnimation 2s ease 0s infinite normal none;
}

.maintainer-state-Stopped {
    color: #aa0;
}

.maintainer-state-Crashed {
    color: #d33;
}

.maintainer-result-Success {
    color: #080;
}

.maintainer-result-Failure {
    color: #d33;
    animation: maintainerBusyAnimation 2s ease 0s infinite normal none;
}

.maintainer-result-Partial {
    color: #d80;
    animation: maintainerBusyAnimation 2s ease 0s infinite normal none;
}

.maintainer-result-Skipped {
    color: #59d;
}

@keyframes maintainerBusyAnimation {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0.5;
    }
}
</style>