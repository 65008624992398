<template>
    <div>
        <h1 id="title-source">Детали веб-сессии #{{ webSessionId }}</h1>

        <RequestWrapper
            v-bind:data="webSession"
            v-bind:errorText="webSessionErrorText"
            v-bind:errorCode="webSessionErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Дата и время добавления">
                        <DateTime v-bind:dt="webSession.added" />
                    </ItemDetail>
                    <ItemDetail name="Дата и время обновления">
                        <DateTime v-bind:dt="webSession.updated" />
                    </ItemDetail>
                    <ItemDetail name="Внешний ID сессии">
                        {{ webSession.externalId }}
                    </ItemDetail>
                    <ItemDetail name="Внешний ID пользователя">
                        {{ webSession.userTrackExternalId }}
                    </ItemDetail>
                    <ItemDetail name="IP-адрес">
                        <IpAddressPopupMenu v-bind:ip="webSession.ipAddress" />
                    </ItemDetail>
                    <ItemDetail name="Сайт">
                        {{ webSession.site }}
                    </ItemDetail>
                    <ItemDetail name="Вердикт">
                        {{ verdict }}
                    </ItemDetail>
                </WrappedContent>
                <ItemDetail name="User-Agent">
                    {{ webSession.userAgent }}
                </ItemDetail>
                <ItemDetail name="Referer">
                    <ExternalLink v-bind:url="webSession.referer" />
                </ItemDetail>
            </template>
        </RequestWrapper>

        <h2>Свойства</h2>
        <RequestWrapper
            v-bind:data="webSessionProperties"
            v-bind:errorText="webSessionPropertiesErrorText"
            v-bind:errorCode="webSessionPropertiesErrorCode">
            <template v-slot:default>
                <div v-if="webSessionProperties.length > 0">
                    <table class="web-session-properties">
                        <tbody>
                            <tr
                                v-for="webSessionProperty in webSessionProperties"
                                v-bind:key="webSessionProperty.webSessionPropertyId">
                                <td>{{ webSessionProperty.name }}</td>
                                <td>{{ webSessionProperty.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <CompareWidget v-bind:webSessionId="Number(webSessionId)" />
                </div>
                <div v-else>
                    <i>Записи о свойствах отсутствуют.</i>
                </div>
            </template>
        </RequestWrapper>

        <h2>Хронология</h2>
        <RequestWrapper
            v-bind:data="webSessionEvents"
            v-bind:errorText="webSessionEventsErrorText"
            v-bind:errorCode="webSessionEventsErrorCode">
            <template v-slot:default>
                <div v-if="webSessionEvents.length > 0">
                    <div v-for="arr in tabbedEvents" v-bind:key="arr[0]">
                        <h3>Вкладка #{{ arr[0] }}</h3>
                        <EventExpandable
                            v-for="webSessionEvent in arr[1]"
                            v-bind:key="webSessionEvent.webSessionEventId"
                            v-bind:event="webSessionEvent" />
                    </div>
                </div>
                <div v-else>
                    <i>Записи о событиях отсутствуют.</i>
                </div>
            </template>
        </RequestWrapper>

        <div v-if="webSession">
            <h2>Поиск</h2>
            <ul>
                <li>
                    <router-link v-bind:to="`/telemetry/web-sessions?mode=search&ip=${webSession.ipAddress}`">
                        Веб-сессии с этого IP-адреса
                    </router-link>
                </li>
                <li>
                    <router-link
                        v-bind:to="`/telemetry/web-sessions?mode=search&userTrackExternalId=${webSession.userTrackExternalId}`">
                        Веб-сессии с этим внешним ID пользователя
                    </router-link>
                </li>
                <li>
                    <router-link v-bind:to="`/telemetry/web-sessions?mode=search&site=${webSession.site}`">
                        Веб-сессии на этом сайте
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import DateTime from '../../../misc/DateTime.vue'
import IpAddressPopupMenu from '../../reputation/IpAddressPopupMenu.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import EventExpandable from './EventExpandable.vue'
import CompareWidget from './CompareWidget.vue'
import { getAuthorizedRequest } from '../../../../common.js'
import { getSessionVerdictString } from './verdict.js'

export default {
    name: 'WebSessionViewPage',

    components: {
        WrappedContent,
        ItemDetail,
        DateTime,
        IpAddressPopupMenu,
        ExternalLink,
        RequestWrapper,
        EventExpandable,
        CompareWidget,
    },

    data: function() {
        return {
            webSessionId: this.$route.params.id,
            webSession: undefined,
            webSessionErrorText: undefined,
            webSessionErrorCode: undefined,
            webSessionEvents: undefined,
            webSessionEventsErrorText: undefined,
            webSessionEventsErrorCode: undefined,
            webSessionProperties: undefined,
            webSessionPropertiesErrorText: undefined,
            webSessionPropertiesErrorCode: undefined,
        }
    },

    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/telemetry/web-sessions/${this.webSessionId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.webSession = data))
                        break
                    default:
                        this.webSessionErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.webSessionErrorText = error))

        getAuthorizedRequest(`/api/telemetry/web-sessions/${this.webSessionId}/events`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.webSessionEvents = data))
                        break
                    default:
                        this.webSessionEventsErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.webSessionEventsErrorText = error))

        getAuthorizedRequest(`/api/telemetry/web-sessions/${this.webSessionId}/properties`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.webSessionProperties = data))
                        break
                    default:
                        this.webSessionPropertiesErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.webSessionPropertiesErrorText = error))
    },

    computed: {
        tabbedEvents: function() {
            if (!this.webSessionEvents) return null
            var sortedEvents = this.webSessionEvents
            sortedEvents = sortedEvents.sort((e1, e2) => e1.tabId - e2.tabId)
            var tabIds = [...new Set(sortedEvents.map(e => e.tabId))]
            return tabIds.map(i => [i, sortedEvents.filter(e => e.tabId === i)])
        },
        verdict: function() {
            return getSessionVerdictString(this.webSession)
        },
    },
}
</script>

<style scoped>
.web-session-properties {
    margin-bottom: 20px;
    border-spacing: 0px;
    border-collapse: collapse;
}

.web-session-properties td {
    padding: 2px 5px;
}

.web-session-properties tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
</style>