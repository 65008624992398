<template>
    <div>
        <ItemsList>
            <div class="item" v-for="user in users" v-bind:key="user.userId">
                <div class="item-details">
                    <span class="item-title">
                        {{ user.login }}
                    </span>
                    <span class="user-status" v-if="user.status !== 'Normal'">{{
                        statusToString(user.status).toLowerCase()
                    }}</span>
                    <br />
                    Имя:
                    {{ ((user.firstName || '') + ' ' + (user.lastName || '')).trim() || '&lt; не установлено &gt;' }}
                    <br />
                    Роль: {{ roleToString(user.role) }}
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/administration/users/${user.userId}/view`">Подробнее</router-link>
                    <router-link v-bind:to="`/administration/users/${user.userId}/edit`">Редактировать</router-link>
                    <router-link
                        v-bind:to="`/administration/users/${user.userId}/delete`"
                        v-if="!isRootAdmin(user) && !isMe(user)"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import { userInfo, userStatusToString, userRoleToString } from '../../../../common.js'

export default {
    name: 'UsersList',
    components: {
        ItemsList,
    },
    props: {
        users: {
            type: Array,
            default: undefined,
        },
    },
    methods: {
        statusToString: function(status) {
            return userStatusToString(status)
        },
        roleToString: function(role) {
            return userRoleToString(role)
        },
        isRootAdmin: function(user) {
            return user.login.toLowerCase() == 'admin'
        },
        isMe: function(user) {
            return user.login.toLowerCase() == userInfo.profile.login.toLowerCase()
        },
    },
}
</script>

<style scoped>
.user-status {
    padding-left: 10px;
    color: #dd9;
}
</style>