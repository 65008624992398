<template>
    <div class="loader" v-bind:style="{ height: height }">
        <div class="loader-animation" />
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        height: {
            type: String,
            default: '120px',
        },
    },
}
</script>

<style scoped>
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loader-animation {
    width: 100%;
    height: 100%;
    max-height: 100px;
    background-image: url('../../assets/misc/loader.gif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 70%;
}
</style>
