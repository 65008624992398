<template>
    <div>
        <h1 id="title-source">
            Удаление
            <router-link v-bind:to="`/licensing/orders/${orderId}/view`"> ордера #{{ orderId }} </router-link>
        </h1>

        <RequestWrapper v-bind:data="order" v-bind:errorText="orderErrorText" v-bind:errorCode="orderErrorCode">
            <template v-slot:default>
                <ul>
                    <li>
                        <RequestInlineWrapper
                            loadingText="Поиск связанных лицензий..."
                            v-bind:data="linkedLicenses"
                            v-bind:errorText="linkedLicensesErrorText"
                            v-bind:errorCode="linkedLicensesErrorCode">
                            <template v-slot:default>
                                <span v-if="linkedLicenses.itemsCount > 0">
                                    Найдено лицензий, выписанных для этого ордера:
                                    {{ linkedLicenses.itemsCount }} (<router-link
                                        v-bind:to="`/licensing/licenses?mode=search&orderId=${orderId}`"
                                        target="_blank"
                                        >посмотреть</router-link
                                    >)
                                </span>
                                <span v-else> Не найдено лицензий, выписанных для этого ордера </span>
                            </template>
                        </RequestInlineWrapper>
                    </li>
                </ul>
                <Warning v-if="totalLinkedItems > 0">
                    При удалении ордера все перечисленные связанные с ним объекты будут также удалены.
                </Warning>
                <ButtonsBlock>
                    <Button
                        text="Подтверждаю удаление"
                        destructive
                        v-bind:failureText="deleteFailure"
                        v-on:click="performDelete" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import RequestInlineWrapper from '../../../misc/RequestInlineWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, deleteAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'OrderDeletePage',
    components: {
        Warning,
        Button,
        ButtonsBlock,
        RequestWrapper,
        RequestInlineWrapper,
    },
    data: function() {
        return {
            orderId: this.$route.params.id,
            order: undefined,
            orderErrorText: undefined,
            orderErrorCode: undefined,
            linkedLicenses: undefined,
            linkedLicensesErrorText: undefined,
            linkedLicensesErrorCode: undefined,
            deleteFailure: '',
        }
    },
    computed: {
        totalLinkedItems: function() {
            let total = 0
            if (this.linkedLicenses != undefined) total += this.linkedLicenses.itemsCount
            return total
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/orders/${this.orderId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.order = data))
                        break
                    default:
                        this.orderErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.orderErrorText = error))

        var searchRequest = { orderIdExact: Number(this.orderId) }

        postAuthorizedRequest('/api/licensing/licenses/search?pageSize=1', searchRequest)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.linkedLicenses = data))
                        break
                    default:
                        this.linkedLicensesErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.linkedLicensesErrorText = error))
    },
    methods: {
        performDelete: function(callback) {
            this.deleteFailure = ''

            deleteAuthorizedRequest(`/api/licensing/orders/${this.orderId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.$router.push('/licensing/orders')
                            break
                        default:
                            this.deleteFailure = `Плохой ответ на запрос (${res.status})`
                            callback()
                            break
                    }
                })
                .catch(() => (this.deleteFailure = 'Не удалось выполнить запрос'))
        },
    },
}
</script>