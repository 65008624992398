<template>
    <div class="items-list">
        <slot>
            <div class="no-items">Нет результатов</div>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'ItemsList',
}
</script>

<style>
.items-list {
    padding: 0px 20px;
    background-color: #111;
    border-radius: 6px;
}

.items-list > *:not(:last-child) {
    border-bottom: 1px solid #394757;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
}

.screen-s .item {
    flex-direction: column;
    align-items: flex-start;
}

.item:not(:last-child) {
    border-bottom: 1px solid #394757;
}

.item > * {
    margin-right: 30px;
}

.screen-s .item > * {
    margin-right: 0px;
}

.item > :last-child {
    margin-right: 0px;
}

.screen-s .item > :not(:last-child) {
    margin-bottom: 15px;
}

.item-date {
    width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
}

.screen-s .item-date {
    width: 100%;
    text-align: center;
}

.item-details {
    word-break: break-word;
    flex-grow: 1;
}

.item-title {
    font-weight: bold;
    color: #ccc;
}

.item-links > * {
    display: block;
}

.screen-s .item-links > a {
    display: inline-block;
    margin-right: 15px;
}

.no-items {
    padding: 10px;
    text-align: center;
}
</style>