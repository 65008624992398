<template>
    <div>
        <ItemsList>
            <div class="item" v-for="email in emails" v-bind:key="email.emailId">
                <div class="item-date">
                    <DateTime v-bind:dt="email.added" />
                </div>
                <div class="item-details">
                    <span class="item-title">{{ email.subject }}</span>
                    <br />
                    От: {{ email.sender.address }}
                    <br />
                    Кому:
                    <span v-for="address in email.recipients" v-bind:key="address.address" class="email-recipient">{{
                        address.address
                    }}</span>
                    <br />
                    Статус:
                    <span v-bind:class="`email-status-${email.status.toLowerCase()}`">{{
                        statusToString(email.status)
                    }}</span>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/notification/emails/${email.emailId}/view`">Подробнее</router-link>
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'

export default {
    name: 'EmailsList',
    components: {
        ItemsList,
        DateTime,
    },
    props: {
        emails: {
            type: Array,
            default: undefined,
        },
    },
    methods: {
        statusToString: function(status) {
            switch (status) {
                case 'New':
                    return 'ожидает отправки'
                case 'Sending':
                    return 'отправляется'
                case 'Sent':
                    return 'отправлено'
                case 'Failed':
                    return 'не удалось отправить'
                default:
                    return status.toLowerCase()
            }
        },
    },
}
</script>

<style scoped>
.email-recipient {
    margin-right: 10px;
}

.email-status-new {
    color: #ddd;
}

.email-status-sending {
    color: #dd9;
}

.email-status-sent {
    color: #2a6;
}

.email-status-failed {
    color: #d99;
}
</style>