<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание продукта</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/products/${productId}/view`">продукта #{{ productId }}</router-link>
        </h1>

        <RequestWrapper v-bind:data="product" v-bind:errorText="productErrorText" v-bind:errorCode="productErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="product-name"
                            labelText="Название:"
                            v-bind:errorText="nameValidationError"
                            required
                            v-model="product.name" />
                        <SearchField
                            id="product-domain"
                            labelText="Домен:"
                            required
                            v-bind:errorText="productDomainValidationError"
                            textKey="name"
                            valueKey="productDomainId"
                            v-bind:getInfoUrl="`/api/licensing/product-domains/${product.productDomainId}`"
                            searchUrl="/api/licensing/product-domains/search"
                            searchKey="namePartial"
                            v-model="product.productDomainId" />
                        <TextField
                            id="product-download-link"
                            labelText="Ссылка на скачивание:"
                            v-bind:errorText="downloadLinkValidationError"
                            required
                            v-model="product.downloadLink" />
                        <TextField
                            id="product-support-email"
                            labelText="E-mail тех. поддержки:"
                            v-bind:errorText="supportEmailValidationError"
                            required
                            v-model="product.supportEmail" />
                        <TextField
                            id="product-site"
                            labelText="Сайт:"
                            v-bind:errorText="siteValidationError"
                            placeholderText="Использовать из домена продукта"
                            v-model="product.site" />
                        <TextField
                            id="product-support-link"
                            labelText="Страница тех. поддержки:"
                            v-bind:errorText="supportLinkValidationError"
                            placeholderText="Использовать из домена продукта"
                            v-model="product.supportLink" />
                        <TextField
                            id="product-registry-key"
                            labelText="Ключ в реестре:"
                            v-bind:errorText="registryKeyValidationError"
                            required
                            v-model="product.registryKey" />
                        <SelectField
                            id="product-protection-provider"
                            labelText="Провайдер защиты:"
                            v-bind:options="protectionProviderOptions"
                            v-model="product.protectionProvider" />
                    </WrappedContent>

                    <h2>Параметры защиты</h2>
                    <div v-show="product.protectionProvider == 'ASProtect'">
                        <h3>ASProtect</h3>
                        <TextField
                            id="asprotect-keygen-a"
                            labelText="KeygenA:"
                            v-bind:errorText="asProtectAValidationError"
                            required
                            v-model="product.asProtectParameters.keygenA" />
                        <TextField
                            id="asprotect-keygen-e"
                            labelText="KeygenE:"
                            v-bind:errorText="asProtectEValidationError"
                            required
                            v-model="product.asProtectParameters.keygenE" />
                        <TextField
                            id="asprotect-keygen-n"
                            labelText="KeygenN:"
                            v-bind:errorText="asProtectNValidationError"
                            required
                            v-model="product.asProtectParameters.keygenN" />
                    </div>
                    <div v-show="product.protectionProvider == 'VMProtect'">
                        <h3>VMProtect</h3>
                        <TextField
                            id="vmprotect-data"
                            labelText="Data:"
                            v-bind:errorText="vmprotectDataValidationError"
                            required
                            v-model="product.vmProtectParameters.data" />
                    </div>

                    <h3>Другие параметры</h3>
                    <WrappedContent>
                        <TextField
                            id="sublicense-password"
                            maxlength="256"
                            labelText="Пароль шифрования SubLicense:"
                            placeholderText="Использовать пароль по умолчанию"
                            v-model="product.subLicensePassword" />
                        <NumberField
                            id="license-expiration-period-override"
                            v-bind:min="0"
                            v-bind:max="9999"
                            v-bind:required="isSubLicenseSet"
                            labelText="Переопределение срока первой лицензии (дней):"
                            placeholderText="Не переопределён"
                            v-model="product.licenseExpirationPeriodDaysOverride" />
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/products/${productId}/delete`)" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SearchField from '../../../inputs/SearchField.vue'
import SelectField from '../../../inputs/SelectField.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'
import { validateEmail, validateUrl } from '../../../../common.js'

export default {
    name: 'ProductEditPage',
    components: {
        WrappedContent,
        SearchField,
        SelectField,
        TextField,
        NumberField,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            protectionProviderOptions: [
                { value: 'None', text: 'Отсутствует' },
                { value: 'ASProtect', text: 'ASProtect' },
                { value: 'VMProtect', text: 'VMProtect' },
            ],
            productId: this.$route.params.id,
            productDomainValidationError: '',
            nameValidationError: '',
            downloadLinkValidationError: '',
            siteValidationError: '',
            supportLinkValidationError: '',
            supportEmailValidationError: '',
            registryKeyValidationError: '',
            asProtectAValidationError: '',
            asProtectEValidationError: '',
            asProtectNValidationError: '',
            vmprotectDataValidationError: '',
            product: undefined,
            productErrorText: undefined,
            productErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.productId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.product = {
                productDomainId: '',
                name: '',
                downloadLink: '',
                site: '',
                supportLink: '',
                supportEmail: '',
                registryKey: '',
                protectionProvider: 'ASProtect',
                asProtectParameters: {
                    keygenA: undefined,
                    keygenE: undefined,
                    keygenN: undefined,
                },
                vmProtectParameters: {
                    data: undefined,
                },
                subLicensePassword: undefined,
                licenseExpirationPeriodDaysOverride: undefined,
            }
        } else {
            getAuthorizedRequest(`/api/licensing/products/${this.productId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.product = {
                                    productDomainId: data.productDomain.productDomainId,
                                    name: data.name,
                                    downloadLink: data.downloadLink,
                                    site: data.site,
                                    supportLink: data.supportLink,
                                    supportEmail: data.supportEmail,
                                    registryKey: data.registryKey,
                                    protectionProvider: data.protectionProvider,
                                    asProtectParameters: data.asProtectParameters || {
                                        keygenA: undefined,
                                        keygenE: undefined,
                                        keygenN: undefined,
                                    },
                                    vmProtectParameters: data.vmProtectParameters || {
                                        data: undefined,
                                    },
                                    subLicensePassword: data.subLicensePassword,
                                    licenseExpirationPeriodDaysOverride: data.licenseExpirationPeriodDaysOverride,
                                }
                            })
                            break
                        default:
                            this.productErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.productErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.product.name = this.product.name.trim()
            this.product.downloadLink = this.product.downloadLink.trim()
            this.product.site = (this.product.site && this.product.site.trim()) || undefined
            this.product.supportLink = (this.product.supportLink && this.product.supportLink.trim()) || undefined
            this.product.supportEmail = this.product.supportEmail.trim()
            this.product.registryKey = this.product.registryKey.trim()
            if (!this.product.licenseExpirationPeriodDaysOverride)
                this.product.licenseExpirationPeriodDaysOverride = undefined

            var validationOk = true

            if (!this.product.productDomainId) {
                this.productDomainValidationError = 'Укажите домен продукта'
                validationOk = false
            }

            if (!this.product.name) {
                this.nameValidationError = 'Введите название'
                validationOk = false
            } else {
                this.nameValidationError = ''
            }

            this.downloadLinkValidationError = validateUrl(this.product.downloadLink)
            if (this.downloadLinkValidationError) {
                validationOk = false
            }

            if (this.product.site) {
                this.siteValidationError = validateUrl(this.product.site)
                if (this.siteValidationError) {
                    validationOk = false
                }
            }

            if (this.product.supportLink) {
                this.supportLinkValidationError = validateUrl(this.product.supportLink)
                if (this.supportLinkValidationError) {
                    validationOk = false
                }
            }

            this.supportEmailValidationError = validateEmail(this.product.supportEmail)
            if (this.supportEmailValidationError) {
                validationOk = false
            }

            if (!this.product.registryKey) {
                this.registryKeyValidationError = 'Введите ключ реестра'
                validationOk = false
            } else {
                this.registryKeyValidationError = ''
            }

            switch (this.product.protectionProvider) {
                case 'ASProtect':
                    this.product.asProtectParameters.keygenA = this.cleanupBase64(
                        this.product.asProtectParameters.keygenA
                    )
                    this.product.asProtectParameters.keygenE = this.cleanupBase64(
                        this.product.asProtectParameters.keygenE
                    )
                    this.product.asProtectParameters.keygenN = this.cleanupBase64(
                        this.product.asProtectParameters.keygenN
                    )

                    if (!this.product.asProtectParameters.keygenA) {
                        this.asProtectAValidationError = 'Введите параметр A'
                        validationOk = false
                    } else {
                        if (!this.isValidBase64(this.product.asProtectParameters.keygenA)) {
                            this.asProtectAValidationError = 'Ошибка декодирования Base64'
                            validationOk = false
                        } else {
                            this.asProtectAValidationError = ''
                        }
                    }

                    if (!this.product.asProtectParameters.keygenE) {
                        this.asProtectEValidationError = 'Введите параметр E'
                        validationOk = false
                    } else {
                        if (!this.isValidBase64(this.product.asProtectParameters.keygenE)) {
                            this.asProtectEValidationError = 'Ошибка декодирования Base64'
                            validationOk = false
                        } else {
                            this.asProtectEValidationError = ''
                        }
                    }

                    if (!this.product.asProtectParameters.keygenN) {
                        this.asProtectNValidationError = 'Введите параметр N'
                        validationOk = false
                    } else {
                        if (!this.isValidBase64(this.product.asProtectParameters.keygenN)) {
                            this.asProtectNValidationError = 'Ошибка декодирования Base64'
                            validationOk = false
                        } else {
                            this.asProtectNValidationError = ''
                        }
                    }

                    break
                case 'VMProtect':
                    this.product.vmProtectParameters.data = this.cleanupBase64(this.product.vmProtectParameters.data)

                    if (!this.product.vmProtectParameters.data) {
                        this.vmprotectDataValidationError = 'Введите параметр data'
                        validationOk = false
                    } else {
                        if (!this.isValidBase64(this.product.vmProtectParameters.data)) {
                            this.vmprotectDataValidationError = 'Ошибка декодирования Base64'
                            validationOk = false
                        } else {
                            this.vmprotectDataValidationError = ''
                        }
                    }

                    break
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/products', this.product)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data => this.$router.push(`/licensing/products/${data.productId}/view`))
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/products/${this.productId}`, this.product)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные продукта сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },

        cleanupBase64: function(s) {
            if (!s) return s
            return s
                .replaceAll(' ', '')
                .replaceAll('\r', '')
                .replaceAll('\n', '')
                .trim()
        },

        isValidBase64: function(s) {
            try {
                return btoa(atob(s)) == s
            } catch {
                return false
            }
        },
    },
}
</script>