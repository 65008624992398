<template>
    <div class="request-wrapper">
        <div v-if="errorText != undefined" v-bind:style="{ height: height }" class="request-wrapper-error">
            <div>{{ errorTextTitle }}</div>
            <div>{{ errorText }}</div>
        </div>
        <div v-else-if="errorCode != undefined" v-bind:style="{ height: height }" v-bind:class="errorCodeStyle">
            <div>{{ errorCodeTitle }}</div>
            <div>Код: {{ errorCode }}</div>
        </div>
        <Loader v-else-if="data == undefined" v-bind:height="height" />
        <slot v-else />
    </div>
</template>

<script>
import Loader from './Loader.vue'

export default {
    name: 'RequestWrapper',
    components: {
        Loader,
    },
    props: {
        height: {
            type: String,
            default: '120px',
        },
        data: {
            default: undefined,
        },
        errorTextTitle: {
            type: String,
            default: 'Не удалось выполнить запрос',
        },
        errorCodeTitle: {
            type: String,
            default: 'Запрос завершился ошибкой',
        },
        errorText: {
            type: String,
            default: undefined,
        },
        errorCode: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        errorCodeStyle: function() {
            var result = { 'request-wrapper-error': true }
            result[`request-wrapper-error-${this.errorCode.toString().substr(0, 1)}xx`] = true
            return result
        },
    },
}
</script>

<style scoped>
.request-wrapper-error {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.1em;
    border-radius: 5px;
    background-image: repeating-linear-gradient(-45deg, #000, #000 9px, #420 10px, #420 19px, #000 20px);
    color: #bbb;
}

.request-wrapper-error-4xx {
    background-image: repeating-linear-gradient(-45deg, #000, #000 9px, #440 10px, #440 19px, #000 20px);
}

.request-wrapper-error-5xx {
    background-image: repeating-linear-gradient(-45deg, #000, #000 9px, #400 10px, #400 19px, #000 20px);
}

.request-wrapper-error div {
    background-color: #111;
    padding: 4px 8px;
    border-radius: 5px;
}
</style>
