<template>
    <router-link v-if="link" v-bind:to="link">
        <div class="subsection-container subsection-link">
            <div class="subsection-title">
                {{ title }}
            </div>
            <div class="subsection-description">
                <slot />
            </div>
        </div>
    </router-link>
    <div v-else>
        <div class="subsection-container">
            <div class="subsection-title">
                {{ title }}
            </div>
            <div class="subsection-description">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubSection',
    props: {
        link: String,
        title: String,
    },
}
</script>

<style scoped>
.subsection-container {
    margin-bottom: 5px;
    padding: 10px 20px;
    background-color: #111;
    border-radius: 5px;
}

.subsection-container.subsection-link:hover {
    background-color: #12171a;
}

.subsection-title {
    margin-bottom: 10px;
    color: #ddd;
    font-size: 1.2em;
}

.subsection-description {
    color: #888;
}
</style>
