<template>
    <div class="expandable" v-bind:class="{ expanded: isExpanded }">
        <div class="expandable-summary" v-on:click="isExpanded = !isExpanded">
            <div class="expanded-marker">
                <div class="expanded-marker-icon" />
            </div>
            <div class="summary">
                <div class="summary-container">
                    {{ summaryText }}
                </div>
            </div>
        </div>
        <div class="expandable-details" v-show="isExpanded">
            <div class="expandable-details-space" />
            <div class="expandable-details-content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExpandableContent',
    props: {
        summaryText: String,
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            isExpanded: this.expanded,
        }
    },
}
</script>

<style scoped>
.expandable {
    margin: 10px 0px;
    padding: 5px;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #181818;
}

.expandable-summary {
    display: flex;
    padding: 5px 5px 5px 0px;
    cursor: pointer;
    color: #718dab;
    line-height: 1;
}

.expanded-marker {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}

.expanded-marker-icon {
    width: 16px;
    height: 16px;
    background-image: url('../../assets/misc/expandable-arrow.svg');
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(0.75turn);
}

.expanded .expanded-marker-icon {
    transform: none;
}

.expandable-summary:hover {
    color: #99bade;
}

.summary {
    flex-grow: 1;
}

.summary-container {
    max-width: 100%;
}

.expandable-details {
    display: flex;
}

.expandable-details-space {
    flex-shrink: 0;
    width: 30px;
}

.expandable-details-content {
    flex-grow: 1;
    margin-top: 10px;
    margin-right: 30px;
    word-break: break-word;
}
</style>
