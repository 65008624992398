<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание пользователя</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/administration/users/${userId}/view`">пользователя #{{ userId }}</router-link>
        </h1>
        <RequestWrapper v-bind:data="user" v-bind:errorText="userErrorText" v-bind:errorCode="userErrorCode">
            <template v-slot:default>
                <Warning v-if="isRootAdmin">
                    Это корневой администратор системы, его базовые параметры настраиваются через конфигурационные файлы
                    приложения.
                </Warning>
                <form>
                    <WrappedContent>
                        <SelectField
                            id="status"
                            labelText="Статус:"
                            required
                            v-bind:enabled="!isRootAdmin && !isMe"
                            v-bind:options="statusOptions"
                            v-model="user.status" />
                        <SelectField
                            id="role"
                            labelText="Роль:"
                            required
                            v-bind:enabled="!isRootAdmin && !isMe"
                            v-bind:options="roleOptions"
                            v-model="user.role" />
                        <TextField
                            id="login"
                            labelText="Логин:"
                            required
                            v-bind:enabled="!isRootAdmin"
                            v-bind:errorText="loginValidationError"
                            v-model="user.login" />
                        <TextField
                            id="email"
                            labelText="Электронная почта:"
                            required
                            v-bind:errorText="emailValidationError"
                            v-model="user.email" />
                        <TextField id="firstName" labelText="Имя:" v-model="user.firstName" />
                        <TextField id="lastName" labelText="Фамилия:" v-model="user.lastName" />
                        <div v-if="isCreateMode">
                            <TextField
                                id="password"
                                labelText="Пароль:"
                                required
                                isPassword
                                v-bind:errorText="passwordValidationError"
                                v-model="user.password" />
                            <PasswordRequirements />
                        </div>
                    </WrappedContent>
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-bind:enabled="!isRootAdmin && !isMe"
                            v-on:click="$router.push(`/administration/users/${userId}/delete`)" />
                    </ButtonsBlock>
                </form>
                <div v-if="!isCreateMode">
                    <h2>Изменить пароль</h2>
                    <form>
                        <WrappedContent>
                            <TextField
                                id="password"
                                labelText="Пароль:"
                                required
                                v-bind:enabled="!isRootAdmin"
                                isPassword
                                v-bind:errorText="passwordValidationError"
                                v-model="passwordPatchRequest.password" />
                        </WrappedContent>
                        <PasswordRequirements />
                        <ButtonsBlock>
                            <Button
                                text="Сохранить"
                                v-bind:enabled="!isRootAdmin"
                                v-on:click="performPasswordPatch"
                                v-bind:successText="passwordPatchRequestSuccess"
                                v-bind:failureText="passwordPatchRequestFailure" />
                        </ButtonsBlock>
                    </form>
                    <h2>Активные сессии</h2>
                    <RequestWrapper
                        v-bind:data="sessions"
                        v-bind:errorText="sessionsErrorText"
                        v-bind:errorCode="sessionsErrorCode">
                        <template v-slot:default>
                            <div v-if="sessions.length > 0">
                                <WrappedContent>
                                    <Session
                                        v-for="session in sessions"
                                        v-bind:key="session.sessionId"
                                        v-bind:session="session"
                                        v-bind:deleteFailureText="session.deleteFailure"
                                        v-on:deleteClick="(callback) => deleteSession(session, callback)" />
                                </WrappedContent>
                                <ButtonsBlock>
                                    <Button
                                        text="Прервать все сессии"
                                        destructive
                                        v-on:click="performDeleteAllSessions"
                                        v-bind:failureText="deleteAllSessionsRequestFailure" />
                                </ButtonsBlock>
                            </div>
                            <div v-else>Нет активных сессий</div>
                        </template>
                    </RequestWrapper>
                    <h2>Токены установки пароля</h2>
                    <RequestWrapper
                        v-bind:data="passwordSetTokens"
                        v-bind:errorText="passwordSetTokensErrorText"
                        v-bind:errorCode="passwordSetTokensErrorCode">
                        <template v-slot:default>
                            <div v-if="passwordSetTokens.length > 0">
                                <WrappedContent>
                                    <PasswordSetToken
                                        v-for="token in passwordSetTokens"
                                        v-bind:key="token.passwordSetTokenId"
                                        v-bind:token="token" />
                                </WrappedContent>
                                <ButtonsBlock>
                                    <Button
                                        text="Удалить все токены"
                                        destructive
                                        v-on:click="performDeleteAllPasswordSetTokens"
                                        v-bind:failureText="deleteAllPasswordSetTokensRequestFailure" />
                                </ButtonsBlock>
                            </div>
                            <div v-else>Нет токенов установки пароля</div>
                        </template>
                    </RequestWrapper>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SelectField from '../../../inputs/SelectField.vue'
import TextField from '../../../inputs/TextField.vue'
import PasswordRequirements from '../../../misc/PasswordRequirements.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import Session from './Session.vue'
import PasswordSetToken from './PasswordSetToken.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import {
    getAuthorizedRequest,
    postAuthorizedRequest,
    patchAuthorizedRequest,
    deleteAuthorizedRequest,
} from '../../../../common.js'
import {
    userInfo,
    userStatusToString,
    userRoleToString,
    validateLogin,
    validateEmail,
    validatePassword,
} from '../../../../common.js'

export default {
    name: 'UserEditPage',
    components: {
        Warning,
        WrappedContent,
        SelectField,
        TextField,
        PasswordRequirements,
        Button,
        ButtonsBlock,
        Session,
        PasswordSetToken,
        RequestWrapper,
    },
    data: function() {
        return {
            statusOptions: [
                { value: 'Normal', text: userStatusToString('Normal') },
                { value: 'Disabled', text: userStatusToString('Disabled') },
            ],
            roleOptions: [
                { value: 'Admin', text: userRoleToString('Admin') },
                { value: 'Support', text: userRoleToString('Support') },
                { value: 'Viewer', text: userRoleToString('Viewer') },
            ],
            userId: this.$route.params.id,
            loginValidationError: '',
            emailValidationError: '',
            passwordValidationError: '',
            user: undefined,
            userErrorText: undefined,
            userErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
            passwordPatchRequest: undefined,
            passwordPatchRequestSuccess: '',
            passwordPatchRequestFailure: '',
            sessions: undefined,
            sessionsErrorText: undefined,
            sessionsErrorCode: undefined,
            deleteAllSessionsRequestFailure: '',
            passwordSetTokens: undefined,
            passwordSetTokensErrorText: undefined,
            passwordSetTokensErrorCode: undefined,
            deleteAllPasswordSetTokensRequestFailure: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.userId == undefined
        },
        isRootAdmin: function() {
            return this.user.login.toLowerCase() == 'admin'
        },
        isMe: function() {
            return this.user.login == userInfo.profile.login
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.user = {
                status: 'Normal',
                role: 'Viewer',
                login: '',
                email: '',
                firstName: '',
                lastName: '',
                password: this.getRandomPassword(),
            }
        } else {
            getAuthorizedRequest(`/api/administration/users/${this.userId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.user = {
                                    status: data.status,
                                    role: data.role,
                                    login: data.login,
                                    email: data.email || '',
                                    firstName: data.firstName || '',
                                    lastName: data.lastName || '',
                                }
                                this.passwordPatchRequest = {
                                    password: '',
                                }
                            })
                            break
                        default:
                            this.userErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.userErrorText = error))

            this.loadSessions()
            this.loadPasswordSetTokens()
        }
    },
    methods: {
        getRandomPassword: function() {
            var charsets = [
                'abcdefghijklmnopqrstuvwxyz',
                'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                '0123456789',
                "!@#$%^&*();':\\|/",
            ]

            var result = ''
            for (var i = 0; i < charsets.length; i++) {
                var charset = charsets[i]
                for (var j = 0, n = charset.length; j < 4; ++j) {
                    result += charset.charAt(Math.floor(Math.random() * n))
                }
            }
            return result
        },

        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.user.login = this.user.login.trim()
            this.user.email = this.user.email.trim()
            this.user.firstName = this.user.firstName.trim()
            this.user.lastName = this.user.lastName.trim()

            var validationOk = true

            this.loginValidationError = validateLogin(this.user.login)
            if (this.loginValidationError) {
                validationOk = false
            }

            this.emailValidationError = validateEmail(this.user.email)
            if (this.emailValidationError) {
                validationOk = false
            }

            if (this.isCreateMode) {
                this.passwordValidationError = validatePassword(this.user.password)
                if (this.passwordValidationError) {
                    validationOk = false
                }
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/administration/users', this.user)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data => this.$router.push(`/administration/users/${data.userId}/view`))
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => {
                        this.saveRequestFailure = 'Не удалось выполнить запрос'
                    })
                    .then(callback)
            } else {
                patchAuthorizedRequest(`/api/administration/users/${this.userId}`, this.user)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные пользователя сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => {
                        this.saveRequestFailure = 'Не удалось выполнить запрос'
                    })
                    .then(callback)
            }
        },

        performPasswordPatch: function(callback) {
            var validationOk = true
            this.passwordPatchRequest.password = this.passwordPatchRequest.password.trim()

            this.passwordValidationError = validatePassword(this.passwordPatchRequest.password)
            if (this.passwordValidationError) {
                validationOk = false
            }

            if (!validationOk) {
                callback()
                return
            }

            this.passwordPatchRequestSuccess = ''
            this.passwordPatchRequestFailure = ''

            patchAuthorizedRequest(`/api/administration/users/${this.userId}`, this.passwordPatchRequest)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.passwordPatchRequestSuccess = 'Пароль пользователя изменён'
                            this.passwordPatchRequest.password = ''
                            this.loadSessions()
                            this.loadPasswordSetTokens()
                            break
                        default:
                            this.passwordPatchRequestFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => {
                    this.passwordPatchRequestFailure = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },

        loadSessions: function() {
            this.sessions = undefined
            getAuthorizedRequest(`/api/administration/users/${this.userId}/sessions`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                data.forEach(session => {
                                    session.deleteFailure = ''
                                })
                                this.sessions = data
                            })
                            break
                        default:
                            this.sessionsErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.sessionsErrorText = error))
        },

        deleteSession: function(session, callback) {
            deleteAuthorizedRequest(`/api/administration/users/${this.userId}/sessions/${session.sessionId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.loadSessions()
                            break
                        default:
                            session.deleteFailure = `Плохой ответ на запрос (${res.status})`
                    }
                })
                .catch(() => {
                    session.deleteFailure = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },

        performDeleteAllSessions: function(callback) {
            deleteAuthorizedRequest(`/api/administration/users/${this.userId}/sessions`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.loadSessions()
                            break
                        default:
                            this.deleteAllSessionsRequestFailure = `Плохой ответ на запрос (${res.status})`
                    }
                })
                .catch(() => {
                    this.deleteAllSessionsRequestFailure = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },

        loadPasswordSetTokens: function() {
            this.passwordSetTokens = undefined
            getAuthorizedRequest(`/api/administration/users/${this.userId}/password-set-tokens`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.passwordSetTokens = data
                            })
                            break
                        default:
                            this.passwordSetTokensErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.passwordSetTokensErrorText = error))
        },

        performDeleteAllPasswordSetTokens: function(callback) {
            deleteAuthorizedRequest(`/api/administration/users/${this.userId}/password-set-tokens`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.loadPasswordSetTokens()
                            break
                        default:
                            this.deleteAllSessionsRequestFailure = `Плохой ответ на запрос (${res.status})`
                    }
                })
                .catch(() => {
                    this.deleteAllSessionsRequestFailure = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },
    },
}
</script>