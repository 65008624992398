<template>
    <div>
        <h1 id="title-source">Детали парсинга продуктов #{{ productParsingId }}</h1>

        <RequestWrapper
            v-bind:data="productParsing"
            v-bind:errorText="productParsingErrorText"
            v-bind:errorCode="productParsingErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Название">
                        {{ productParsing.name }}
                    </ItemDetail>
                    <ItemDetail name="Приоритет">
                        {{ productParsing.priority }}
                    </ItemDetail>
                    <ItemDetail name="Регистратор">
                        {{
                            (productParsing.registrator && productParsing.registrator.registratorId) ||
                            '&lt; Любой &gt;'
                        }}
                    </ItemDetail>
                </WrappedContent>
                <ItemDetail name="Паттерн названия продукта у регистратора">
                    {{ productParsing.registratorProductNameRegex }}
                </ItemDetail>
                <ItemDetail name="Выражение названия продукта">
                    {{ productParsing.productNameExpression }}
                </ItemDetail>
                <ItemDetail name="Выражение названия типа лицензии">
                    {{ productParsing.licenseTypeNameExpression }}
                </ItemDetail>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Редактировать"
                        v-on:click="$router.push(`/licensing/product-parsings/${productParsingId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/product-parsings/${productParsingId}/delete`)" />
                </ButtonsBlock>

                <div class="access-level-admin">
                    <h2>Поиск</h2>
                    <ul>
                        <li>
                            <router-link
                                v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/product-parsings/${productParsingId}`">
                                Записи аудита на этот парсинг продуктов
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductParsingViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productParsingId: this.$route.params.id,
            productParsing: undefined,
            productParsingErrorText: undefined,
            productParsingErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/product-parsings/${this.productParsingId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.productParsing = data))
                        break
                    default:
                        this.productParsingErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productParsingErrorText = error))
    },
}
</script>