<template>
    <div id="header">
        <router-link id="header-logo" to="/" />
        <img
            id="header-menu"
            alt="menu"
            src="../../assets/layout/header/menu.svg"
            v-on:click="$emit('headerMenuClick')" />
        <div id="header-me-container" v-if="userInfo.profile != undefined">
            <router-link to="/me">
                <div id="header-me-icon" />
                <div id="header-me-link">{{ profileLinkText }}</div>
            </router-link>
            <Button text="Выйти" icon="layout/header/logout.svg" v-on:click="performSignout" />
        </div>
    </div>
</template>

<script>
import Button from '../inputs/Button.vue'
import { userInfo, signout } from '../../common.js'

export default {
    name: 'Header',
    components: {
        Button,
    },
    data: function() {
        return {
            userInfo: userInfo,
        }
    },
    computed: {
        profileLinkText: function() {
            return (
                `${this.userInfo.profile.firstName || ''} ${this.userInfo.profile.lastName || ''}`.trim() ||
                this.userInfo.profile.login
            )
        },
    },
    methods: {
        performSignout: function() {
            signout(this.$router)
        },
    },
}
</script>

<style scoped>
#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 80px;
    background-color: #222;
    z-index: 100;
}

.screen-m #header,
.screen-s #header {
    position: fixed;
    margin-bottom: 0px;
    border-bottom: 10px solid #111;
    top: 0;
    left: 0;
    width: 100%;
}

.screen-s #header {
    padding: 10px;
}

#header-logo {
    width: 250px;
    height: 100%;
    background-image: url('../../assets/layout/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.screen-m #header-logo,
.screen-s #header-logo {
    width: 200px;
}

#header-menu {
    height: 90%;
    cursor: pointer;
    z-index: 200;
}

.screen-l #header-menu {
    display: none;
}

#header-me-container {
    display: flex;
}

.screen-m #header-me-container,
.screen-s #header-me-container {
    display: none;
}

#header-me-container > a {
    display: flex;
    align-items: center;
}

#header-me-icon {
    display: inline-block;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background-image: url('../../assets/layout/header/user.svg');
}

#header-me-link {
    margin-right: 30px;
}
</style>