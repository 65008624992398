<template>
    <div>
        <Loader v-if="token == undefined && errorText == undefined" />
        <div v-else-if="errorText != undefined">
            <h2 id="title-source">Oops!</h2>
            <div class="error">{{ errorText }}</div>
        </div>
        <div v-else id="set-password-form-container">
            <div v-if="mode == 'invitation'">
                <h3>Добро пожаловать в BackOffice!</h3>
                <p>
                    Для начала работы задайте пароль вашей учётной записи <b>{{ token.userLogin }}</b
                    >:
                </p>
            </div>
            <div v-if="mode == 'recovery'">
                <h3 id="title-source">Восстановление доступа</h3>
                <p>
                    Введите новый пароль вашей учётной записи <b>{{ token.userLogin }}</b
                    >:
                </p>
            </div>
            <form>
                <TextField
                    id="password"
                    v-bind:errorText="passwordValidationError"
                    isPassword
                    v-model="savePasswordRequest.password" />
                <PasswordRequirements />
                <ButtonsBlock>
                    <Button text="Сохранить" v-on:click="performSave" v-bind:failureText="saveFailure" />
                </ButtonsBlock>
            </form>
        </div>
        <div>
            <router-link to="/signin">Перейти на главную страницу</router-link>
        </div>
    </div>
</template>

<script>
import NonAuthorized from '../../layout/NonAuthorized.vue'
import TextField from '../../inputs/TextField.vue'
import PasswordRequirements from '../../misc/PasswordRequirements.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import Loader from '../../misc/Loader.vue'
import { getRequest, postRequest, validatePassword } from '../../../common.js'

export default {
    name: 'SetPasswordPage',
    components: {
        TextField,
        PasswordRequirements,
        Button,
        ButtonsBlock,
        Loader,
    },
    props: {
        mode: {
            type: String,
            default: 'invitation',
        },
    },
    data: function() {
        return {
            tokenId: this.$route.params.tokenId,
            token: undefined,
            errorText: undefined,
            savePasswordRequest: {
                passwordSetTokenId: undefined,
                password: '',
            },
            passwordValidationError: '',
            saveFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', NonAuthorized)

        getRequest('/api/password-set/tokens/' + this.tokenId)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => {
                            this.savePasswordRequest.passwordSetTokenId = data.passwordSetTokenId
                            this.token = data
                        })
                        break
                    case 404:
                        this.errorText =
                            'Запрос на установку пароля не найден. Возможно, он истёк или уже был использован.'
                        break
                    default:
                        this.errorText = `Плохой ответ на запрос (${res.status})`
                }
            })
            .catch(() => {
                this.errorText = 'Не удалось выполнить запрос'
            })
    },
    methods: {
        performSave: function(callback) {
            var validationOk = true
            this.savePasswordRequest.password = this.savePasswordRequest.password.trim()

            var passwordValidationError = validatePassword(this.savePasswordRequest.password)
            if (passwordValidationError) {
                this.passwordValidationError = passwordValidationError
                validationOk = false
            } else {
                this.passwordValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            postRequest('/api/password-set', this.savePasswordRequest)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.$router.push({ path: '/signin', query: { reason: 'password-set' } })
                            break
                        default:
                            this.saveFailure = `Плохой ответ на запрос (${res.status})`
                    }
                })
                .catch(() => {
                    this.saveFailure = 'Не удалось выполнить запрос на сохранение пароля'
                })
                .then(callback)
        },
    },
}
</script>

<style scoped>
.error {
    margin-bottom: 10px;
}
</style>