<template>
    <div>
        <h1 id="title-source">Проверка определения продуктов</h1>

        <Note>
            Введите данные продукта у регистратора, чтобы проверить, что на него корректно определяются внутренние
            записи
            <router-link to="/licensing/products">продукта</router-link> и
            <router-link to="/licensing/license-types">типа лицензии</router-link>. Аналогичная процедура выполняется
            при приёме IPN от регистратора, чтобы создать <router-link to="/licensing/orders">ордер</router-link> на его
            основе.
        </Note>

        <form>
            <WrappedContent>
                <SearchField
                    id="tool-product-id-registrator"
                    labelText="Регистратор:"
                    required
                    v-bind:errorText="registratorValidationError"
                    textKey="name"
                    valueKey="registratorId"
                    v-bind:getInfoUrl="`/api/licensing/registrators/${request.registratorId}`"
                    searchUrl="/api/licensing/registrators/search"
                    searchKey="namePartial"
                    v-model="request.registratorId" />
                <TextField
                    id="tool-product-id-registrator-product-name"
                    labelText="Название продукта у регистратора:"
                    required
                    v-bind:errorText="registratorProductNameValidationError"
                    v-model="request.registratorProductName" />
                <TextField
                    id="tool-product-id-registrator-product-id"
                    labelText="Идентификатор продукта у регистратора:"
                    required
                    v-bind:errorText="registratorProductIdValidationError"
                    v-model="request.registratorProductId" />
            </WrappedContent>
            <ButtonsBlock>
                <Button text="Проверить" v-on:click="performRequest" v-bind:failureText="requestFailure" />
            </ButtonsBlock>

            <h2>Результат</h2>
            <div v-if="result">
                <h3>Наличие маппинга для идентификатора</h3>
                <div v-if="result.mapping">
                    Маппинг:
                    <router-link v-bind:to="`/licensing/product-mappings/${result.mapping.productMappingId}/view`">
                        {{ result.mapping.registrator.name }} {{ result.mapping.registratorProductId }}
                    </router-link>
                    <br />
                    Продукт:
                    <router-link v-bind:to="`/licensing/products/${result.mapping.product.productId}/view`">
                        {{ result.mapping.product.name }}
                    </router-link>
                    <br />
                    Тип лицензии:
                    <router-link
                        v-bind:to="`/licensing/license-types/${result.mapping.licenseType.licenseTypeId}/view`">
                        {{ result.mapping.licenseType.name }}
                    </router-link>
                </div>
                <div v-else>Идентификатору продукта не соответствует ни один маппинг.</div>

                <h3>Совпадение названия с парсингом</h3>
                <div v-if="result.parsingMatch.success">
                    Парсинг:
                    <router-link
                        v-bind:to="`/licensing/product-parsings/${result.parsingMatch.parsing.productParsingId}/view`">
                        {{ result.parsingMatch.parsing.name }}
                    </router-link>
                    <br />
                    Продукт:
                    <router-link v-bind:to="`/licensing/products/${result.parsingMatch.product.productId}/view`">
                        {{ result.parsingMatch.product.name }}
                    </router-link>
                    <br />
                    Тип лицензии:
                    <router-link
                        v-bind:to="`/licensing/license-types/${result.parsingMatch.licenseType.licenseTypeId}/view`">
                        {{ result.parsingMatch.licenseType.name }}
                    </router-link>
                </div>
                <div v-else>Название продукта не совпало ни с одним парсингом.</div>
            </div>
            <div v-else>
                <i>Нажми на кнопку - получишь результат.</i>
            </div>
        </form>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Note from '../../../misc/Note.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SearchField from '../../../inputs/SearchField.vue'
import TextField from '../../../inputs/TextField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import { postAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ToolProductIdentification',
    components: {
        Note,
        WrappedContent,
        SearchField,
        TextField,
        Button,
        ButtonsBlock,
    },
    data: function() {
        return {
            registratorValidationError: '',
            registratorProductNameValidationError: '',
            registratorProductIdValidationError: '',

            request: {
                registratorId: undefined,
                registratorProductName: '',
                registratorProductId: '',
            },
            requestFailure: '',
            result: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
    methods: {
        performRequest: function(callback) {
            this.requestFailure = ''
            this.request.registratorProductName = this.request.registratorProductName.trim()
            this.request.registratorProductId = this.request.registratorProductId.trim()

            var validationOk = true

            if (!this.request.registratorId) {
                this.registratorValidationError = 'Укажите регистратора'
                validationOk = false
            } else {
                this.registratorValidationError = ''
            }

            if (!this.request.registratorProductName) {
                this.registratorProductNameValidationError = 'Введите названия продукта'
                validationOk = false
            } else {
                this.registratorProductNameValidationError = ''
            }

            if (!this.request.registratorProductId) {
                this.registratorProductIdValidationError = 'Введите идентификатор продукта'
                validationOk = false
            } else {
                this.registratorProductIdValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            postAuthorizedRequest('/api/licensing/tools/product-identification', this.request)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.result = data))
                            break
                        default:
                            this.requestFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.requestFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },
    },
}
</script>