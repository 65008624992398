<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Парсинги продуктов</h1>
        <h1 v-else id="title-source">Поиск парсингов продуктов</h1>
        <ButtonsBlock class="access-level-support">
            <Button text="Создать парсинг продуктов" v-on:click="$router.push('/licensing/product-parsings/create')" />
        </ButtonsBlock>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <TextField id="search-registrator-name" labelText="Название:" v-model="searchParameters.name" />
                    <SearchField
                        id="search-registrator"
                        labelText="Регистратор:"
                        textKey="name"
                        valueKey="registratorId"
                        v-bind:getInfoUrl="`/api/licensing/registrators/${searchParameters.registratorId}`"
                        searchUrl="/api/licensing/registrators/search"
                        searchKey="namePartial"
                        v-model="searchParameters.registratorId" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button
                        text="Искать"
                        v-bind:useThrobber="false"
                        v-bind:enabled="isSearchValid"
                        v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <ProductParsingsList v-bind:productParsings="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SearchField from '../../../inputs/SearchField.vue'
import TextField from '../../../inputs/TextField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import ProductParsingsList from './ProductParsingsList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'ProductParsingsPage',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        SearchField,
        TextField,
        ExpandableContent,
        PageController,
        ProductParsingsList,
        RequestWrapper,
    },
    data: function() {
        return {
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    computed: {
        isSearchValid: function() {
            return (this.searchParameters.name || '').trim() != '' || this.searchParameters.registratorId != undefined
        },
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                name: this.$route.query.name || '',
                registratorId: Number(this.$route.query.registratorId) || undefined,
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            let query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/licensing/product-parsings', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {}
                    if (this.searchParameters.name) request.namePartial = this.searchParameters.name
                    if (this.searchParameters.registratorId)
                        request.registratorIdExact = this.searchParameters.registratorId

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/licensing/product-parsings/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            this.searchParameters.name = this.searchParameters.name.trim()

            let query = { mode: 'search' }
            if (this.searchParameters.name) query.name = this.searchParameters.name
            if (this.searchParameters.registratorId) query.registratorId = this.searchParameters.registratorId

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>