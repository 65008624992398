<template>
    <div>
        <h2 id="title-source" class="hidden">Вход</h2>
        <div class="signin-notification signin-info" v-if="reason == 'password-set'">
            Новый пароль вашего аккаунта был сохранён. Используйте его для входа.
        </div>
        <div class="signin-notification signin-warning" v-if="reason == 'expired'">
            Похоже, ваша сессия истекла. Придётся повторить вход.
        </div>
        <div class="signin-notification signin-error" v-show="loginError != ''">
            {{ loginError }}
        </div>
        <form>
            <TextField
                id="login"
                labelText="Логин:"
                v-bind:errorText="loginValidationError"
                v-model="authRequest.login" />
            <TextField
                id="password"
                labelText="Пароль:"
                v-bind:errorText="passwordValidationError"
                isPassword
                v-model="authRequest.password" />
            <Recaptcha
                v-if="requirements && requirements.captchaRequired"
                v-bind:sitekey="requirements.recaptchaSiteKey"
                v-bind:errorText="recaptchaValidationError"
                style="margin: 10px 0px"
                v-model="authRequest.recaptchaResponse" />
            <CheckBox id="rememberMe" labelText="Запомнить меня" v-bind:value="true" v-model="authRequest.rememberMe" />
            <ButtonsBlock>
                <Button text="Войти" v-bind:enabled="requirements != undefined" v-on:click="performSignin" />
            </ButtonsBlock>
        </form>
    </div>
</template>

<script>
import NonAuthorized from '../../layout/NonAuthorized.vue'
import TextField from '../../inputs/TextField.vue'
import CheckBox from '../../inputs/CheckBox.vue'
import Recaptcha from '../../inputs/Recaptcha.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import { getRequest, postRequest } from '../../../common.js'

export default {
    name: 'SigninPage',
    components: {
        TextField,
        CheckBox,
        Recaptcha,
        Button,
        ButtonsBlock,
    },
    data: function() {
        return {
            requirements: undefined,
            authRequest: {
                login: '',
                password: '',
                recaptchaResponse: '',
                rememberMe: true,
            },
            loginValidationError: '',
            passwordValidationError: '',
            recaptchaValidationError: '',
            loginError: '',
            returnUrl: this.$route.query.returnUrl,
            reason: this.$route.query.reason,
        }
    },
    created: function() {
        this.$emit('update:layout', NonAuthorized)

        getRequest('/api/auth')
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => {
                            if (data.isAuthenticated === true) {
                                this.$router.push('/')
                            }
                        })
                        break
                    default:
                        this.loginError = `Плохой ответ на запрос статуса аутентификации (${res.status})`
                }
            })
            .catch(() => {
                this.loginError = 'Не удалось проверить статус аутентификации'
            })

        this.loadRequirements()
    },
    methods: {
        loadRequirements: function() {
            this.requirements = undefined

            getRequest('/api/auth/requirements')
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.requirements = data))
                            break
                        default:
                            this.loginError = `Плохой ответ на запрос требований (${res.status})`
                    }
                })
                .catch(() => {
                    this.loginError = 'Не удалось выполнить запрос требований'
                })
        },

        performSignin: function(callback) {
            var validationOk = true

            if (this.authRequest.login.trim() == '') {
                this.loginValidationError = 'Введите логин'
                validationOk = false
            } else {
                this.loginValidationError = ''
            }

            if (this.authRequest.password.trim() == '') {
                this.passwordValidationError = 'Введите пароль'
                validationOk = false
            } else {
                this.passwordValidationError = ''
            }

            if (this.requirements.captchaRequired && !this.authRequest.recaptchaResponse) {
                this.recaptchaValidationError = 'Пройдите каптчу'
                validationOk = false
            } else {
                this.recaptchaValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            postRequest('/api/auth/signin', this.authRequest)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            if (this.returnUrl != undefined) {
                                this.$router.push(this.returnUrl)
                                return
                            }

                            this.$router.push('/')
                            break
                        case 401:
                            res.json().then(data => {
                                switch (data.result) {
                                    case 'InvalidCredentials':
                                        this.loginError = 'Неправильный логин или пароль'
                                        break
                                    case 'CaptchaRequired':
                                        this.loginError = 'Необходимо пройти каптчу'
                                        break
                                    case 'InvalidCaptchaAnswer':
                                        this.loginError = 'Неправильный ответ на каптчу'
                                        break
                                    default:
                                        this.loginError = `Неудачная попытка входа (${data.result})`
                                        break
                                }
                            })

                            this.loadRequirements()
                            break
                        default:
                            this.loginError = `Плохой ответ на запрос (${res.status})`
                            this.loadRequirements()
                            break
                    }
                })
                .catch(() => {
                    this.loginError = 'Не удалось выполнить запрос'
                })
                .then(callback)
        },
    },
}
</script>

<style scoped>
.signin-notification {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 0.8em;
    border: 1px dashed #dd9;
    border-radius: 6px;
}

.signin-info {
    color: #ccc;
    border-color: #888;
}

.signin-warning {
    color: #dd9;
    border-color: #dd9;
}

.signin-error {
    color: #faa;
    border-color: #faa;
}
</style>