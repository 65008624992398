<template>
    <div class="form-field checkbox" v-bind:class="{ 'checkbox-disabled': !enabled }">
        <input
            type="checkbox"
            v-bind:id="id"
            v-bind:name="name || id"
            v-bind:disabled="!enabled"
            v-bind:checked="isChecked"
            v-on:input="inputHandler($event)" />
        <label v-bind:for="id">{{ labelText }}</label>
    </div>
</template>

<script>
export default {
    name: 'CheckBox',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        name: String,
        id: String,
        labelText: String,
    },
    data: function() {
        return {
            isChecked: this.value,
        }
    },
    methods: {
        inputHandler: function(event) {
            this.isChecked = event.target.checked
            this.$emit('input', event.target.checked)
        },
    },
}
</script>

<style scoped>
input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

label {
    display: flex;
    align-items: center;
}

label::before {
    content: '';
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

input:checked + label::before {
    background-image: url('../../assets/inputs/checkbox-tick.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 70%;
}

.checkbox:not(.checkbox-disabled) input + label:hover::before,
input:focus + label::before {
    border-color: #7491b0;
}

.checkbox-disabled {
    color: #555;
    border-color: #2c394a;
}

.checkbox-disabled label::before {
    border-color: #2c394a;
    background-color: #232e3b;
}
</style>
