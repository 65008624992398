<template>
    <div class="menu-section">
        <div class="menu-section-header">
            <img
                class="menu-section-icon"
                alt="menu-section-icon"
                v-bind:src="require(`../../assets/layout/menu/${icon}`)" />
            <span class="menu-section-title">{{ title }}</span>
        </div>
        <div class="menu-section-items">
            <router-link v-for="(item, index) in items" v-bind:key="index" v-bind:to="item.url">
                <div class="menu-section-item">{{ item.text }}</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuSection',
    props: {
        title: String,
        icon: String,
        items: Array,
    },
}
</script>

<style scoped>
.menu-section-header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #bbb;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
}

.menu-section-icon {
    height: 50%;
}

.menu-section-title {
    margin-left: 15px;
}

.menu-section-item {
    display: flex;
    align-items: center;
    padding: 0px 30px 0px 25px;
    border-left: 5px solid transparent;
    box-sizing: border-box;
    height: 36px;
    background-color: #181818;
}

.menu-section-item:hover {
    border-color: #0f7db8;
    background-color: #12171a;
}

a.router-link-active .menu-section-item {
    border-color: #0f7db8;
}

a + a .menu-section-item {
    margin-top: 2px;
}
</style>