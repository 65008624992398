<template>
    <div>
        <ItemsList>
            <div class="item" v-for="ipStat in ipStats" v-bind:key="ipStat.ipStatId">
                <div class="item-details">
                    <span class="item-title">
                        {{ ipStat.ipAddress }}
                    </span>
                    <div class="reputation-widgets">
                        <ListsImpactsWidget v-bind:obj="ipStat" />
                        <StatsWidget v-bind:stat="ipStat" />
                    </div>
                </div>
                <div class="item-links">
                    <IpAddressPopupMenu v-bind:ip="ipStat.ipAddress" title="Меню" />
                    <StaticBlockPopupMenu
                        v-bind:startIp="ipStat.staticBlockStartIpAddress"
                        v-bind:endIp="ipStat.staticBlockEndIpAddress"
                        title="Стат. блок" />
                    <AsnBlockPopupMenu
                        v-bind:startIp="ipStat.asnBlockStartIpAddress"
                        v-bind:endIp="ipStat.asnBlockEndIpAddress"
                        title="Блок ASN" />
                    <AsnPopupMenu v-bind:asnId="ipStat.asnId" title="ASN" />
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import StatsWidget from './StatsWidget.vue'
import ListsImpactsWidget from './ListsImpactsWidget.vue'
import AsnPopupMenu from '../AsnPopupMenu.vue'
import AsnBlockPopupMenu from '../AsnBlockPopupMenu.vue'
import StaticBlockPopupMenu from '../StaticBlockPopupMenu.vue'
import IpAddressPopupMenu from '../IpAddressPopupMenu.vue'

export default {
    name: 'IpAddressStatsList',
    components: {
        ItemsList,
        StatsWidget,
        ListsImpactsWidget,
        AsnPopupMenu,
        AsnBlockPopupMenu,
        StaticBlockPopupMenu,
        IpAddressPopupMenu,
    },
    props: {
        ipStats: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.item-title {
    margin-right: 5px;
}

.reputation-widgets {
    margin-top: 5px;
}
</style>