<template>
    <div class="form-field text-field" v-bind:class="{ 'text-field-disabled': !enabled }">
        <label v-bind:for="id">{{ labelText }}<span class="text-field-required" v-if="required"> *</span></label>
        <div class="text-field-main-container">
            <div class="text-field-input-container">
                <input
                    v-bind:type="isPassword && !showPassword ? 'password' : 'text'"
                    v-bind:id="id"
                    v-bind:name="name || id"
                    v-bind:maxlength="maxlength"
                    v-bind:placeholder="placeholderText"
                    v-bind:class="isPassword ? 'text-field-input-password' : 'text-field-input'"
                    v-bind:disabled="!enabled"
                    v-bind:value="value"
                    v-on:input="$emit('input', $event.target.value)" />
                <div
                    v-if="isPassword && enabled"
                    class="text-field-password-toggle"
                    v-bind:class="showPassword ? 'text-field-password-hide' : 'text-field-password-show'"
                    v-on:click="showPassword = !showPassword" />
            </div>
        </div>
        <FieldNotifications v-bind:errorText="errorText" />
    </div>
</template>

<script>
import FieldNotifications from './FieldNotifications.vue'

export default {
    name: 'TextField',
    components: {
        FieldNotifications,
    },
    props: {
        value: String,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        maxlength: {
            type: Number,
            default: undefined,
        },
        labelText: String,
        placeholderText: {
            type: String,
            default: undefined,
        },
        isPassword: Boolean,
        errorText: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            showPassword: false,
        }
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

.text-field:not(.text-field-disabled) .text-field-required {
    color: #dda;
}

.text-field-main-container {
    display: flex;
    align-items: flex-start;
}

.text-field-input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
}

.text-field-input-container:focus-within,
.text-field:not(.text-field-disabled) .text-field-input-container:hover {
    border-color: #7491b0;
}

input {
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #a2bfdb;
    font: inherit;
}

input::placeholder {
    font-style: italic;
    color: #777;
}

.text-field-input {
    width: 100%;
}

.text-field-input-password {
    width: calc(100% - 18px);
}

.text-field-password-toggle {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    opacity: 0.5;
}

.text-field-input-container:focus-within .text-field-password-toggle,
.text-field:not(.text-field-disabled) .text-field-input-container:hover .text-field-password-toggle {
    opacity: 1;
}

.text-field-password-show {
    background-image: url('../../assets/inputs/password-show.svg');
}

.text-field-password-hide {
    background-image: url('../../assets/inputs/password-hide.svg');
}

.text-field-disabled {
    color: #555;
}

.text-field-disabled .text-field-input-container {
    border-color: #2c394a;
    background-color: #232e3b;
}

.text-field-disabled input {
    color: #465a70;
}

/* костыль, чтобы автозаполнение в Chrome не меняло радикально стиль поля */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2bfdb;
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
}
</style>
