<template>
    <div>
        <ItemsList>
            <div
                class="item"
                v-for="staticBlockStat in staticBlockStats"
                v-bind:key="staticBlockStat.staticBlockStatId">
                <div class="item-details">
                    <span class="item-title">
                        {{ staticBlockStat.startIpAddress }} – {{ staticBlockStat.endIpAddress }}
                    </span>
                    <div class="reputation-widgets">
                        <ListsImpactsWidget v-bind:obj="staticBlockStat" />
                        <StatsWidget v-bind:stat="staticBlockStat" />
                    </div>
                </div>
                <div class="item-links">
                    <StaticBlockPopupMenu
                        v-bind:startIp="staticBlockStat.startIpAddress"
                        v-bind:endIp="staticBlockStat.endIpAddress"
                        title="Меню" />
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import StatsWidget from './StatsWidget.vue'
import ListsImpactsWidget from './ListsImpactsWidget.vue'
import StaticBlockPopupMenu from '../StaticBlockPopupMenu.vue'

export default {
    name: 'StaticBlockStatsList',
    components: {
        ItemsList,
        StatsWidget,
        ListsImpactsWidget,
        StaticBlockPopupMenu,
    },
    props: {
        staticBlockStats: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.item-title {
    margin-right: 5px;
}

.reputation-widgets {
    margin-top: 5px;
}
</style>