<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Статистика веб-сессий IP-адресов</h1>
        <h1 v-else id="title-source">Поиск статистики веб-сессий IP-адресов</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <IpAddressField id="search-ip" labelText="IP-адрес:" v-model="searchParameters.ipAddressExact" />
                    <NumberField
                        id="search-asn-id"
                        labelText="ASN:"
                        v-model="searchParameters.asnId"
                        v-bind:min="0"
                        v-bind:max="4294967295" />
                    <IpAddressField
                        id="search-asn-block-start-ip"
                        labelText="Начальный IP-адрес блока ASN:"
                        v-model="searchParameters.asnBlockStartIpAddressExact" />
                    <IpAddressField
                        id="search-asn-block-end-ip"
                        labelText="Конечный IP-адрес блока ASN:"
                        v-model="searchParameters.asnBlockEndIpAddressExact" />
                    <IpAddressField
                        id="search-static-block-start-ip"
                        labelText="Начальный IP-адрес статичного блока:"
                        v-model="searchParameters.staticBlockStartIpAddressExact" />
                    <IpAddressField
                        id="search-static-block-end-ip"
                        labelText="Конечный IP-адрес статичного блока:"
                        v-model="searchParameters.staticBlockEndIpAddressExact" />
                    <NumberField
                        id="search-sessions-count-min"
                        labelText="Минимальное количество веб-сессий:"
                        v-model="searchParameters.webSessionsCountMin"
                        v-bind:min="1"
                        v-bind:max="2147483647" />
                    <NumberField
                        id="search-sessions-unfair-percent-min"
                        labelText="Минимальный процент нечестных веб-сессий:"
                        v-model="searchParameters.webSessionsUnfairPercentMin"
                        v-bind:min="0"
                        v-bind:max="100" />
                    <NumberField
                        id="search-sessions-direct-percent-min"
                        labelText="Минимальный процент веб-сессий без Referer:"
                        v-model="searchParameters.webSessionsDirectPercentMin"
                        v-bind:min="0"
                        v-bind:max="100" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button text="Искать" v-bind:useThrobber="false" v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <IpAddressStatsList v-bind:ipStats="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import NumberField from '../../../inputs/NumberField.vue'
import IpAddressField from '../../../inputs/IpAddressField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import IpAddressStatsList from './IpAddressStatsList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'IpAddressStatsPage',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        NumberField,
        IpAddressField,
        ExpandableContent,
        PageController,
        IpAddressStatsList,
        RequestWrapper,
    },
    data: function() {
        return {
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                asnId: Number(this.$route.query.asnId) || undefined,
                webSessionsCountMin: Number(this.$route.query.webSessionsCountMin) || undefined,
                webSessionsUnfairPercentMin: Number(this.$route.query.webSessionsUnfairPercentMin) || undefined,
                webSessionsDirectPercentMin: Number(this.$route.query.webSessionsDirectPercentMin) || undefined,
                asnBlockStartIpAddressExact: this.$route.query.asnBlockStartIp || undefined,
                asnBlockEndIpAddressExact: this.$route.query.asnBlockEndIp || undefined,
                staticBlockStartIpAddressExact: this.$route.query.staticBlockStartIp || undefined,
                staticBlockEndIpAddressExact: this.$route.query.staticBlockEndIp || undefined,
                ipAddressExact: this.$route.query.ip || undefined,
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            var query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/reputation/stats/ips', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {
                        eventsMin: {},
                        eventTagsPercentMin: {},
                    }

                    if (this.searchParameters.asnId) request.asnIdExact = this.searchParameters.asnId
                    if (this.searchParameters.webSessionsCountMin)
                        request.eventsMin['WebSession'] = this.searchParameters.webSessionsCountMin
                    if (this.searchParameters.webSessionsUnfairPercentMin)
                        request.eventTagsPercentMin[
                            'WebSession#unfair'
                        ] = this.searchParameters.webSessionsUnfairPercentMin
                    if (this.searchParameters.webSessionsDirectPercentMin)
                        request.eventTagsPercentMin[
                            'WebSession#direct'
                        ] = this.searchParameters.webSessionsDirectPercentMin
                    if (this.searchParameters.asnBlockStartIpAddressExact)
                        request.asnBlockStartIpAddressExact = this.searchParameters.asnBlockStartIpAddressExact
                    if (this.searchParameters.asnBlockEndIpAddressExact)
                        request.asnBlockEndIpAddressExact = this.searchParameters.asnBlockEndIpAddressExact
                    if (this.searchParameters.staticBlockStartIpAddressExact)
                        request.staticBlockStartIpAddressExact = this.searchParameters.staticBlockStartIpAddressExact
                    if (this.searchParameters.staticBlockEndIpAddressExact)
                        request.staticBlockEndIpAddressExact = this.searchParameters.staticBlockEndIpAddressExact
                    if (this.searchParameters.ipAddressExact)
                        request.ipAddressExact = this.searchParameters.ipAddressExact

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/reputation/stats/ips/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            let query = {
                mode: 'search',
                asnId: undefined,
                webSessionsCountMin: undefined,
                webSessionsUnfairPercentMin: undefined,
                webSessionsDirectPercentMin: undefined,
                asnBlockStartIp: undefined,
                asnBlockEndIp: undefined,
                staticBlockStartIp: undefined,
                staticBlockEndIp: undefined,
                ip: undefined,
            }

            if (this.searchParameters.asnId) query.asnId = this.searchParameters.asnId
            if (this.searchParameters.webSessionsCountMin)
                query.webSessionsCountMin = this.searchParameters.webSessionsCountMin
            if (this.searchParameters.webSessionsUnfairPercentMin)
                query.webSessionsUnfairPercentMin = this.searchParameters.webSessionsUnfairPercentMin
            if (this.searchParameters.webSessionsDirectPercentMin)
                query.webSessionsDirectPercentMin = this.searchParameters.webSessionsDirectPercentMin
            if (this.searchParameters.asnBlockStartIpAddressExact)
                query.asnBlockStartIp = this.searchParameters.asnBlockStartIpAddressExact
            if (this.searchParameters.asnBlockEndIpAddressExact)
                query.asnBlockEndIp = this.searchParameters.asnBlockEndIpAddressExact
            if (this.searchParameters.staticBlockStartIpAddressExact)
                query.staticBlockStartIp = this.searchParameters.staticBlockStartIpAddressExact
            if (this.searchParameters.staticBlockEndIpAddressExact)
                query.staticBlockEndIp = this.searchParameters.staticBlockEndIpAddressExact
            if (this.searchParameters.ipAddressExact) query.ip = this.searchParameters.ipAddressExact

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>