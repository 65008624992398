<template>
    <div class="reputation-stats-widget">
        <div class="reputation-stats-widget-sessions-total" title="Всего веб-сессий">
            {{ webSessionsCount }}
        </div>
        <div
            class="reputation-stats-widget-counter reputation-stats-widget-counter-unfair"
            v-bind:title="`Веб-сессий без правдоподобной активности пользователя: ${webSessionsUnfairCount}`"
            v-bind:style="webSessionsUnfairColorStyle">
            <div class="reputation-stats-widget-counter-icon" />
            <div class="reputation-stats-widget-counter-percent">{{ webSessionsUnfairPercent }}%</div>
            <div
                v-if="isUnfairWebSessions"
                class="reputation-stats-widget-counter-indicator reputation-stats-widget-counter-indicator-wrongful"
                title="Поведение считается зловредным" />
            <div v-else class="reputation-stats-widget-counter-indicator" title="Поведение не считается зловредным">
                ✓
            </div>
        </div>
        <div
            class="reputation-stats-widget-counter reputation-stats-widget-counter-direct"
            v-bind:title="`Веб-сессий с пустым referer: ${webSessionsDirectCount}`"
            v-bind:style="webSessionsDirectColorStyle">
            <div class="reputation-stats-widget-counter-icon" />
            <div class="reputation-stats-widget-counter-percent">{{ webSessionsDirectPercent }}%</div>
            <div
                v-if="isDirectWebSessions"
                class="reputation-stats-widget-counter-indicator reputation-stats-widget-counter-indicator-wrongful"
                title="Поведение считается зловредным" />
            <div v-else class="reputation-stats-widget-counter-indicator" title="Поведение не считается зловредным">
                ✓
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatsWidget',

    props: {
        stat: {
            type: Object,
            required: true,
        },
    },

    computed: {
        webSessionsCount: function() {
            return this.stat.eventCounters['WebSession'] || 0
        },
        webSessionsUnfairCount: function() {
            return this.stat.eventCounters['WebSession#unfair'] || 0
        },
        webSessionsDirectCount: function() {
            return this.stat.eventCounters['WebSession#direct'] || 0
        },
        webSessionsUnfairPercent: function() {
            return this.webSessionsCount > 0
                ? Math.round((this.webSessionsUnfairCount / this.webSessionsCount) * 100)
                : 0
        },
        webSessionsDirectPercent: function() {
            return this.webSessionsCount > 0
                ? Math.round((this.webSessionsDirectCount / this.webSessionsCount) * 100)
                : 0
        },
        webSessionsUnfairColorStyle: function() {
            var ratio = this.webSessionsUnfairPercent / 100
            var r = 192 * ratio
            var g = 192 - 192 * ratio
            var b = 0
            return `background-color: rgba(${r},${g},${b},0.4)`
        },
        webSessionsDirectColorStyle: function() {
            var ratio = this.webSessionsDirectPercent / 100
            var r = 192 * ratio
            var g = 192 - 192 * ratio
            var b = 0
            return `background-color: rgba(${r},${g},${b},0.4)`
        },
        isUnfairWebSessions: function() {
            return this.stat.flags.some(f => f.endsWith(':UnfairWebSessions'))
        },
        isDirectWebSessions: function() {
            return this.stat.flags.some(f => f.endsWith(':DirectWebSessions'))
        },
    },
}
</script>

<style scoped>
.reputation-stats-widget {
    display: inline-flex;
    margin-bottom: 2px;
    color: #ddd;
    background-color: #2f2f2f;
    border-radius: 5px;
    text-align: center;
}

.reputation-stats-widget-sessions-total {
    min-width: 55px;
    flex-grow: 0;
    padding: 0px 10px;
}

.screen-s .reputation-stats-widget-sessions-total {
    text-align: left;
    padding-left: 10px;
}

.reputation-stats-widget > div:last-child {
    margin-left: 2px;
}

.reputation-stats-widget-counter {
    display: flex;
    border-radius: 4px;
}

.reputation-stats-widget-counter-icon,
.reputation-stats-widget-counter-indicator {
    width: 30px;
    background-position: center center;
    background-repeat: no-repeat;
}

.reputation-stats-widget-counter-unfair .reputation-stats-widget-counter-icon {
    background-image: url('../../../../assets/reputation/stats/web-session-unfair.svg');
    background-size: auto 70%;
}

.reputation-stats-widget-counter-direct .reputation-stats-widget-counter-icon {
    background-image: url('../../../../assets/reputation/stats/web-session-direct.svg');
    background-size: auto 60%;
}

.screen-s .reputation-stats-widget-counter-icon {
    display: none;
}

.reputation-stats-widget-counter-percent {
    width: 55px;
}

.screen-s .reputation-stats-widget-counter-percent {
    text-align: left;
    width: 45px;
    padding-left: 10px;
}

.reputation-stats-widget-counter-indicator {
    flex-grow: 0;
    width: 30px;
    user-select: none;
    color: #ddd4;
    background-size: auto 70%;
}

.reputation-stats-widget-counter-unfair .reputation-stats-widget-counter-indicator-wrongful {
    background-image: url('../../../../assets/reputation/stats/web-session-unfair-wrongful.svg');
}

.reputation-stats-widget-counter-direct .reputation-stats-widget-counter-indicator-wrongful {
    background-image: url('../../../../assets/reputation/stats/web-session-direct-wrongful.svg');
}
</style>