<template>
    <div>
        <h1 id="title-source">
            Удаление
            <router-link v-bind:to="`/licensing/product-mappings/${productMappingId}/view`">
                маппинга продуктов #{{ productMappingId }}
            </router-link>
        </h1>

        <RequestWrapper
            v-bind:data="productMapping"
            v-bind:errorText="productMappingErrorText"
            v-bind:errorCode="productMappingErrorCode">
            <template v-slot:default>
                <Warning>
                    При удалении маппинга продуктов обработка IPN с соответствующим идентификатором продукта у
                    регистратора станет невозможной.
                </Warning>
                <ButtonsBlock>
                    <Button
                        text="Подтверждаю удаление"
                        destructive
                        v-bind:failureText="deleteFailure"
                        v-on:click="performDelete" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, deleteAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductMappingDeletePage',
    components: {
        Warning,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productMappingId: this.$route.params.id,
            productMapping: undefined,
            productMappingErrorText: undefined,
            productMappingErrorCode: undefined,
            deleteFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/product-mappings/${this.productMappingId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.productMapping = data))
                        break
                    default:
                        this.productMappingErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productMappingErrorText = error))
    },
    methods: {
        performDelete: function(callback) {
            this.deleteFailure = ''

            deleteAuthorizedRequest(`/api/licensing/product-mappings/${this.productMappingId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.$router.push('/licensing/product-mappings')
                            break
                        default:
                            this.deleteFailure = `Плохой ответ на запрос (${res.status})`
                            callback()
                            break
                    }
                })
                .catch(() => (this.deleteFailure = 'Не удалось выполнить запрос'))
        },
    },
}
</script>