<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Лицензии</h1>
        <h1 v-else id="title-source">Поиск лицензий</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <SearchField
                        id="search-customer"
                        labelText="Клиент:"
                        textKey="email"
                        valueKey="customerId"
                        v-bind:getInfoUrl="`/api/licensing/customers/${searchParameters.customerId}`"
                        searchUrl="/api/licensing/customers/search"
                        searchKey="emailPartial"
                        v-model="searchParameters.customerId" />
                    <SearchField
                        id="search-product"
                        labelText="Продукт:"
                        textKey="name"
                        valueKey="productId"
                        v-bind:getInfoUrl="`/api/licensing/products/${searchParameters.productId}`"
                        searchUrl="/api/licensing/products/search"
                        searchKey="namePartial"
                        v-model="searchParameters.productId" />
                    <SearchField
                        id="search-registrator"
                        labelText="Регистратор:"
                        textKey="name"
                        valueKey="registratorId"
                        v-bind:getInfoUrl="`/api/licensing/registrators/${searchParameters.registratorId}`"
                        searchUrl="/api/licensing/registrators/search"
                        searchKey="namePartial"
                        v-model="searchParameters.registratorId" />
                    <TextField
                        id="search-registrator-order-id"
                        labelText="Идентификатор ордера у регистратора:"
                        v-model="searchParameters.registratorOrderId" />
                    <SearchField
                        id="search-product-domain"
                        labelText="Домен продуктов:"
                        textKey="name"
                        valueKey="productDomainId"
                        v-bind:getInfoUrl="`/api/licensing/product-domains/${searchParameters.productDomainId}`"
                        searchUrl="/api/licensing/product-domains/search"
                        searchKey="namePartial"
                        v-model="searchParameters.productDomainId" />
                    <SearchField
                        id="search-license-type"
                        labelText="Тип лицензии:"
                        textKey="name"
                        valueKey="licenseTypeId"
                        v-bind:getInfoUrl="`/api/licensing/license-types/${searchParameters.licenseTypeId}`"
                        searchUrl="/api/licensing/license-types/search"
                        searchKey="namePartial"
                        v-model="searchParameters.licenseTypeId" />
                    <NumberField
                        id="search-order-id"
                        labelText="Идентификатор ордера:"
                        v-model="searchParameters.orderId" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button
                        text="Искать"
                        v-bind:useThrobber="false"
                        v-bind:enabled="isSearchValid"
                        v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <LicensesList v-bind:licenses="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SearchField from '../../../inputs/SearchField.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import LicensesList from './LicensesList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'LicensesPage',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        SearchField,
        TextField,
        NumberField,
        ExpandableContent,
        PageController,
        LicensesList,
        RequestWrapper,
    },
    data: function() {
        return {
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    computed: {
        isSearchValid: function() {
            return (
                this.searchParameters.customerId != undefined ||
                this.searchParameters.productId != undefined ||
                this.searchParameters.registratorId != undefined ||
                (this.searchParameters.registratorOrderId || '').trim() != '' ||
                this.searchParameters.productDomainId != undefined ||
                this.searchParameters.licenseTypeId != undefined ||
                this.searchParameters.orderId != undefined
            )
        },
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                customerId: Number(this.$route.query.customerId) || undefined,
                productId: Number(this.$route.query.productId) || undefined,
                registratorId: Number(this.$route.query.registratorId) || undefined,
                registratorOrderId: this.$route.query.registratorOrderId || '',
                productDomainId: Number(this.$route.query.productDomainId) || undefined,
                licenseTypeId: Number(this.$route.query.licenseTypeId) || undefined,
                orderId: Number(this.$route.query.orderId) || undefined,
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            let query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/licensing/licenses', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {}
                    if (this.searchParameters.customerId) request.customerIdExact = this.searchParameters.customerId
                    if (this.searchParameters.productId) request.productIdExact = this.searchParameters.productId
                    if (this.searchParameters.registratorId)
                        request.registratorIdExact = this.searchParameters.registratorId
                    if (this.searchParameters.registratorOrderId)
                        request.registratorOrderIdExact = this.searchParameters.registratorOrderId
                    if (this.searchParameters.productDomainId)
                        request.productDomainIdExact = this.searchParameters.productDomainId
                    if (this.searchParameters.licenseTypeId)
                        request.licenseTypeIdExact = this.searchParameters.licenseTypeId
                    if (this.searchParameters.orderId) request.orderIdExact = this.searchParameters.orderId

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/licensing/licenses/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            this.searchParameters.registratorOrderId = this.searchParameters.registratorOrderId.trim()

            let query = { mode: 'search' }
            if (this.searchParameters.customerId) query.customerId = this.searchParameters.customerId
            if (this.searchParameters.productId) query.productId = this.searchParameters.productId
            if (this.searchParameters.registratorId) query.registratorId = this.searchParameters.registratorId
            if (this.searchParameters.registratorOrderId)
                query.registratorOrderId = this.searchParameters.registratorOrderId
            if (this.searchParameters.productDomainId) query.productDomainId = this.searchParameters.productDomainId
            if (this.searchParameters.licenseTypeId) query.licenseTypeId = this.searchParameters.licenseTypeId
            if (this.searchParameters.orderId) query.orderId = this.searchParameters.orderId

            if (Object.keys(query).length == 1) return

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>