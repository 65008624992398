<template>
    <div>
        <h1 v-if="mode == 'view'" id="title-source">Статистика веб-сессий статичных блоков</h1>
        <h1 v-else id="title-source">Поиск статистики веб-сессий статичных блоков</h1>
        <ExpandableContent v-bind:expanded="mode == 'search'" summaryText="Поиск по параметрам">
            <form>
                <WrappedContent>
                    <IpAddressField
                        id="search-static-block-start-ip"
                        labelText="Начальный IP-адрес блока:"
                        v-model="searchParameters.startIpAddressExact" />
                    <IpAddressField
                        id="search-static-block-end-ip"
                        labelText="Конечный IP-адрес блока:"
                        v-model="searchParameters.endIpAddressExact" />
                    <NumberField
                        id="search-sessions-count-min"
                        labelText="Минимальное количество веб-сессий:"
                        v-model="searchParameters.webSessionsCountMin"
                        v-bind:min="1"
                        v-bind:max="2147483647" />
                    <NumberField
                        id="search-sessions-unfair-percent-min"
                        labelText="Минимальный процент нечестных веб-сессий:"
                        v-model="searchParameters.webSessionsUnfairPercentMin"
                        v-bind:min="0"
                        v-bind:max="100" />
                    <NumberField
                        id="search-sessions-direct-percent-min"
                        labelText="Минимальный процент веб-сессий без Referer:"
                        v-model="searchParameters.webSessionsDirectPercentMin"
                        v-bind:min="0"
                        v-bind:max="100" />
                </WrappedContent>
                <ButtonsBlock>
                    <Button text="Искать" v-bind:useThrobber="false" v-on:click="searchClickHandler" />
                </ButtonsBlock>
            </form>
        </ExpandableContent>
        <RequestWrapper
            v-bind:data="itemsPagination"
            v-bind:errorText="itemsPaginationErrorText"
            v-bind:errorCode="itemsPaginationErrorCode">
            <template v-slot:default>
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
                <StaticBlockStatsList v-bind:staticBlockStats="itemsPagination.items" />
                <PageController v-bind:pagination="itemsPagination" v-on:pageChange="pageChangeHandler" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import NumberField from '../../../inputs/NumberField.vue'
import IpAddressField from '../../../inputs/IpAddressField.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import PageController from '../../PageController.vue'
import StaticBlockStatsList from './StaticBlockStatsList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { buildUrl, getItemsPageSize, setItemsPageSize } from '../../../../common.js'

export default {
    name: 'StaticBlockStatsPage',
    components: {
        Button,
        ButtonsBlock,
        WrappedContent,
        NumberField,
        IpAddressField,
        ExpandableContent,
        PageController,
        StaticBlockStatsList,
        RequestWrapper,
    },
    data: function() {
        return {
            mode: 'view',
            itemsPagination: undefined,
            itemsPaginationErrorText: undefined,
            itemsPaginationErrorCode: undefined,
            searchParameters: {},
        }
    },
    watch: {
        $route() {
            this.initQueryParameters()
            this.loadItems()
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)
        this.initQueryParameters()
        this.loadItems()
    },
    methods: {
        initQueryParameters: function() {
            this.mode = this.$route.query.mode || 'view'
            this.searchParameters = {
                webSessionsCountMin: Number(this.$route.query.webSessionsCountMin) || undefined,
                webSessionsUnfairPercentMin: Number(this.$route.query.webSessionsUnfairPercentMin) || undefined,
                webSessionsDirectPercentMin: Number(this.$route.query.webSessionsDirectPercentMin) || undefined,
                startIpAddressExact: this.$route.query.startIp || undefined,
                endIpAddressExact: this.$route.query.endIp || undefined,
            }
        },

        loadItems: function() {
            this.itemsPagination = undefined

            var query = {
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || getItemsPageSize(),
            }

            switch (this.mode) {
                case 'view':
                    getAuthorizedRequest(buildUrl('/api/reputation/stats/static-blocks', query))
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
                case 'search':
                    var request = {
                        eventsMin: {},
                        eventTagsPercentMin: {},
                    }

                    if (this.searchParameters.webSessionsCountMin)
                        request.eventsMin['WebSession'] = this.searchParameters.webSessionsCountMin
                    if (this.searchParameters.webSessionsUnfairPercentMin)
                        request.eventTagsPercentMin[
                            'WebSession#unfair'
                        ] = this.searchParameters.webSessionsUnfairPercentMin
                    if (this.searchParameters.webSessionsDirectPercentMin)
                        request.eventTagsPercentMin[
                            'WebSession#direct'
                        ] = this.searchParameters.webSessionsDirectPercentMin
                    if (this.searchParameters.startIpAddressExact)
                        request.startIpAddressExact = this.searchParameters.startIpAddressExact
                    if (this.searchParameters.endIpAddressExact)
                        request.endIpAddressExact = this.searchParameters.endIpAddressExact

                    this.itemsPagination = undefined

                    postAuthorizedRequest(buildUrl('/api/reputation/stats/static-blocks/search', query), request)
                        .then(res => {
                            switch (res.status) {
                                case 200:
                                    res.json().then(data => (this.itemsPagination = data))
                                    break
                                default:
                                    this.itemsPaginationErrorCode = res.status
                                    break
                            }
                        })
                        .catch(error => (this.itemsPaginationErrorText = error))
                    break
            }
        },

        pageChangeHandler: function(page, pageSize) {
            let newQuery = Object.assign({}, this.$route.query)
            newQuery.page = page
            newQuery.pageSize = pageSize
            setItemsPageSize(pageSize)
            this.$router.push({ path: this.$route.path, query: newQuery })
        },

        searchClickHandler: function() {
            let query = {
                mode: 'search',
                webSessionsCountMin: undefined,
                webSessionsUnfairPercentMin: undefined,
                webSessionsDirectPercentMin: undefined,
                startIp: undefined,
                endIp: undefined,
            }

            if (this.searchParameters.webSessionsCountMin)
                query.webSessionsCountMin = this.searchParameters.webSessionsCountMin
            if (this.searchParameters.webSessionsUnfairPercentMin)
                query.webSessionsUnfairPercentMin = this.searchParameters.webSessionsUnfairPercentMin
            if (this.searchParameters.webSessionsDirectPercentMin)
                query.webSessionsDirectPercentMin = this.searchParameters.webSessionsDirectPercentMin
            if (this.searchParameters.startIpAddressExact) query.startIp = this.searchParameters.startIpAddressExact
            if (this.searchParameters.endIpAddressExact) query.endIp = this.searchParameters.endIpAddressExact

            this.$router.push({ path: this.$route.path, query: query })
        },
    },
}
</script>