<template>
    <div>
        <h1 id="title-source">Детали лицензии #{{ licenseId }}</h1>

        <RequestWrapper v-bind:data="license" v-bind:errorText="licenseErrorText" v-bind:errorCode="licenseErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Дата и время добавления">
                        <DateTime v-bind:dt="license.added" />
                    </ItemDetail>
                    <ItemDetail name="Ордер">
                        <router-link v-bind:to="`/licensing/orders/${license.order.orderId}/view`">
                            {{ getOrderTitle(license.order) }}
                        </router-link>
                        <span v-if="license.order.status != 'Normal'">
                            <OrderStatus v-bind:order="license.order" lowerCase />
                        </span>
                    </ItemDetail>
                    <ItemDetail name="Клиент">
                        <router-link v-bind:to="`/licensing/customers/${license.order.customer.customerId}/view`">
                            {{ license.order.customer.email }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Продукт">
                        <router-link v-bind:to="`/licensing/products/${license.order.product.productId}/view`">
                            {{ license.order.product.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Тип лицензии">
                        <router-link
                            v-bind:to="`/licensing/license-types/${license.order.licenseType.licenseTypeId}/view`">
                            {{ license.order.licenseType.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Дата и время истечения">
                        <div v-if="license.expires">
                            <DateTime v-bind:dt="license.expires" />
                        </div>
                        <div v-else>Нет срока истечения</div>
                    </ItemDetail>
                </WrappedContent>
                <div class="registration-сode">
                    <ExpandableContent summaryText="Регистрационный код">
                        {{ license.registrationCode }}
                    </ExpandableContent>
                    <ExpandableContent summaryText="Встроенные в код данные" style="white-space: pre-line">
                        {{ license.embeddedData }}
                    </ExpandableContent>
                </div>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Отправить клиенту по e-mail"
                        v-bind:successText="sendSuccess"
                        v-bind:failureText="sendFailure"
                        v-on:click="sendHandler" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/licenses/${licenseId}/delete`)" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import DateTime from '../../../misc/DateTime.vue'
import OrderStatus from '../orders/OrderStatus.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'LicenseViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        DateTime,
        OrderStatus,
        Button,
        ButtonsBlock,
        ExpandableContent,
        RequestWrapper,
    },
    data: function() {
        return {
            sendActionTypeOptions: [
                { value: 'Registration', text: 'Регистрация' },
                { value: 'Prolongation', text: 'Продление' },
            ],
            licenseId: this.$route.params.id,
            license: undefined,
            licenseErrorText: undefined,
            licenseErrorCode: undefined,
            sendSuccess: '',
            sendFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/licenses/${this.licenseId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.license = data))
                        break
                    default:
                        this.licenseErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.licenseErrorText = error))
    },
    methods: {
        getOrderTitle: function(order) {
            if (order.registrator == undefined) return `#${order.orderId}`
            return `${order.registrator.name}-${order.registratorOrderId}`
        },
        sendHandler: function(callback) {
            this.sendSuccess = ''
            this.sendFailure = ''

            postAuthorizedRequest(`/api/licensing/licenses/${this.licenseId}/send`)
                .then(res => {
                    switch (res.status) {
                        case 202:
                            this.sendSuccess = 'Письмо поставлено в очередь на отправку'
                            break
                        default:
                            this.sendFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(error => (this.sendFailure = error))
                .then(callback)
        },
    },
}
</script>