<template>
    <ul>
        <li>Длина от 8 до 72 символов</li>
        <li>Латинские буквы разных регистров и цифры</li>
        <li>Пробелы по краям удаляются</li>
    </ul>
</template>

<script>
export default {
    name: 'PasswordRequirements',
}
</script>

<style scoped>
ul {
    font-size: 0.9em;
    margin-top: 5px;
}
</style>
