<template>
    <div>
        <ItemsList>
            <div class="item" v-for="productDomain in productDomains" v-bind:key="productDomain.productDomainId">
                <div class="item-details">
                    <span class="item-title">{{ productDomain.name }}</span>
                    <br />
                    Сайт: <ExternalLink v-bind:url="productDomain.site" />
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/product-domains/${productDomain.productDomainId}/view`"
                        >Подробнее</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-domains/${productDomain.productDomainId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-domains/${productDomain.productDomainId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'

export default {
    name: 'ProductDomainsList',
    components: {
        ItemsList,
        ExternalLink,
    },
    props: {
        productDomains: {
            type: Array,
            default: undefined,
        },
    },
}
</script>