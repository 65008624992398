<template>
    <span v-if="order" v-bind:class="classes">{{ lowerCase ? text.toLowerCase() : text }}</span>
</template>

<script>
import { orderStatusToString } from '../../../../common.js'

export default {
    name: 'OrderStatus',
    props: {
        order: Object,
        lowerCase: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        text: function() {
            return orderStatusToString(this.order.status)
        },
        classes: function() {
            var result = {}
            result[`order-status-${this.order.status.toLowerCase()}`] = true
            return result
        },
    },
}
</script>

<style scoped>
.order-status-refund,
.order-status-partialrefund {
    color: #dd9;
}

.order-status-chargeback {
    color: #d99;
}
</style>