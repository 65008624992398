<template>
    <div>
        <h1 id="title-source">
            Удаление
            <router-link v-bind:to="`/administration/users/${userId}/view`"> пользователя #{{ userId }} </router-link>
        </h1>

        <RequestWrapper v-bind:data="user" v-bind:errorText="userErrorText" v-bind:errorCode="userErrorCode">
            <template v-slot:default>
                <Warning>
                    Помните, что пользователя не обязательно удалять - можно его отключить.<br />
                    При удалении пользователя записи аудита на его идентификатор будут сохранены.
                </Warning>
                <ButtonsBlock>
                    <Button
                        text="Подтверждаю удаление"
                        destructive
                        v-bind:failureText="deleteFailure"
                        v-on:click="performDelete" />
                </ButtonsBlock>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import Warning from '../../../misc/Warning.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import { getAuthorizedRequest, deleteAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'UserDeletePage',
    components: {
        RequestWrapper,
        Warning,
        Button,
        ButtonsBlock,
    },
    data: function() {
        return {
            userId: this.$route.params.id,
            user: undefined,
            userErrorText: undefined,
            userErrorCode: undefined,
            deleteFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/administration/users/${this.userId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.user = data))
                        break
                    default:
                        this.userErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.userErrorText = error))
    },
    methods: {
        performDelete: function(callback) {
            this.deleteFailure = ''

            deleteAuthorizedRequest(`/api/administration/users/${this.userId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            this.$router.push('/administration/users')
                            break
                        default:
                            this.deleteFailure = `Плохой ответ на запрос (${res.status})`
                            callback()
                            break
                    }
                })
                .catch(() => (this.deleteFailure = 'Не удалось выполнить запрос'))
        },
    },
}
</script>