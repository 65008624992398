<template>
    <div class="popup-menu" v-bind:id="popupId">
        <a class="popup-menu-title" v-on:click="switchExpanded">
            <span>{{ title }}</span>
        </a>
        <div class="popup-menu-overlay" v-show="isExpanded" v-on:click="switchExpanded"></div>
        <div class="popup-menu-content" v-bind:id="contentId" v-show="isExpanded">
            <a class="popup-menu-content-title" v-on:click="switchExpanded">
                <span>{{ expandedTitle || title }}</span>
            </a>
            <div class="popup-menu-content-separator"></div>
            <slot />
        </div>
    </div>
</template>

<script>
import { getRandomHexString, getScreenSize } from '../../common.js'

export default {
    name: 'PopupMenu',

    props: {
        title: {
            type: String,
            default: undefined,
        },
        expandedTitle: {
            type: String,
            default: undefined,
        },
    },

    data: function() {
        var id = getRandomHexString(8)

        return {
            popupId: `popup-menu-${id}`,
            contentId: `popup-menu-content-${id}`,
            isExpanded: false,
        }
    },

    mounted: function() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResizeHandler)

            var closers = document.getElementsByClassName('popup-menu-closer')
            Array.from(closers).forEach(closer => closer.addEventListener('click', this.switchExpanded))
        })
    },

    beforeDestroy: function() {
        window.removeEventListener('resize', this.windowResizeHandler)

        var closers = document.getElementsByClassName('popup-menu-closer')
        Array.from(closers).forEach(closer => closer.removeEventListener('click', this.switchExpanded))
    },

    methods: {
        switchExpanded: function() {
            this.isExpanded = !this.isExpanded
            if (this.isExpanded) this.updateContentStyle()
        },

        updateContentStyle: function() {
            var screen = getScreenSize()

            switch (screen) {
                case 'l':
                case 'm':
                    var anchor = document.getElementById(this.popupId)
                    var content = document.getElementById(this.contentId)

                    var anchorBoundingRect = anchor.getBoundingClientRect()
                    var anchorRect = {
                        top: anchorBoundingRect.top + window.scrollY,
                        left: anchorBoundingRect.left + window.scrollX,
                        bottom: anchorBoundingRect.bottom + window.scrollY,
                        right: anchorBoundingRect.right + window.scrollX,
                        height: anchorBoundingRect.height,
                        width: anchorBoundingRect.width,
                    }

                    var anchorCenterX = anchorRect.left + anchorRect.width / 2
                    var windowCenterX = window.innerWidth / 2

                    content.style =
                        anchorCenterX <= windowCenterX
                            ? `top: ${anchorRect.top}px; left: ${anchorRect.left}px; `
                            : `top: ${anchorRect.top}px; right: ${window.innerWidth - anchorRect.right}px`

                    break

                case 's':
                    content = document.getElementById(this.contentId)
                    var top = window.innerHeight / 4
                    content.style = `top: ${top}px; left: 0px; right: 0px;`

                    break
            }
        },

        windowResizeHandler: function() {
            this.isExpanded = false
        },
    },
}
</script>

<style scoped>
.popup-menu {
    display: inline-block;
}

.popup-menu:not(:last-child) {
    display: inline-block;
    margin-right: 10px;
}

.popup-menu-title {
    cursor: pointer;
    padding-right: 22px;
    background-image: url('../../assets/misc/expandable-arrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.popup-menu-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.4);
}

.screen-s .popup-menu-overlay {
    background-color: rgba(0, 0, 0, 0.8);
}

.popup-menu-content {
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 102;
    background-color: #181818;
    box-shadow: 0px 0px 5px #99bade;
}

.screen-s .popup-menu-content {
    position: fixed;
    border-radius: 0px;
}

.popup-menu-content-title {
    display: block;
    padding: 0px 20px;
    color: #ccc;
    text-align: center;
    cursor: pointer;
}

.screen-m .popup-menu-content-title,
.screen-s .popup-menu-content-title,
.popup-menu-content-title:hover {
    background-image: url('../../assets/misc/close-rect.svg');
    background-position: right 50%;
    background-repeat: no-repeat;
    background-size: 14px auto;
}

.popup-menu-content-separator {
    display: block;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #444;
}
</style>
