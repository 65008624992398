<template>
    <div>
        <h1 id="title-source">Инструменты</h1>
        <WrappedContent>
            <SubSection link="/licensing/tools/product-identification" title="Проверка определения продуктов">
                Отображает парсинг и маппинг продуктов, под которые попадает продукт, описанный через параметры
                регистратора.
            </SubSection>
            <SubSection
                link="/licensing/tools/registration-code"
                title="Генератор регистрационных кодов"
                class="access-level-support">
                Создаёт регистрационные коды для продуктов, не добавляя их в базу данных лицензирования.
            </SubSection>
        </WrappedContent>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SubSection from '../../../misc/SubSection.vue'

export default {
    name: 'Tools',
    components: {
        WrappedContent,
        SubSection,
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>