<template>
    <div>
        <h1 id="title-source">Детали пользователя #{{ userId }}</h1>
        <RequestWrapper v-bind:data="user" v-bind:errorText="userErrorText" v-bind:errorCode="userErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Статус">
                        {{ statusToString(user.status) }}
                    </ItemDetail>
                    <ItemDetail name="Роль">
                        {{ roleToString(user.role) }}
                    </ItemDetail>
                    <ItemDetail name="Логин">
                        {{ user.login }}
                    </ItemDetail>
                    <ItemDetail name="E-mail">
                        <EmailAddressLink v-bind:address="user.email" />
                    </ItemDetail>
                    <ItemDetail name="Имя">
                        {{ user.firstName || '&lt; Не установлено &gt;' }}
                    </ItemDetail>
                    <ItemDetail name="Фамилия">
                        {{ user.lastName || '&lt; Не установлена &gt;' }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-admin">
                    <Button
                        text="Отправить приглашение"
                        v-bind:enabled="!isRootAdmin && user.status != 'Disabled'"
                        v-bind:successText="postInvitationSuccess"
                        v-bind:failureText="postInvitationFailure"
                        v-on:click="postInvitation" />
                    <Button
                        text="Отправить ссылку на смену пароля"
                        v-bind:enabled="!isRootAdmin && user.status != 'Disabled'"
                        v-bind:successText="postRecoverySuccess"
                        v-bind:failureText="postRecoveryFailure"
                        v-on:click="postRecovery" />
                    <Button text="Редактировать" v-on:click="$router.push(`/administration/users/${userId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-bind:enabled="!isRootAdmin && !isMe"
                        v-on:click="$router.push(`/administration/users/${userId}/delete`)" />
                </ButtonsBlock>
                <h2>Поиск</h2>
                <ul>
                    <li v-if="user && user.email">
                        <router-link v-bind:to="`/notification/emails?mode=search&recipientEmail=${user.email}`">
                            Письма, отправленные на e-mail этого пользователя
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/administration/audit-entries?mode=search&userId=${userId}`">
                            Записи аудита на этого пользователя
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'
import { userInfo, userStatusToString, userRoleToString } from '../../../../common.js'

export default {
    name: 'UserViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        EmailAddressLink,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            userId: this.$route.params.id,
            user: undefined,
            userErrorText: undefined,
            userErrorCode: undefined,
            postInvitationSuccess: '',
            postInvitationFailure: '',
            postRecoverySuccess: '',
            postRecoveryFailure: '',
        }
    },
    computed: {
        isRootAdmin: function() {
            if (!this.user) return false
            return this.user.login.toLowerCase() == 'admin'
        },
        isMe: function() {
            return this.user.login == userInfo.profile.login
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/administration/users/${this.userId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.user = data))
                        break
                    default:
                        this.userErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.userErrorText = error))
    },
    methods: {
        statusToString: function(status) {
            return userStatusToString(status)
        },
        roleToString: function(role) {
            return userRoleToString(role)
        },
        postInvitation: function(callback) {
            this.postInvitationSuccess = ''
            this.postInvitationFailure = ''

            postAuthorizedRequest(`/api/administration/users/${this.userId}/invitation`)
                .then(res => {
                    switch (res.status) {
                        case 202:
                            this.postInvitationSuccess = 'Приглашение отправлено'
                            break
                        default:
                            this.postInvitationFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .then(callback)
        },
        postRecovery: function(callback) {
            this.postRecoverySuccess = ''
            this.postRecoveryFailure = ''

            postAuthorizedRequest(`/api/administration/users/${this.userId}/recovery`)
                .then(res => {
                    switch (res.status) {
                        case 202:
                            this.postRecoverySuccess = 'Ссылка отправлена'
                            break
                        default:
                            this.postRecoveryFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .then(callback)
        },
    },
}
</script>