<template>
    <div>
        <h1 id="title-source">Репутация</h1>

        <h2>Проверка репутации</h2>
        <WrappedContent>
            <SubSection title="Статус репутации IP-адреса">
                <form>
                    <IpAddressField id="status-ip-address" labelText="Введите IP-адрес:" v-model="statusIpAddress" />
                    <Button
                        text="Проверить"
                        v-bind:useThrobber="false"
                        v-bind:enabled="statusIpAddress != undefined"
                        v-on:click="viewIpReputationStatus" />
                </form>
            </SubSection>
        </WrappedContent>

        <h2>Статистика веб-сессий</h2>
        <WrappedContent>
            <SubSection link="/reputation/stats/asns" title="По ASN">
                Просмотр и поиск статистики веб-сессий, агрегированной по ASN.
            </SubSection>
            <SubSection link="/reputation/stats/asn-blocks" title="По блокам ASN">
                Просмотр и поиск статистики веб-сессий, агрегированной по блокам ASN.
            </SubSection>
            <SubSection link="/reputation/stats/static-blocks" title="По статичным блокам">
                Просмотр и поиск статистики веб-сессий, агрегированной по статичным блокам.
            </SubSection>
            <SubSection link="/reputation/stats/ips" title="По IP-адресам">
                Просмотр и поиск статистики веб-сессий по отдельным IP-адресам.
            </SubSection>
        </WrappedContent>

        <h2>Списки</h2>
        <WrappedContent>
            <SubSection link="/reputation/lists/asns/white" title="Белый список ASN">
                Просмотр и редактирование списка ASN, для IP-адресов которых не должен блокироваться доступ на сайты.
            </SubSection>
            <SubSection link="/reputation/lists/asns/black" title="Чёрный список ASN">
                Просмотр и редактирование списка ASN, для IP-адресов которых должен блокироваться доступ на сайты вне
                зависимости от статистики.
            </SubSection>
            <SubSection link="/reputation/lists/ips/white" title="Белый список IP-адресов">
                Просмотр и редактирование диапазонов IP-адресов, для которых не должен блокироваться доступ на сайты.
            </SubSection>
            <SubSection link="/reputation/lists/ips/black" title="Чёрный список IP-адресов">
                Просмотр и редактирование диапазонов IP-адресов, для которых должен блокироваться доступ на сайты вне
                зависимости от статистики.
            </SubSection>
        </WrappedContent>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import SubSection from '../../misc/SubSection.vue'
import IpAddressField from '../../inputs/IpAddressField.vue'
import Button from '../../inputs/Button.vue'

export default {
    name: 'ReputationPage',
    components: {
        WrappedContent,
        SubSection,
        IpAddressField,
        Button,
    },
    data: function() {
        return {
            statusIpAddress: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
    methods: {
        viewIpReputationStatus: function() {
            this.$router.push(`/reputation/statuses/ips/${this.statusIpAddress}`)
        },
    },
}
</script>