<template>
    <div>
        <ItemsList>
            <div class="item" v-for="order in orders" v-bind:key="order.orderId">
                <div class="item-date">
                    <DateTime v-bind:dt="order.added" />
                </div>
                <div class="item-details">
                    <div class="order-test" v-if="order.isTest">Тестовый ордер</div>
                    <span class="item-title">
                        {{ getOrderTitle(order) }}
                        <span v-if="order.status != 'Normal'">
                            <OrderStatus v-bind:order="order" lowerCase />
                        </span>
                    </span>
                    <br />
                    Клиент:
                    <router-link v-bind:to="`/licensing/customers/${order.customer.customerId}/view`">
                        {{ order.customer.email }}
                    </router-link>
                    <br />
                    Продукт:
                    <router-link v-bind:to="`/licensing/products/${order.product.productId}/view`">
                        {{ order.product.name }}
                    </router-link>
                    <span class="order-licenses-quantity" title="Количество лицензий" v-if="order.licensesQuantity > 1">
                        × {{ order.licensesQuantity }}
                    </span>
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/orders/${order.orderId}/view`">Подробнее</router-link>
                    <router-link v-bind:to="`/licensing/orders/${order.orderId}/edit`" class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link v-bind:to="`/licensing/orders/${order.orderId}/delete`" class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import DateTime from '../../../misc/DateTime.vue'
import OrderStatus from './OrderStatus.vue'

export default {
    name: 'OrdersList',
    components: {
        ItemsList,
        DateTime,
        OrderStatus,
    },
    props: {
        orders: {
            type: Array,
            default: undefined,
        },
    },
    methods: {
        getOrderTitle: function(order) {
            if (order.registrator == undefined) return `#${order.orderId}`
            return `${order.registrator.name}-${order.registratorOrderId}`
        },
    },
}
</script>

<style scoped>
.order-test {
    color: #b772cf;
}

.order-licenses-quantity {
    color: #12b056;
}
</style>