<template>
    <div>
        <h1 id="title-source">Генератор регистрационных кодов</h1>

        <Note>
            Генератор предназначен для тестирования, сгенерированные тут коды нигде не сохраняются. Если вам необходимо
            вручную создать регистрационный код для передачи клиенту, то используйте разделы
            <router-link to="/licensing/customers">клиенты</router-link>,
            <router-link to="/licensing/orders">ордеры</router-link> и
            <router-link to="/licensing/licenses">лицензии</router-link>.
        </Note>

        <form>
            <WrappedContent>
                <TextField
                    id="tool-reg-code-purchased"
                    labelText="Дата и время покупки:"
                    required
                    v-bind:errorText="purchasedValidationError"
                    v-model="request.purchased" />
                <TextField
                    id="tool-reg-code-customer-email"
                    labelText="E-mail клиента:"
                    required
                    v-bind:errorText="customerEmailValidationError"
                    v-model="request.customerEmail" />
                <TextField id="tool-reg-code-customer-name" labelText="Имя клиента:" v-model="request.customerName" />
                <TextField
                    id="tool-reg-code-customer-company"
                    labelText="Компания клиента:"
                    v-model="request.customerCompany" />
                <SearchField
                    id="tool-reg-code-product"
                    labelText="Продукт:"
                    required
                    v-bind:errorText="productValidationError"
                    textKey="name"
                    valueKey="productId"
                    v-bind:getInfoUrl="`/api/licensing/products/${request.productId}`"
                    searchUrl="/api/licensing/products/search"
                    searchKey="namePartial"
                    v-model="request.productId" />
                <SearchField
                    id="tool-reg-code-license-type"
                    labelText="Тип лицензии:"
                    required
                    v-bind:errorText="licenseTypeValidationError"
                    textKey="name"
                    valueKey="licenseTypeId"
                    v-bind:getInfoUrl="`/api/licensing/license-types/${request.licenseTypeId}`"
                    searchUrl="/api/licensing/license-types/search"
                    searchKey="namePartial"
                    v-model="request.licenseTypeId" />
                <SearchField
                    id="tool-reg-code-registrator"
                    labelText="Регистратор:"
                    textKey="name"
                    valueKey="registratorId"
                    v-bind:getInfoUrl="`/api/licensing/registrators/${request.registratorId}`"
                    searchUrl="/api/licensing/registrators/search"
                    searchKey="namePartial"
                    v-model="request.registratorId" />
                <TextField
                    id="tool-reg-code-registrator-order-id"
                    labelText="Идентификатор у регистратора:"
                    v-model="request.registratorOrderId" />
            </WrappedContent>
            <ButtonsBlock>
                <Button text="Генерировать" v-on:click="performRequest" v-bind:failureText="requestFailure" />
            </ButtonsBlock>

            <h2>Результат</h2>
            <div v-if="result">
                <b>Дата и время истечения кода:</b> {{ result.expires || 'никогда' }}
                <ExpandableContent summaryText="Регистрационный код" expanded>
                    {{ result.registrationCode }}
                </ExpandableContent>
                <ExpandableContent summaryText="Встроенные в код данные" style="white-space: pre-line">
                    {{ result.embeddedData }}
                </ExpandableContent>
            </div>
            <div v-else>
                <i>Нажми на кнопку - получишь результат.</i>
            </div>
        </form>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Note from '../../../misc/Note.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import SearchField from '../../../inputs/SearchField.vue'
import TextField from '../../../inputs/TextField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import ExpandableContent from '../../../misc/ExpandableContent.vue'
import { postAuthorizedRequest } from '../../../../common.js'
import { isValidDateTime, validateEmail } from '../../../../common.js'

export default {
    name: 'ToolRegistrationCode',
    components: {
        Note,
        WrappedContent,
        SearchField,
        TextField,
        Button,
        ButtonsBlock,
        ExpandableContent,
    },
    data: function() {
        return {
            purchasedValidationError: '',
            customerEmailValidationError: '',
            productValidationError: '',
            licenseTypeValidationError: '',

            request: {
                purchased: new Date().toISOString(),
                customerEmail: 'customer@test.test',
                customerName: undefined,
                customerCompany: undefined,
                productId: undefined,
                licenseTypeId: undefined,
                registratorId: undefined,
                registratorOrderId: '',
            },
            requestFailure: '',
            result: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
    methods: {
        performRequest: function(callback) {
            this.requestFailure = ''
            this.request.purchased = this.request.purchased.trim()

            var validationOk = true

            if (!this.request.purchased) {
                this.purchasedValidationError = 'Введите дату и время покупки'
                validationOk = false
            } else if (!isValidDateTime(this.request.purchased)) {
                this.purchasedValidationError = 'Формат: YYYY-MM-DDThh:mm:ssZ'
                validationOk = false
            } else {
                this.purchasedValidationError = ''
            }

            this.customerEmailValidationError = validateEmail(this.request.customerEmail)
            if (this.customerEmailValidationError) {
                validationOk = false
            }

            if (!this.request.productId) {
                this.productValidationError = 'Укажите продукт'
                validationOk = false
            } else {
                this.productValidationError = ''
            }

            if (!this.request.licenseTypeId) {
                this.licenseTypeValidationError = 'Укажите тип лицензии'
                validationOk = false
            } else {
                this.licenseTypeValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            postAuthorizedRequest('/api/licensing/tools/registration-code', this.request)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.result = data))
                            break
                        default:
                            this.requestFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.requestFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },
    },
}
</script>