<template>
    <div class="notifications-container">
        <div v-show="errorText != ''" class="notification-error">{{ errorText }}</div>
    </div>
</template>

<script>
export default {
    name: 'FieldNotifications',
    props: {
        errorText: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped>
.notifications-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 18px;
    font-size: 0.8em;
}

.notification-error {
    margin-right: 5px;
    color: #f99;
}
</style>
