<template>
    <div id="menu" v-on:click="$emit('menuClick')">
        <MenuSection title="Лицензирование" icon="licensing.svg" v-bind:items="licensingItems" />
        <MenuSection title="Уведомления" icon="notification.svg" v-bind:items="notificationItems" />
        <MenuSection title="Защита" icon="defense.svg" v-bind:items="defenseItems" />
        <MenuSection title="Другое" icon="misc.svg" v-bind:items="miscItems" class="access-level-admin" />
        <MenuSection
            title="Управление"
            icon="administration.svg"
            v-bind:items="administeringItems"
            class="access-level-admin" />
        <MenuSection id="menu-me" title="Персональное" icon="personal.svg" v-bind:items="meItems" />
        <div class="empty-space" />
    </div>
</template>

<script>
import MenuSection from './MenuSection.vue'

export default {
    name: 'Menu',
    components: {
        MenuSection,
    },
    data: function() {
        return {
            licensingItems: [
                {
                    url: '/licensing/orders',
                    text: 'Ордеры',
                },
                {
                    url: '/licensing/licenses',
                    text: 'Лицензии',
                },
                {
                    url: '/licensing/customers',
                    text: 'Клиенты',
                },
                {
                    url: '/licensing/license-types',
                    text: 'Типы лицензий',
                },
                {
                    url: '/licensing/products',
                    text: 'Продукты',
                },
                {
                    url: '/licensing/product-domains',
                    text: 'Домены продуктов',
                },
                {
                    url: '/licensing/product-mappings',
                    text: 'Маппинги продуктов',
                },
                {
                    url: '/licensing/product-parsings',
                    text: 'Парсинги продуктов',
                },
                {
                    url: '/licensing/registrators',
                    text: 'Регистраторы',
                },
                {
                    url: '/licensing/tools',
                    text: 'Инструменты',
                },
            ],
            notificationItems: [
                {
                    url: '/notification/emails',
                    text: 'E-mail',
                },
            ],
            defenseItems: [
                {
                    url: '/telemetry/web-sessions',
                    text: 'Веб-телеметрия',
                },
                {
                    url: '/ipinfo',
                    text: 'IP-информация',
                },
                {
                    url: '/reputation',
                    text: 'Репутация',
                },
            ],
            miscItems: [
                {
                    url: '/misc/reporters',
                    text: 'Отчёты',
                },
                {
                    url: '/misc/maintainers',
                    text: 'Фоновые службы',
                },
            ],
            administeringItems: [
                {
                    url: '/administration/users',
                    text: 'Пользователи',
                },
                {
                    url: '/administration/audit-entries',
                    text: 'Записи аудита',
                },
            ],
            meItems: [
                {
                    url: '/me',
                    text: 'Мой профиль',
                },
            ],
        }
    },
}
</script>

<style scoped>
#menu {
    flex-grow: 0;
    flex-shrink: 0;
    width: 250px;
    margin-right: 10px;
    background-color: #222;
}

.screen-m #menu,
.screen-s #menu {
    position: fixed;
    right: 0px;
    top: 70px;
    bottom: 0px;
    margin-right: 0px;
    background-color: #111;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.75);
}

.screen-l #menu-me {
    display: none;
}

.empty-space {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
}
</style>