<template>
    <div>
        <ItemsList>
            <div class="item" v-for="asnStat in asnStats" v-bind:key="asnStat.asnStatId">
                <div class="item-details">
                    <span class="item-title">ASN {{ asnStat.asnId }}</span>
                    <div class="reputation-widgets">
                        <ListsImpactsWidget v-bind:obj="asnStat" />
                        <StatsWidget v-bind:stat="asnStat" />
                    </div>
                </div>
                <div class="item-links">
                    <AsnPopupMenu v-bind:asnId="asnStat.asnId" title="Меню" />
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import StatsWidget from './StatsWidget.vue'
import ListsImpactsWidget from './ListsImpactsWidget.vue'
import AsnPopupMenu from '../AsnPopupMenu.vue'

export default {
    name: 'AsnStatsList',
    components: {
        ItemsList,
        StatsWidget,
        ListsImpactsWidget,
        AsnPopupMenu,
    },
    props: {
        asnStats: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.item-title {
    margin-right: 5px;
}

.reputation-widgets {
    margin-top: 5px;
}
</style>