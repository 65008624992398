<template>
    <div>
        <h1 v-if="isCreateMode" id="title-source">Создание парсинга продуктов</h1>
        <h1 v-else id="title-source">
            Редактирование
            <router-link v-bind:to="`/licensing/product-parsings/${productParsingId}/view`"
                >парсинга продуктов #{{ productParsingId }}</router-link
            >
        </h1>

        <RequestWrapper
            v-bind:data="productParsing"
            v-bind:errorText="productParsingErrorText"
            v-bind:errorCode="productParsingErrorCode">
            <template v-slot:default>
                <form>
                    <WrappedContent>
                        <TextField
                            id="product-parsing-name"
                            labelText="Название:"
                            required
                            v-bind:errorText="nameValidationError"
                            v-model="productParsing.name" />
                        <NumberField
                            id="product-parsing-priority"
                            v-bind:min="0"
                            v-bind:max="999"
                            labelText="Приоритет:"
                            required
                            v-bind:errorText="priorityValidationError"
                            v-model="productParsing.priority" />
                        <SearchField
                            id="product-parsing-registrator"
                            labelText="Регистратор:"
                            placeholderText="Любой регистратор"
                            textKey="name"
                            valueKey="registratorId"
                            v-bind:getInfoUrl="`/api/licensing/registrators/${productParsing.registratorId}`"
                            searchUrl="/api/licensing/registrators/search"
                            searchKey="namePartial"
                            v-model="productParsing.registratorId" />
                    </WrappedContent>
                    <TextField
                        id="product-parsing-registrator-product-name-regex"
                        labelText="Паттерн названия продукта у регистратора:"
                        placeholderText="Введите регулярное выражение"
                        required
                        v-bind:errorText="registratorProductNameRegexValidationError"
                        v-model="productParsing.registratorProductNameRegex" />
                    <TextField
                        id="product-parsing-product-name-expression"
                        labelText="Выражение названия продукта:"
                        placeholderText="Используйте {N} для ссылок на группы паттерна"
                        required
                        v-bind:errorText="productNameExpressionValidationError"
                        v-model="productParsing.productNameExpression" />
                    <TextField
                        id="product-parsing-license-type-name-expression"
                        labelText="Выражение названия типа лицензии:"
                        placeholderText="Используйте {N} для ссылок на группы паттерна"
                        required
                        v-bind:errorText="licenseTypeNameExpressionValidationError"
                        v-model="productParsing.licenseTypeNameExpression" />
                    <ButtonsBlock>
                        <Button
                            text="Сохранить"
                            v-on:click="performSave"
                            v-bind:successText="saveRequestSuccess"
                            v-bind:failureText="saveRequestFailure" />
                        <Button
                            v-if="!isCreateMode"
                            text="Удалить"
                            destructive
                            v-on:click="$router.push(`/licensing/product-parsings/${productParsingId}/delete`)" />
                    </ButtonsBlock>
                </form>

                <h2>Тестирование</h2>
                <form>
                    <TextField
                        id="product-parsing-test-registrator-product-name"
                        required
                        labelText="Название продукта у регистратора:"
                        placeholderText="Введите для тестирования"
                        v-model="testRegistratorProductName" />
                    <div class="parsing-test-result" v-show="this.testResult">
                        <b>Результат проверки:</b>
                        <br />
                        {{ testResult }}
                    </div>
                    <ButtonsBlock>
                        <Button
                            text="Проверить"
                            v-bind:useThrobber="false"
                            v-on:click="performTest"
                            v-bind:failureText="testFailure" />
                    </ButtonsBlock>
                </form>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import TextField from '../../../inputs/TextField.vue'
import NumberField from '../../../inputs/NumberField.vue'
import SearchField from '../../../inputs/SearchField.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest, putAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductParsingEditPage',
    components: {
        WrappedContent,
        TextField,
        NumberField,
        SearchField,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productParsingId: this.$route.params.id,
            nameValidationError: '',
            priorityValidationError: '',
            registratorProductNameRegexValidationError: '',
            productNameExpressionValidationError: '',
            licenseTypeNameExpressionValidationError: '',
            productParsing: undefined,
            productParsingErrorText: undefined,
            productParsingErrorCode: undefined,
            saveRequestSuccess: '',
            saveRequestFailure: '',
            testRegistratorProductName: '',
            testFailure: '',
            testResult: '',
        }
    },
    computed: {
        isCreateMode: function() {
            return this.productParsingId == undefined
        },
    },
    created: function() {
        this.$emit('update:layout', Layout)

        if (this.isCreateMode) {
            this.productParsing = {
                name: '',
                priority: undefined,
                registratorId: undefined,
                registratorProductNameRegex: '',
                productNameExpression: '',
                licenseTypeNameExpression: '',
            }
        } else {
            getAuthorizedRequest(`/api/licensing/product-parsings/${this.productParsingId}`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => {
                                this.productParsing = {
                                    name: data.name,
                                    priority: data.priority,
                                    registratorId: data.registrator && data.registrator.registratorId,
                                    registratorProductNameRegex: data.registratorProductNameRegex,
                                    productNameExpression: data.productNameExpression,
                                    licenseTypeNameExpression: data.licenseTypeNameExpression,
                                }
                            })
                            break
                        default:
                            this.productParsingErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.productParsingErrorText = error))
        }
    },
    methods: {
        performSave: function(callback) {
            this.saveRequestSuccess = ''
            this.saveRequestFailure = ''
            this.productParsing.name = this.productParsing.name.trim()
            if (!this.productParsing.registratorId) this.productParsing.registratorId = undefined
            this.productParsing.registratorProductNameRegex = this.productParsing.registratorProductNameRegex.trim()
            this.productParsing.productNameExpression = this.productParsing.productNameExpression.trim()
            this.productParsing.licenseTypeNameExpression = this.productParsing.licenseTypeNameExpression.trim()

            var validationOk = true

            if (!this.productParsing.name) {
                this.nameValidationError = 'Введите название'
                validationOk = false
            } else {
                this.nameValidationError = ''
            }

            if (!this.productParsing.priority) {
                this.priorityValidationError = 'Введите приоритет'
                validationOk = false
            } else {
                this.priorityValidationError = ''
            }

            if (!this.productParsing.registratorProductNameRegex) {
                this.registratorProductNameRegexValidationError = 'Введите RegExp'
                validationOk = false
            } else {
                try {
                    new RegExp(this.productParsing.registratorProductNameRegex)
                    this.registratorProductNameRegexValidationError = ''
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        this.registratorProductNameRegexValidationError = 'Ошибка синтаксиса'
                        validationOk = false
                    } else {
                        this.registratorProductNameRegexValidationError = e.name
                        console.error(e.message)
                        validationOk = false
                    }
                }
            }

            if (!this.productParsing.productNameExpression) {
                this.productNameExpressionValidationError = 'Введите выражение'
                validationOk = false
            } else {
                this.productNameExpressionValidationError = ''
            }

            if (!this.productParsing.licenseTypeNameExpression) {
                this.licenseTypeNameExpressionValidationError = 'Введите выражение'
                validationOk = false
            } else {
                this.licenseTypeNameExpressionValidationError = ''
            }

            if (!validationOk) {
                callback()
                return
            }

            if (this.isCreateMode) {
                postAuthorizedRequest('/api/licensing/product-parsings', this.productParsing)
                    .then(res => {
                        switch (res.status) {
                            case 201:
                                res.json().then(data =>
                                    this.$router.push(`/licensing/product-parsings/${data.productParsingId}/view`)
                                )
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            } else {
                putAuthorizedRequest(`/api/licensing/product-parsings/${this.productParsingId}`, this.productParsing)
                    .then(res => {
                        switch (res.status) {
                            case 200:
                                this.saveRequestSuccess = 'Данные маппинга продуктов сохранены'
                                break
                            default:
                                this.saveRequestFailure = `Плохой ответ на запрос (${res.status})`
                                break
                        }
                    })
                    .catch(() => (this.saveRequestFailure = 'Не удалось выполнить запрос'))
                    .then(callback)
            }
        },

        performTest: function() {
            this.testFailure = ''
            this.testResult = ''

            if (!this.productParsing.registratorProductNameRegex) {
                this.testFailure = 'Не задан паттерн названия продукта'
                return
            } else if (!this.productParsing.productNameExpression) {
                this.testFailure = 'Не задано выражение названия продукта'
                return
            } else if (!this.productParsing.licenseTypeNameExpression) {
                this.testFailure = 'Не задано выражение названия типа лицензии'
                return
            } else if (!this.testRegistratorProductName) {
                this.testFailure = 'Не задано название продукта у регистратора для проверки'
                return
            }

            try {
                var regexp = new RegExp(this.productParsing.registratorProductNameRegex.trim())

                var match = this.testRegistratorProductName.match(regexp)
                if (!match) {
                    this.testResult = 'Нет совпадения с паттерном'
                    return
                }

                var productName = this.productParsing.productNameExpression.trim()
                var licenseTypeName = this.productParsing.licenseTypeNameExpression.trim()
                for (var i = 1; i < match.length; i++) {
                    productName = productName.replace(`{${i}}`, match[i])
                    licenseTypeName = licenseTypeName.replace(`{${i}}`, match[i])
                }

                this.testResult =
                    `Вычисленное название продукта: "${productName}"\r\n` +
                    `Вычисленное название типа лицензии: "${licenseTypeName}"`
            } catch (e) {
                if (e instanceof SyntaxError) {
                    this.testFailure = 'Ошибка синтаксиса в паттерне'
                } else {
                    this.testFailure = e.name
                    console.error(e.message)
                }
            }
        },
    },
}
</script>

<style scoped>
.parsing-test-result {
    white-space: pre-line;
}
</style>