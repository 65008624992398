<template>
    <div class="form-field ip-address-field" v-bind:class="{ 'ip-address-field-disabled': !enabled }">
        <label v-bind:for="id">{{ labelText }}<span class="ip-address-field-required" v-if="required"> *</span></label>
        <div class="ip-address-field-main-container">
            <div class="ip-address-field-input-container">
                <input
                    type="text"
                    class="ip-address-field-input"
                    v-bind:class="computedClass"
                    v-bind:id="id"
                    v-bind:name="name || id"
                    maxlength="39"
                    v-bind:placeholder="placeholderText"
                    v-bind:disabled="!enabled"
                    v-model="currentValue"
                    v-on:input="inputHandler($event.target.value)" />
                <div v-if="enabled" class="ip-address-field-version">
                    {{ validIpVersion }}
                </div>
            </div>
        </div>
        <FieldNotifications v-bind:errorText="errorText" />
    </div>
</template>

<script>
import FieldNotifications from './FieldNotifications.vue'
import { Address4, Address6 } from 'ip-address'

export default {
    name: 'IpAddressField',

    components: {
        FieldNotifications,
    },

    props: {
        value: String,
        name: String,
        id: String,
        required: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        labelText: String,
        placeholderText: {
            type: String,
            default: 'Введите IP-адрес',
        },
        errorText: {
            type: String,
            default: '',
        },
    },

    watch: {
        value: function(newValue) {
            if (!this.isEmitting) this.currentValue = newValue
            this.isEmitting = false
        },
    },

    data: function() {
        return {
            currentValue: this.value,
            isEmitting: false,
        }
    },

    computed: {
        computedClass: function() {
            return {
                'ip-address-field-input-invalid': !this.isValid,
                'ip-address-field-input-empty': !this.currentValue,
            }
        },

        maxLength: function() {
            return this.ipVersion == 'v4' ? 15 : 39
        },

        validIpVersion: function() {
            if (Address4.isValid(this.currentValue)) return 'v4'
            else if (Address6.isValid(this.currentValue)) return 'v6'
            return undefined
        },

        isValid: function() {
            return this.validIpVersion != undefined
        },
    },

    methods: {
        inputHandler: function(value) {
            this.isEmitting = true
            this.$emit('input', this.isValid ? value : undefined)
        },
    },
}
</script>

<style scoped>
label {
    display: block;
    margin-bottom: 4px;
}

.ip-address-field:not(.ip-address-field-disabled) .ip-address-field-required {
    color: #dda;
}

.ip-address-field-main-container {
    display: flex;
    align-items: flex-start;
}

.ip-address-field-input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border: 2px solid #41556b;
    border-radius: 6px;
    background-color: #131a21;
}

.ip-address-field-input-container:focus-within,
.ip-address-field:not(.ip-address-field-disabled) .ip-address-field-input-container:hover {
    border-color: #7491b0;
}

input {
    padding: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #a2bfdb;
    font: inherit;
}

input::placeholder {
    font-style: italic;
    color: #777;
}

.ip-address-field-input {
    width: calc(100% - 18px);
}

.ip-address-field-input-invalid:not(.ip-address-field-input-empty) {
    text-decoration: underline wavy rgba(255, 255, 0, 0.5) 1px;
}

.ip-address-field-version {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    color: #ddd;
    opacity: 0.5;
    text-align: center;
    border-radius: 4px;
}

.ip-address-field-disabled {
    color: #555;
}

.ip-address-field-disabled .ip-address-field-input-container {
    border-color: #2c394a;
    background-color: #232e3b;
}

.ip-address-field-disabled input {
    color: #465a70;
}

/* костыль, чтобы автозаполнение в Chrome не меняло радикально стиль поля */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2bfdb;
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
}
</style>
