<template>
    <div>
        <h1 id="title-source">Детали домена продуктов #{{ productDomainId }}</h1>

        <RequestWrapper
            v-bind:data="productDomain"
            v-bind:errorText="productDomainErrorText"
            v-bind:errorCode="productDomainErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Название">
                        {{ productDomain.name }}
                    </ItemDetail>
                    <ItemDetail name="Название компании">
                        {{ productDomain.companyName }}
                    </ItemDetail>
                    <ItemDetail name="Сайт">
                        <ExternalLink v-bind:url="productDomain.site" />
                    </ItemDetail>
                    <ItemDetail name="Страница тех. поддержки">
                        <ExternalLink v-bind:url="productDomain.supportLink" />
                    </ItemDetail>
                    <ItemDetail name="E-mail отправителя уведомлений">
                        <EmailAddressLink v-bind:address="productDomain.notificationEmail" />
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Редактировать"
                        v-on:click="$router.push(`/licensing/product-domains/${productDomainId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/product-domains/${productDomainId}/delete`)" />
                </ButtonsBlock>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/orders?mode=search&productDomainId=${productDomainId}`">
                            Ордеры продуктов этого домена
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&productDomainId=${productDomainId}`">
                            Лицензии, выписанные на ордеры продуктов этого домена
                        </router-link>
                    </li>
                    <li>
                        <router-link v-bind:to="`/licensing/products?mode=search&productDomainId=${productDomainId}`">
                            Продукты этого домена
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            v-bind:to="`/licensing/product-mappings?mode=search&productDomainId=${productDomainId}`">
                            Маппинги продуктов, использующие продукты этого домена
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/product-domains/${productDomainId}`">
                            Записи аудита на этот домен продуктов
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import EmailAddressLink from '../../../misc/EmailAddressLink.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'ProductDomainViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        ExternalLink,
        EmailAddressLink,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            productDomainId: this.$route.params.id,
            productDomain: undefined,
            productDomainErrorText: undefined,
            productDomainErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/product-domains/${this.productDomainId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.productDomain = data))
                        break
                    default:
                        this.productDomainErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.productDomainErrorText = error))
    },
}
</script>