<template>
    <div>
        <div
            id="layout"
            v-if="userInfo.profile != undefined"
            v-bind:class="'role-' + userInfo.profile.role.toLowerCase()">
            <div id="layout-container">
                <Header v-on:headerMenuClick="headerMenuClickHandler" />
                <div id="layout-main">
                    <Menu v-show="showMenu" v-on:menuClick="menuClickHandler" />
                    <Content v-bind:class="{ 'mobile-menu-overlayed': showMobileMenu }">
                        <Environment />
                        <slot />
                    </Content>
                </div>
                <Footer />
                <div id="mobile-menu-overlay" v-show="showMobileMenu" v-on:click="mobileMenuOverlayClickHandler" />
            </div>
        </div>
    </div>
</template>

<script>
import Environment from './Environment.vue'
import Header from './Header.vue'
import Menu from './Menu.vue'
import Content from './Content.vue'
import Footer from './Footer.vue'
import { userInfo, updateTitle, getScreenSize } from '../../common.js'

export default {
    name: 'Layout',
    components: {
        Environment,
        Header,
        Menu,
        Content,
        Footer,
    },
    data: function() {
        return {
            userInfo: userInfo,
            showMenu: true,
            showMobileMenu: false,
        }
    },
    created: function() {
        userInfo.load()
    },
    mounted: function() {
        updateTitle()
        this.updateShowMenu()
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResizeHandler)
        })
    },
    beforeDestroy: function() {
        window.removeEventListener('resize', this.windowResizeHandler)
    },
    updated: function() {
        updateTitle()
    },
    methods: {
        updateShowMenu: function() {
            this.showMenu = getScreenSize() == 'l' ? true : this.showMobileMenu
        },
        windowResizeHandler: function() {
            this.showMobileMenu = false
            this.updateShowMenu()
        },
        headerMenuClickHandler: function() {
            this.showMobileMenu = !this.showMobileMenu
            this.updateShowMenu()
        },
        menuClickHandler: function() {
            this.showMobileMenu = false
            this.updateShowMenu()
        },
        mobileMenuOverlayClickHandler: function() {
            this.showMobileMenu = false
            this.updateShowMenu()
        },
    },
}
</script>

<style>
#layout {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    /* чтобы в контенте работали скролл-бары при overflow */
    overflow: hidden;
}

#layout-container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 100vh;
    /* чтобы в контенте работали скролл-бары при overflow */
    overflow: hidden;
}

#layout-main {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.screen-m #layout-main,
.screen-s #layout-main {
    flex-direction: column;
}

#layout.role-viewer .access-level-admin,
#layout.role-viewer .access-level-support,
#layout.role-support .access-level-admin {
    display: none;
}

#mobile-menu-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 200;
}
</style>