<template>
    <span>
        <a v-if="url" v-bind:href="url" class="external-link" target="_blank">
            {{ text || url.substr(0, maxLength) }}<span v-if="url.length > maxLength">...</span>
        </a>
        <span v-else> &lt; Нет ссылки &gt; </span>
    </span>
</template>

<script>
export default {
    name: 'ExternalLink',
    props: {
        url: String,
        text: String,
        maxLength: {
            type: Number,
            default: 10000,
        },
    },
}
</script>

<style scoped>
.external-link {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 80%;
    background-image: url('../../assets/misc/external-link.svg');
}
</style>
