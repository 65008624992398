<template>
    <div>
        <ItemsList>
            <div class="item" v-for="productParsing in productParsings" v-bind:key="productParsing.productParsingId">
                <div class="item-details">
                    <span class="parsing-priority" title="Приоритет">⇅ {{ productParsing.priority }}</span>
                    <span class="item-title">{{ productParsing.name }}</span>
                    <br />
                    <span v-if="productParsing.registrator">
                        Регистратор:
                        <router-link
                            v-bind:to="`/licensing/registrators/${productParsing.registrator.registratorId}/view`">
                            {{ productParsing.registrator.name }}
                        </router-link>
                        <br />
                    </span>
                    Паттерн:
                    {{ productParsing.registratorProductNameRegex }}
                </div>
                <div class="item-links">
                    <router-link v-bind:to="`/licensing/product-parsings/${productParsing.productParsingId}/view`"
                        >Подробнее</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-parsings/${productParsing.productParsingId}/edit`"
                        class="access-level-support"
                        >Редактировать</router-link
                    >
                    <router-link
                        v-bind:to="`/licensing/product-parsings/${productParsing.productParsingId}/delete`"
                        class="access-level-support"
                        >Удалить</router-link
                    >
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'

export default {
    name: 'ProductParsingsList',
    components: {
        ItemsList,
    },
    props: {
        productParsings: {
            type: Array,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.parsing-priority {
    padding: 1px 8px;
    margin-right: 10px;
    font-weight: bold;
    color: #8cbf52;
    border: 1px solid #8cbf52;
    box-sizing: border-box;
}
</style>