<template>
    <div>
        <h1 id="title-source">Статус репутации IP-адреса {{ ipAddress }}</h1>

        <RequestWrapper v-bind:data="status" v-bind:errorText="statusErrorText" v-bind:errorCode="statusErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="ASN">
                        <AsnPopupMenu v-if="status.asnId" v-bind:asnId="status.asnId" />
                        <span v-else> &lt; Неизвестен &gt; </span>
                    </ItemDetail>
                    <ItemDetail name="Блок ASN">
                        <AsnBlockPopupMenu
                            v-if="status.asnBlock"
                            v-bind:startIp="status.asnBlock.startIpAddress"
                            v-bind:endIp="status.asnBlock.endIpAddress" />
                        <span v-else> &lt; Неизвестен &gt; </span>
                    </ItemDetail>
                    <ItemDetail name="Статичный блок">
                        <StaticBlockPopupMenu
                            v-bind:startIp="status.staticBlock.startIpAddress"
                            v-bind:endIp="status.staticBlock.endIpAddress" />
                    </ItemDetail>
                    <ItemDetail name="Блок из белого списка" v-if="status.whitelistedBlock">
                        {{ status.whitelistedBlock.startIpAddress }} – {{ status.whitelistedBlock.endIpAddress }}
                    </ItemDetail>
                    <ItemDetail name="Блок из чёрного списка" v-if="status.blacklistedBlock">
                        {{ status.blacklistedBlock.startIpAddress }} – {{ status.blacklistedBlock.endIpAddress }}
                    </ItemDetail>
                </WrappedContent>

                <h2>Флаги статуса репутации</h2>
                <div v-if="status.flags && status.flags.length > 0">
                    <div v-for="flag in status.flags" v-bind:key="flag">
                        <ReputationFlagWidget v-bind:flag="flag" showScope />
                    </div>
                </div>
                <div v-else>
                    <i>Флаги отсутствуют.</i>
                </div>

                <IpAddressMenu v-bind:ip="ipAddress" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import ReputationFlagWidget from '../ReputationFlagWidget.vue'
import AsnPopupMenu from '../AsnPopupMenu.vue'
import AsnBlockPopupMenu from '../AsnBlockPopupMenu.vue'
import StaticBlockPopupMenu from '../StaticBlockPopupMenu.vue'
import IpAddressMenu from '../IpAddressMenu.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'IpAddressStatusViewPage',

    components: {
        WrappedContent,
        ItemDetail,
        ReputationFlagWidget,
        AsnPopupMenu,
        AsnBlockPopupMenu,
        StaticBlockPopupMenu,
        IpAddressMenu,
        RequestWrapper,
    },

    data: function() {
        return {
            ipAddress: this.$route.params.ip,
            status: undefined,
            statusErrorText: undefined,
            statusErrorCode: undefined,
        }
    },

    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/reputation/statuses/ips/${this.ipAddress}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.status = data))
                        break
                    default:
                        this.statusErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.statusErrorText = error))
    },

    methods: {
        hasStatusFlag: function(flag) {
            return this.status.statusFlags.indexOf(flag) >= 0
        },

        getStatusClasses: function(flag, isPositive) {
            return {
                'ip-status-positive': isPositive,
                'ip-status-negative': !isPositive,
                'ip-status-active': this.hasStatusFlag(flag),
            }
        },

        hasStatusFlagToString: function(flag) {
            return this.hasStatusFlag(flag) ? 'Да' : 'Нет'
        },
    },
}
</script>

<style scoped>
.ip-status-flags {
    margin-bottom: 20px;
    border-spacing: 0px;
    border-collapse: collapse;
}

.ip-status-flags td {
    padding: 2px 10px;
}

.ip-status-flags tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.ip-status-flags td.ip-status-flag-value {
    color: #eee;
}

.ip-status-flags tr.ip-status-active.ip-status-positive td.ip-status-flag-value {
    color: #2a6;
}

.ip-status-flags tr.ip-status-active.ip-status-negative td.ip-status-flag-value {
    color: #d99;
}
</style>