<template>
    <div>
        <h1 id="title-source">Сравнение отпечатков веб-сессий</h1>
        <div v-if="compareAvailable">
            <RequestWrapper
                v-bind:data="compareResponse"
                v-bind:errorText="compareResponseErrorText"
                v-bind:errorCode="compareResponseErrorCode">
                <template v-slot:default>
                    <Warning v-if="compareResponse.notFoundSessionIds && compareResponse.notFoundSessionIds.length > 0">
                        Некоторые веб-сессии не были найдены и были автоматически удалены из списка для сравнения:
                        {{ compareResponse.notFoundSessionIds.join(', ') }}
                    </Warning>
                    <div v-if="compareResponse.compareResults && compareResponse.compareResults.length > 0">
                        <Note>
                            Текст указывает на процент совпадающих свойств сессий, цвет - на уровень достоверности
                            (полноты) сравнения:
                            <span class="credibility-lowest">самый низкий</span>,
                            <span class="credibility-low">низкий</span>,
                            <span class="credibility-medium">средний</span>,
                            <span class="credibility-high">высокий</span> и
                            <span class="credibility-highest">самый высокий</span>.
                        </Note>

                        <div class="web-sessions-compare-results-scroller">
                            <table class="web-sessions-compare-results">
                                <tbody>
                                    <!-- первый ряд - заголовок с идентификаторами сессий -->
                                    <tr>
                                        <!-- первая клетка пустая под вертикальный столбец идентификаторов -->
                                        <th></th>
                                        <th
                                            v-for="webSessionId in webSessionIds"
                                            v-bind:key="webSessionId"
                                            v-bind:data-session-id2="webSessionId">
                                            <div class="web-session-compare-exclude-header">
                                                <div>{{ webSessionId }}</div>
                                                <div
                                                    class="web-session-compare-exclude"
                                                    title="Убрать сессию из сравнения"
                                                    v-on:click="excludeWebSession(webSessionId)" />
                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-for="webSessionId1 in webSessionIds" v-bind:key="webSessionId1">
                                        <!-- первая клетка - идентификатор сессии -->
                                        <th v-bind:data-session-id1="webSessionId1">
                                            <div class="web-session-compare-exclude-header">
                                                <div>{{ webSessionId1 }}</div>
                                                <div
                                                    class="web-session-compare-exclude"
                                                    title="Убрать сессию из сравнения"
                                                    v-on:click="excludeWebSession(webSessionId1)" />
                                            </div>
                                        </th>
                                        <td
                                            v-for="webSessionId2 in webSessionIds"
                                            v-bind:key="webSessionId2"
                                            v-bind:class="getCellClasses(webSessionId1, webSessionId2)"
                                            v-bind:data-session-id1="webSessionId1"
                                            v-bind:data-session-id2="webSessionId2"
                                            v-on:click="showDetailedCompare($event.target)">
                                            {{ getCellText(webSessionId1, webSessionId2) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ButtonsBlock>
                            <Button text="Очистить сравнение" destructive v-on:click="clearComparisonClickHandler" />
                        </ButtonsBlock>

                        <h2>Детали сравнения</h2>
                        <div v-if="detailedCompare == undefined">
                            Кликните по результату в таблице для получения детальной информации.
                        </div>
                        <div v-else>
                            <ul>
                                <li>
                                    Совпадение свойств: {{ `${detailedCompare.fingerprintMatch.matchRatio * 100}%` }}
                                </li>
                                <li>Уровень достоверности: {{ detailedCompare.fingerprintMatch.matchCredibility }}</li>
                                <li>Совпавших свойств: {{ detailedCompare.fingerprintMatch.matchedProperties }}</li>
                                <li>Общих свойств: {{ detailedCompare.fingerprintMatch.mutualProperties }}</li>
                                <li>Всего свойств: {{ detailedCompare.fingerprintMatch.totalProperties }}</li>
                            </ul>
                            <WrappedContent>
                                <WebSessionCompareView
                                    v-bind:webSession="getWebSession(detailedCompare.webSessionId1)" />
                                <WebSessionCompareView
                                    v-bind:webSession="getWebSession(detailedCompare.webSessionId2)" />
                            </WrappedContent>
                        </div>
                    </div>
                </template>
            </RequestWrapper>
        </div>
        <div v-else>Добавьте 2 и более веб-сессий к сравнению, чтобы активировать эту функцию.</div>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import Warning from '../../../misc/Warning.vue'
import Note from '../../../misc/Note.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import Button from '../../../inputs/Button.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import WebSessionCompareView from './WebSessionCompareView.vue'
import { postAuthorizedRequest } from '../../../../common.js'

import {
    getSessionsCompareArray,
    setSessionsCompareArray,
    getIsSessionsCompareAvailable,
} from './compare-fingerprints.js'

export default {
    name: 'WebSessionsCompare',

    components: {
        Warning,
        Note,
        RequestWrapper,
        ButtonsBlock,
        Button,
        WrappedContent,
        WebSessionCompareView,
    },

    data: function() {
        return {
            compareResponse: undefined,
            compareResponseErrorText: undefined,
            compareResponseErrorCode: undefined,
            compareAvailable: getIsSessionsCompareAvailable(),
            detailedCompare: undefined,
        }
    },

    created: function() {
        this.$emit('update:layout', Layout)

        var request = {
            sessionIds: getSessionsCompareArray(),
        }

        if (!this.compareAvailable) return

        postAuthorizedRequest('/api/telemetry/web-sessions/compare', request)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => {
                            this.compareResponse = data

                            if (data.notFoundSessionIds && data.notFoundSessionIds.length > 0) {
                                var sessionIds = getSessionsCompareArray()
                                data.notFoundSessionIds.forEach(id => (sessionIds = sessionIds.filter(i => i != id)))
                                setSessionsCompareArray(sessionIds)
                            }
                        })
                        break
                    default:
                        this.clearComparison()
                        this.compareResponseErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.compareResponseErrorText = error))
    },

    computed: {
        webSessionIds: function() {
            return this.compareResponse.sessions.map(s => s.webSessionId)
        },
    },

    methods: {
        getCompareResult: function(id1, id2) {
            return this.compareResponse.compareResults.find(
                e =>
                    (e.webSessionId1 == id1 && e.webSessionId2 == id2) ||
                    (e.webSessionId1 == id2 && e.webSessionId2 == id1)
            )
        },

        getCellText: function(id1, id2) {
            var result = this.getCompareResult(id1, id2)
            if (!result) return ''
            return `${result.fingerprintMatch.matchRatio * 100}%`
        },

        getCellClasses: function(id1, id2) {
            var result = this.getCompareResult(id1, id2)
            if (!result) return { empty: true }
            var credibility = result.fingerprintMatch.matchCredibility
            if (credibility > 0.9) return { 'credibility-highest': true }
            else if (credibility >= 0.8) return { 'credibility-high': true }
            else if (credibility >= 0.5) return { 'credibility-medium': true }
            else if (credibility >= 0.3) return { 'credibility-low': true }
            else return { 'credibility-lowest': true }
        },

        showDetailedCompare: function(cell) {
            var id1 = cell.getAttribute('data-session-id1')
            var id2 = cell.getAttribute('data-session-id2')
            if (id1 == id2) return

            this.deselectAllCells()

            this.detailedCompare = this.getCompareResult(id1, id2)
            var cells = document.querySelectorAll(
                `.web-sessions-compare-results *[data-session-id1="${id1}"], ` +
                    `.web-sessions-compare-results *[data-session-id2="${id2}"]`
            )
            cells.forEach(c => c.classList.add('highlighted'))

            cell.classList.add('selected')
        },

        deselectAllCells: function() {
            var cells = document.querySelectorAll('.web-sessions-compare-results *')
            cells.forEach(function(c) {
                c.classList.remove('highlighted')
                c.classList.remove('selected')
            })
        },

        getWebSession: function(id) {
            return this.compareResponse.sessions.find(s => s.webSessionId == id)
        },

        excludeWebSession: function(id) {
            this.deselectAllCells()
            this.detailedCompare = undefined
            this.compareResponse.compareResults = this.compareResponse.compareResults.filter(
                r => r.webSessionId1 != id && r.webSessionId2 != id
            )
            this.compareResponse.sessions = this.compareResponse.sessions.filter(s => s.webSessionId != id)
            setSessionsCompareArray(getSessionsCompareArray().filter(i => i != id))
            this.compareAvailable = getIsSessionsCompareAvailable()
        },

        clearComparison: function() {
            var empty = []
            setSessionsCompareArray(empty)
            this.compareAvailable = false
        },

        clearComparisonClickHandler: function() {
            this.clearComparison()
            this.$router.replace('/telemetry/web-sessions')
        },
    },
}
</script>

<style scoped>
.web-sessions-compare-results-scroller {
    width: 100%;
    overflow-x: auto;
}

.web-sessions-compare-results {
    margin-bottom: 20px;
    border-spacing: 0px;
    border-collapse: collapse;
}

th,
td {
    padding: 4px 10px;
    text-align: center;
}

tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

th:not(:last-child),
td:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}

th {
    font-weight: normal;
    color: #ccc;
    vertical-align: central;
}

.empty {
    background: #181818;
    cursor: not-allowed;
}

td:not(.empty):hover {
    cursor: pointer;
}

.screen-l td:not(.empty):hover {
    text-decoration: underline;
}

td:not(.empty):not(.selected):not(.highlighted):hover {
    background: #333;
}

.credibility-highest {
    color: #2f6;
    text-shadow: 0px 0px 4px #0d4;
}

.credibility-high {
    color: #7b5;
}

.credibility-medium {
    color: #aa0;
}

.credibility-low {
    color: #a50;
}

.credibility-lowest {
    color: #911;
}

.selected {
    background: #384554 !important;
}

.highlighted {
    background: #222933;
}

.web-session-compare-exclude-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.web-session-compare-exclude {
    margin-left: 5px;
    width: 18px;
    height: 1.5em;
    background-image: url('../../../../assets/telemetry/web-sessions/session-compare-exclude.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.6;
}

.web-session-compare-exclude:hover {
    cursor: pointer;
    opacity: 1;
}
</style>