<template>
    <div class="web-session-compare-view">
        <h3>
            <router-link v-bind:to="`/telemetry/web-sessions/${webSession.webSessionId}/view`"
                >Сессия #{{ webSession.webSessionId }}</router-link
            >
        </h3>

        <ul>
            <li>Добавлена: <DateTime v-bind:dt="webSession.added" /></li>
            <li>Обновлена: <DateTime v-bind:dt="webSession.updated" /></li>
            <li>Внешний ID сессии: {{ webSession.externalId }}</li>
            <li>Внешний ID пользователя: {{ webSession.userTrackExternalId }}</li>
            <li>IP-адрес: <IpAddressPopupMenu v-bind:ip="webSession.ipAddress" /></li>
            <li>Сайт: {{ webSession.site }}</li>
            <li>User-Agent: {{ webSession.userAgent }}</li>
            <li>Referer: <ExternalLink v-bind:url="webSession.referer" /></li>
        </ul>

        <h3>Свойства</h3>
        <RequestWrapper
            v-bind:data="webSessionProperties"
            v-bind:errorText="webSessionPropertiesErrorText"
            v-bind:errorCode="webSessionPropertiesErrorCode">
            <template v-slot:default>
                <table class="web-session-properties">
                    <tbody>
                        <tr
                            v-for="webSessionProperty in webSessionProperties"
                            v-bind:key="webSessionProperty.webSessionPropertyId">
                            <td>{{ webSessionProperty.name }}</td>
                            <td>{{ webSessionProperty.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import DateTime from '../../../misc/DateTime.vue'
import ExternalLink from '../../../misc/ExternalLink.vue'
import IpAddressPopupMenu from '../../reputation/IpAddressPopupMenu.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'WebSessionCompareView',

    components: {
        DateTime,
        ExternalLink,
        IpAddressPopupMenu,
        RequestWrapper,
    },

    props: {
        webSession: {
            type: Object,
            required: true,
        },
    },

    data: function() {
        return {
            webSessionProperties: undefined,
            webSessionPropertiesErrorText: undefined,
            webSessionPropertiesErrorCode: undefined,
        }
    },

    created: function() {
        this.loadProperties()
    },

    watch: {
        webSession: function() {
            this.loadProperties()
        },
    },

    methods: {
        loadProperties: function() {
            this.webSessionProperties = undefined

            getAuthorizedRequest(`/api/telemetry/web-sessions/${this.webSession.webSessionId}/properties`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.webSessionProperties = data))
                            break
                        default:
                            this.webSessionPropertiesErrorCode = res.status
                            break
                    }
                })
                .catch(error => (this.webSessionPropertiesErrorText = error))
        },
    },
}
</script>

<style scoped>
h3 {
    margin-top: 0px;
}

.web-session-compare-view {
    padding: 15px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #181818;
}

.web-session-properties {
    border-spacing: 0px;
    border-collapse: collapse;
}

.web-session-properties td {
    padding: 2px 5px;
}

.web-session-properties tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
</style>