<template>
    <div class="session">
        <div class="session-info">
            <div class="session-started">{{ new Date(session.started).toLocaleString() }}</div>
            <div class="session-ip">{{ session.ipAddress }}</div>
            <div class="session-browser">
                {{ userAgentParsed.browser_name }} ({{ userAgentParsed.operating_system_name }})
            </div>
        </div>
        <Button
            v-bind:text="session.isCurrent ? 'Текущая сессия' : 'Прервать'"
            v-bind:enabled="!session.isCurrent"
            destructive
            v-bind:failureText="deleteFailureText"
            v-on:click="deleteClickHandler" />
    </div>
</template>

<script>
import Button from '../../../inputs/Button.vue'
import { parseUserAgent } from 'parse-user-agent'

export default {
    name: 'Session',
    components: {
        Button,
    },
    props: {
        session: Object,
        deleteFailureText: {
            type: String,
            default: '',
        },
    },
    computed: {
        userAgentParsed: function() {
            return parseUserAgent(this.session.userAgent)
        },
    },
    methods: {
        deleteClickHandler: function(callback) {
            this.$emit('deleteClick', () => callback())
        },
    },
}
</script>

<style scoped>
.session {
    padding: 15px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #181818;
}

.session-info {
    margin-bottom: 20px;
}

.session-info > div {
    margin-bottom: 5px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: 0px center;
    background-size: auto 80%;
}

.session-started {
    background-image: url('../../../../assets/administration/users/session-started.svg');
}

.session-ip {
    background-image: url('../../../../assets/administration/users/session-ip.svg');
}

.session-browser {
    background-image: url('../../../../assets/administration/users/session-browser.svg');
}
</style>