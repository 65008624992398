<template>
    <div>
        <h1 id="title-source">История статистики веб-сессий ASN-блока {{ startIp }} – {{ endIp }}</h1>

        <RequestWrapper v-bind:data="stats" v-bind:errorText="statsErrorText" v-bind:errorCode="statsErrorCode">
            <template v-slot:default>
                <StatHistoryList v-bind:stats="stats" />
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import StatHistoryList from './StatHistoryList.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'AsnBlockStatHistoryPage',
    components: {
        StatHistoryList,
        RequestWrapper,
    },
    data: function() {
        return {
            startIp: this.$route.params.startIp,
            endIp: this.$route.params.endIp,
            stats: undefined,
            statsErrorText: undefined,
            statsErrorCode: undefined,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/reputation/stats/asn-blocks/${this.startIp}-${this.endIp}/history`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.stats = data))
                        break
                    default:
                        this.statsErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.statsErrorText = error))
    },
}
</script>