<template>
    <div>
        <h1 id="title-source">Детали ордера #{{ orderId }}</h1>

        <RequestWrapper v-bind:data="order" v-bind:errorText="orderErrorText" v-bind:errorCode="orderErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Дата и время добавления">
                        <DateTime v-bind:dt="order.added" />
                    </ItemDetail>
                    <ItemDetail name="Дата и время покупки">
                        <DateTime v-bind:dt="order.purchased" />
                    </ItemDetail>
                    <ItemDetail name="Статус">
                        <OrderStatus v-bind:order="order" />
                    </ItemDetail>
                    <ItemDetail name="Клиент">
                        <router-link v-bind:to="`/licensing/customers/${order.customer.customerId}/view`">
                            {{ order.customer.email }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Продукт">
                        <router-link v-bind:to="`/licensing/products/${order.product.productId}/view`">
                            {{ order.product.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Тип лицензии">
                        <router-link v-bind:to="`/licensing/license-types/${order.licenseType.licenseTypeId}/view`">
                            {{ order.licenseType.name }}
                        </router-link>
                    </ItemDetail>
                    <ItemDetail name="Количество лицензий">
                        {{ order.licensesQuantity }}
                    </ItemDetail>
                    <ItemDetail name="Регистратор">
                        <router-link
                            v-if="order.registrator != undefined"
                            v-bind:to="`/licensing/registrators/${order.registrator.registratorId}/view`">
                            {{ order.registrator.name }}
                        </router-link>
                        <div v-else>Не указан</div>
                    </ItemDetail>
                    <ItemDetail name="Идентификатор у регистратора" v-if="order.registratorOrderId != undefined">
                        {{ order.registratorOrderId || 'Не указан' }}
                    </ItemDetail>
                    <ItemDetail name="Комментарий">
                        {{ order.comment || '&lt; Пусто &gt;' }}
                    </ItemDetail>
                    <ItemDetail name="Тестовый ордер">
                        {{ order.isTest ? 'Да' : 'Нет' }}
                    </ItemDetail>
                </WrappedContent>
                <ButtonsBlock class="access-level-support">
                    <Button
                        text="Выписать лицензию"
                        v-bind:enabled="order.product.protectionProvider != 'None'"
                        v-bind:failureText="createLicenseFailure"
                        v-on:click="createLicenseHandler" />
                    <Button text="Редактировать" v-on:click="$router.push(`/licensing/orders/${orderId}/edit`)" />
                    <Button
                        text="Удалить"
                        destructive
                        v-on:click="$router.push(`/licensing/orders/${orderId}/delete`)" />
                </ButtonsBlock>

                <h2>Поиск</h2>
                <ul>
                    <li>
                        <router-link v-bind:to="`/licensing/licenses?mode=search&orderId=${orderId}`">
                            Лицензии, выписанные на этот ордер
                        </router-link>
                    </li>
                    <li class="access-level-admin">
                        <router-link
                            v-bind:to="`/administration/audit-entries?mode=search&path=/api/licensing/orders/${orderId}`">
                            Записи аудита на этот ордер
                        </router-link>
                    </li>
                </ul>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../../layout/Layout.vue'
import WrappedContent from '../../../misc/WrappedContent.vue'
import ItemDetail from '../../ItemDetail.vue'
import DateTime from '../../../misc/DateTime.vue'
import OrderStatus from './OrderStatus.vue'
import Button from '../../../inputs/Button.vue'
import ButtonsBlock from '../../../misc/ButtonsBlock.vue'
import RequestWrapper from '../../../misc/RequestWrapper.vue'
import { getAuthorizedRequest, postAuthorizedRequest } from '../../../../common.js'

export default {
    name: 'OrderViewPage',
    components: {
        WrappedContent,
        ItemDetail,
        DateTime,
        OrderStatus,
        Button,
        ButtonsBlock,
        RequestWrapper,
    },
    data: function() {
        return {
            orderId: this.$route.params.id,
            order: undefined,
            orderErrorText: undefined,
            orderErrorCode: undefined,
            createLicenseFailure: '',
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/licensing/orders/${this.orderId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.order = data))
                        break
                    default:
                        this.orderErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.orderErrorText = error))
    },
    methods: {
        createLicenseHandler: function(callback) {
            this.createLicenseFailure = ''

            postAuthorizedRequest(`/api/licensing/orders/${this.orderId}/license`).then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => this.$router.push(`/licensing/licenses/${data.licenseId}/view`))
                        break
                    default:
                        this.createLicenseFailure = `Плохой ответ на запрос (${res.status})`
                        callback()
                        break
                }
            })
        },
    },
}
</script>