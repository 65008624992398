<template>
    <div>
        <ItemsList>
            <div class="item" v-for="stat in stats" v-bind:key="stat.analysisSessionId">
                <div class="item-details">
                    <DateTime v-bind:dt="stat.added" />
                </div>
                <div class="item-links">
                    <div>
                        <StatsWidget v-bind:stat="stat" />
                    </div>
                </div>
            </div>
        </ItemsList>
    </div>
</template>

<script>
import ItemsList from '../../ItemsList.vue'
import StatsWidget from './StatsWidget.vue'
import DateTime from '../../../misc/DateTime.vue'

export default {
    name: 'StatHistoryList',
    components: {
        ItemsList,
        StatsWidget,
        DateTime,
    },
    props: {
        stats: {
            type: Array,
            default: undefined,
        },
    },
}
</script>