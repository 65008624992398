<template>
    <div>
        <RequestWrapper
            v-bind:data="reporters"
            v-bind:errorText="reportersErrorText"
            v-bind:errorCode="reportersErrorCode">
            <template v-slot:default>
                <div v-if="reporters.length > 0">
                    <Reporter
                        v-for="reporter in reporters"
                        v-bind:key="reporter.name"
                        v-bind:apiBasePath="`${apiBasePath}/${reporter.name}`" />
                </div>
                <div v-else>
                    <i>Нет генераторов отчётов.</i>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Reporter from './Reporter.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../common.js'

export default {
    name: 'ReportersList',

    components: {
        Reporter,
        RequestWrapper,
    },

    props: {
        apiBasePath: {
            type: String,
            required: true,
        },
    },

    data: function() {
        return {
            reporters: undefined,
            reportersErrorText: undefined,
            reportersErrorCode: undefined,
        }
    },

    created: function() {
        getAuthorizedRequest(this.apiBasePath)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.reporters = data))
                        break
                    default:
                        this.reportersErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.reportersErrorText = error))
    },
}
</script>