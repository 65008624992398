import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Error from './components/pages/main/Error.vue'
import Signin from './components/pages/main/Signin.vue'
import SetPassword from './components/pages/main/SetPassword.vue'
import Index from './components/pages/main/Index.vue'
import Me from './components/pages/main/Me.vue'
import Changes from './components/pages/main/Changes.vue'
import Orders from './components/pages/licensing/orders/Orders.vue'
import OrderView from './components/pages/licensing/orders/OrderView.vue'
import OrderEdit from './components/pages/licensing/orders/OrderEdit.vue'
import OrderDelete from './components/pages/licensing/orders/OrderDelete.vue'
import Customers from './components/pages/licensing/customers/Customers.vue'
import CustomerView from './components/pages/licensing/customers/CustomerView.vue'
import CustomerEdit from './components/pages/licensing/customers/CustomerEdit.vue'
import CustomerDelete from './components/pages/licensing/customers/CustomerDelete.vue'
import Licenses from './components/pages/licensing/licenses/Licenses.vue'
import LicenseView from './components/pages/licensing/licenses/LicenseView.vue'
import LicenseDelete from './components/pages/licensing/licenses/LicenseDelete.vue'
import LicenseTypes from './components/pages/licensing/license-types/LicenseTypes.vue'
import LicenseTypeView from './components/pages/licensing/license-types/LicenseTypeView.vue'
import LicenseTypeEdit from './components/pages/licensing/license-types/LicenseTypeEdit.vue'
import LicenseTypeDelete from './components/pages/licensing/license-types/LicenseTypeDelete.vue'
import Products from './components/pages/licensing/products/Products.vue'
import ProductView from './components/pages/licensing/products/ProductView.vue'
import ProductEdit from './components/pages/licensing/products/ProductEdit.vue'
import ProductDelete from './components/pages/licensing/products/ProductDelete.vue'
import ProductDomains from './components/pages/licensing/product-domains/ProductDomains.vue'
import ProductDomainView from './components/pages/licensing/product-domains/ProductDomainView.vue'
import ProductDomainEdit from './components/pages/licensing/product-domains/ProductDomainEdit.vue'
import ProductDomainDelete from './components/pages/licensing/product-domains/ProductDomainDelete.vue'
import ProductMappings from './components/pages/licensing/product-mappings/ProductMappings.vue'
import ProductMappingView from './components/pages/licensing/product-mappings/ProductMappingView.vue'
import ProductMappingEdit from './components/pages/licensing/product-mappings/ProductMappingEdit.vue'
import ProductMappingDelete from './components/pages/licensing/product-mappings/ProductMappingDelete.vue'
import ProductParsings from './components/pages/licensing/product-parsings/ProductParsings.vue'
import ProductParsingView from './components/pages/licensing/product-parsings/ProductParsingView.vue'
import ProductParsingEdit from './components/pages/licensing/product-parsings/ProductParsingEdit.vue'
import ProductParsingDelete from './components/pages/licensing/product-parsings/ProductParsingDelete.vue'
import Registrators from './components/pages/licensing/registrators/Registrators.vue'
import RegistratorView from './components/pages/licensing/registrators/RegistratorView.vue'
import RegistratorEdit from './components/pages/licensing/registrators/RegistratorEdit.vue'
import RegistratorDelete from './components/pages/licensing/registrators/RegistratorDelete.vue'
import Tools from './components/pages/licensing/tools/Tools.vue'
import ToolProductIdentification from './components/pages/licensing/tools/ToolProductIdentification.vue'
import ToolRegistrationCode from './components/pages/licensing/tools/ToolRegistrationCode.vue'
import Emails from './components/pages/notification/emails/Emails.vue'
import EmailView from './components/pages/notification/emails/EmailView.vue'
import WebSessions from './components/pages/telemetry/web-sessions/WebSessions.vue'
import WebSessionView from './components/pages/telemetry/web-sessions/WebSessionView.vue'
import WebSessionsCompare from './components/pages/telemetry/web-sessions/WebSessionsCompare.vue'
import IpInfo from './components/pages/ipinfo/IpInfo.vue'
import AsnView from './components/pages/ipinfo/AsnView.vue'
import IpAddressView from './components/pages/ipinfo/IpAddressView.vue'
import Reputation from './components/pages/reputation/Reputation.vue'
import AsnStats from './components/pages/reputation/stats/AsnStats.vue'
import AsnStatHistory from './components/pages/reputation/stats/AsnStatHistory.vue'
import AsnBlockStats from './components/pages/reputation/stats/AsnBlockStats.vue'
import AsnBlockStatHistory from './components/pages/reputation/stats/AsnBlockStatHistory.vue'
import StaticBlockStats from './components/pages/reputation/stats/StaticBlockStats.vue'
import StaticBlockStatHistory from './components/pages/reputation/stats/StaticBlockStatHistory.vue'
import IpAddressStats from './components/pages/reputation/stats/IpAddressStats.vue'
import IpAddressStatHistory from './components/pages/reputation/stats/IpAddressStatHistory.vue'
import ListedAsns from './components/pages/reputation/lists/ListedAsns.vue'
import ListedIpAddressBlocks from './components/pages/reputation/lists/ListedIpAddressBlocks.vue'
import IpAddressStatusView from './components/pages/reputation/statuses/IpAddressStatusView.vue'
import Reporters from './components/pages/misc/Reporters.vue'
import Maintainers from './components/pages/misc/Maintainers.vue'
import Users from './components/pages/administration/users/Users.vue'
import UserView from './components/pages/administration/users/UserView.vue'
import UserEdit from './components/pages/administration/users/UserEdit.vue'
import UserDelete from './components/pages/administration/users/UserDelete.vue'
import AuditEntries from './components/pages/administration/audit/AuditEntries.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/signin',
            component: Signin
        },
        {
            path: '/invitation/:tokenId',
            component: SetPassword,
            props: {
                mode: 'invitation'
            }
        },
        {
            path: '/recovery/:tokenId',
            component: SetPassword,
            props: {
                mode: 'recovery'
            }
        },
        {
            path: '/',
            component: Index
        },
        {
            path: '/me',
            component: Me
        },
        {
            path: '/changes',
            component: Changes
        },
        {
            path: '/licensing/orders',
            component: Orders
        },
        {
            path: '/licensing/orders/create',
            component: OrderEdit
        },
        {
            path: '/licensing/orders/:id/view',
            component: OrderView
        },
        {
            path: '/licensing/orders/:id/edit',
            component: OrderEdit
        },
        {
            path: '/licensing/orders/:id/delete',
            component: OrderDelete
        },
        {
            path: '/licensing/customers',
            component: Customers
        },
        {
            path: '/licensing/customers/create',
            component: CustomerEdit
        },
        {
            path: '/licensing/customers/:id/view',
            component: CustomerView
        },
        {
            path: '/licensing/customers/:id/edit',
            component: CustomerEdit
        },
        {
            path: '/licensing/customers/:id/delete',
            component: CustomerDelete
        },
        {
            path: '/licensing/licenses',
            component: Licenses
        },
        {
            path: '/licensing/licenses/:id/view',
            component: LicenseView
        },
        {
            path: '/licensing/licenses/:id/delete',
            component: LicenseDelete
        },
        {
            path: '/licensing/license-types',
            component: LicenseTypes
        },
        {
            path: '/licensing/license-types/create',
            component: LicenseTypeEdit
        },
        {
            path: '/licensing/license-types/:id/view',
            component: LicenseTypeView
        },
        {
            path: '/licensing/license-types/:id/edit',
            component: LicenseTypeEdit
        },
        {
            path: '/licensing/license-types/:id/delete',
            component: LicenseTypeDelete
        },
        {
            path: '/licensing/products',
            component: Products
        },
        {
            path: '/licensing/products/create',
            component: ProductEdit
        },
        {
            path: '/licensing/products/:id/view',
            component: ProductView
        },
        {
            path: '/licensing/products/:id/edit',
            component: ProductEdit
        },
        {
            path: '/licensing/products/:id/delete',
            component: ProductDelete
        },
        {
            path: '/licensing/product-domains',
            component: ProductDomains
        },
        {
            path: '/licensing/product-domains/create',
            component: ProductDomainEdit
        },
        {
            path: '/licensing/product-domains/:id/view',
            component: ProductDomainView
        },
        {
            path: '/licensing/product-domains/:id/edit',
            component: ProductDomainEdit
        },
        {
            path: '/licensing/product-domains/:id/delete',
            component: ProductDomainDelete
        },
        {
            path: '/licensing/product-mappings',
            component: ProductMappings
        },
        {
            path: '/licensing/product-mappings/create',
            component: ProductMappingEdit
        },
        {
            path: '/licensing/product-mappings/:id/view',
            component: ProductMappingView
        },
        {
            path: '/licensing/product-mappings/:id/edit',
            component: ProductMappingEdit
        },
        {
            path: '/licensing/product-mappings/:id/delete',
            component: ProductMappingDelete
        },
        {
            path: '/licensing/product-parsings',
            component: ProductParsings
        },
        {
            path: '/licensing/product-parsings/create',
            component: ProductParsingEdit
        },
        {
            path: '/licensing/product-parsings/:id/view',
            component: ProductParsingView
        },
        {
            path: '/licensing/product-parsings/:id/edit',
            component: ProductParsingEdit
        },
        {
            path: '/licensing/product-parsings/:id/delete',
            component: ProductParsingDelete
        },
        {
            path: '/licensing/registrators',
            component: Registrators
        },
        {
            path: '/licensing/registrators/create',
            component: RegistratorEdit
        },
        {
            path: '/licensing/registrators/:id/view',
            component: RegistratorView
        },
        {
            path: '/licensing/registrators/:id/edit',
            component: RegistratorEdit
        },
        {
            path: '/licensing/registrators/:id/delete',
            component: RegistratorDelete
        },
        {
            path: '/licensing/tools',
            component: Tools
        },
        {
            path: '/licensing/tools/product-identification',
            component: ToolProductIdentification
        },
        {
            path: '/licensing/tools/registration-code',
            component: ToolRegistrationCode
        },
        {
            path: '/notification/emails',
            component: Emails
        },
        {
            path: '/notification/emails/:id/view',
            component: EmailView
        },
        {
            path: '/telemetry/web-sessions',
            component: WebSessions
        },
        {
            path: '/telemetry/web-sessions/:id/view',
            component: WebSessionView
        },
        {
            path: '/telemetry/web-sessions/compare',
            component: WebSessionsCompare
        },
        {
            path: '/ipinfo',
            component: IpInfo
        },
        {
            path: '/ipinfo/asns/:asnId',
            component: AsnView
        },
        {
            path: '/ipinfo/ips/:ip',
            component: IpAddressView
        },
        {
            path: '/reputation',
            component: Reputation
        },
        {
            path: '/reputation/stats/asns',
            component: AsnStats
        },
        {
            path: '/reputation/stats/asns/:asnId/history',
            component: AsnStatHistory
        },
        {
            path: '/reputation/stats/asn-blocks',
            component: AsnBlockStats
        },
        {
            path: '/reputation/stats/asn-blocks/:startIp-:endIp/history',
            component: AsnBlockStatHistory
        },
        {
            path: '/reputation/stats/static-blocks',
            component: StaticBlockStats
        },
        {
            path: '/reputation/stats/static-blocks/:startIp-:endIp/history',
            component: StaticBlockStatHistory
        },
        {
            path: '/reputation/stats/ips',
            component: IpAddressStats
        },
        {
            path: '/reputation/stats/ips/:ip/history',
            component: IpAddressStatHistory
        },
        {
            path: '/reputation/lists/asns/:listType',
            component: ListedAsns
        },
        {
            path: '/reputation/lists/ips/:listType',
            component: ListedIpAddressBlocks
        },
        {
            path: '/reputation/statuses/ips/:ip',
            component: IpAddressStatusView
        },
        {
            path: '/misc/reporters',
            component: Reporters
        },
        {
            path: '/misc/maintainers',
            component: Maintainers
        },
        {
            path: '/administration/users',
            component: Users
        },
        {
            path: '/administration/users/create',
            component: UserEdit
        },
        {
            path: '/administration/users/:id/view',
            component: UserView
        },
        {
            path: '/administration/users/:id/edit',
            component: UserEdit
        },
        {
            path: '/administration/users/:id/delete',
            component: UserDelete
        },
        {
            path: '/administration/audit-entries',
            component: AuditEntries
        },
        {
            path: '/error/:code',
            component: Error
        },
        {
            path: '*',
            component: Error
        }
    ]
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')