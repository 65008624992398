<template>
    <div class="buttons-block">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ButtonsBlock',
}
</script>

<style scoped>
.buttons-block {
    margin: 15px 0px;
}

.buttons-block .button:not(:last-child) {
    margin-bottom: 15px;
}
</style>
