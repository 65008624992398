<template>
    <div v-if="asnId">
        <h2>Меню ASN</h2>
        <ul>
            <li>
                <router-link v-bind:to="asnsStatsSearchUri">Статистика веб-сессий</router-link>
            </li>
            <li>
                <router-link v-bind:to="asnBlocksStatsSearchUri">Статистика веб-сессий по блокам</router-link>
            </li>
            <li>
                <router-link v-bind:to="ipsStatsSearchUri">Статистика веб-сессий по IP-адресам</router-link>
            </li>
            <li>
                <router-link v-bind:to="statsHistoryUri">История статистики веб-сессий</router-link>
            </li>
        </ul>
        <ul>
            <li>
                <router-link v-bind:to="whitelistSearchUri">Искать в белом списке</router-link>
            </li>
            <li>
                <router-link v-bind:to="blacklistSearchUri">Искать в чёрном списке</router-link>
            </li>
            <li>
                <router-link v-bind:to="whitelistCreateUri">Внести в белый список</router-link>
            </li>
            <li>
                <router-link v-bind:to="blacklistCreateUri">Внести в чёрный список</router-link>
            </li>
        </ul>
        <ul v-if="path != ipInfoUri">
            <li>
                <router-link v-bind:to="ipInfoUri">Посмотреть IP-информацию</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'AsnMenu',

    props: {
        asnId: Number,
    },

    data: function() {
        return {
            asnsStatsSearchUri: `/reputation/stats/asns?mode=search&asnId=${this.asnId}`,
            asnBlocksStatsSearchUri: `/reputation/stats/asn-blocks?mode=search&asnId=${this.asnId}`,
            ipsStatsSearchUri: `/reputation/stats/ips?mode=search&asnId=${this.asnId}`,
            statsHistoryUri: `/reputation/stats/asns/${this.asnId}/history`,
            whitelistSearchUri: `/reputation/lists/asns/white?mode=search&asnId=${this.asnId}`,
            blacklistSearchUri: `/reputation/lists/asns/black?mode=search&asnId=${this.asnId}`,
            whitelistCreateUri: `/reputation/lists/asns/white?mode=create&asnId=${this.asnId}`,
            blacklistCreateUri: `/reputation/lists/asns/black?mode=create&asnId=${this.asnId}`,
            ipInfoUri: `/ipinfo/asns/${this.asnId}`,
            path: window.location.pathname,
        }
    },
}
</script>