<template>
    <span>
        <a v-if="address" v-bind:href="`mailto:${address}`" class="email-address">
            {{ linkText }}
        </a>
        <span v-else> &lt; Нет адреса &gt; </span>
    </span>
</template>

<script>
export default {
    name: 'EmailAddressLink',
    props: {
        address: String,
        displayName: String,
    },
    computed: {
        linkText: function() {
            if (!this.displayName) return this.address
            return `${this.displayName} <${this.address}>`
        },
    },
}
</script>

<style scoped>
.email-address {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 80%;
    background-image: url('../../assets/misc/email.svg');
}
</style>
