<template>
    <div class="reputation-lists-impacts">
        <div class="reputation-lists-impacts-container">
            <div v-bind:class="whiteStyles" v-bind:title="whiteTitle">✔</div>
            <div v-bind:class="blackStyles" v-bind:title="blackTitle">✕</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListsImpactsWidget',

    props: {
        obj: {
            type: Object,
            required: true,
        },
    },

    computed: {
        whiteStyles: function() {
            var style = {}
            style['reputation-lists-impacts-status'] = true
            style[`reputation-lists-impacts-status-white-${this.obj.whitelistsImpact.toLowerCase()}`] = true
            return style
        },
        whiteTitle: function() {
            switch (this.obj.whitelistsImpact) {
                case 'Partial':
                    return 'Частично состоит в белых списках'
                case 'Full':
                    return 'Состоит в белых списках'
                default:
                    return 'Не состоит в белых списках'
            }
        },
        blackStyles: function() {
            var style = {}
            style['reputation-lists-impacts-status'] = true
            style[`reputation-lists-impacts-status-black-${this.obj.blacklistsImpact.toLowerCase()}`] = true
            return style
        },
        blackTitle: function() {
            switch (this.obj.blacklistsImpact) {
                case 'Partial':
                    return 'Частично заблокирован чёрными списками'
                case 'Full':
                    return 'Заблокирован чёрными списками'
                default:
                    return 'Не заблокирован чёрными списками'
            }
        },
    },
}
</script>

<style scoped>
.reputation-lists-impacts {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 2px;
    border-radius: 5px;
    background-color: #2f2f2f;
    user-select: none;
    color: #fff0;
    padding-left: 24px;
    background-repeat: no-repeat;
    background-position: 6px center;
    background-size: auto 0.9em;
    background-image: url('../../../../assets/reputation/lists/list.svg');
}

.reputation-lists-impacts-container {
    display: flex;
}

.reputation-lists-impacts-status {
    width: 24px;
    text-align: center;
    border-radius: 4px;
}

.reputation-lists-impacts-status + .reputation-lists-impacts-status {
    margin-left: 2px;
}

.reputation-lists-impacts-status-white-none {
    background-color: #444;
    color: #2f2f2f;
}

.reputation-lists-impacts-status-white-partial {
    background-image: repeating-linear-gradient(-45deg, #3b3, #3b3 2px, #0000 3px, #0000 5px, #3b3 6px);
}

.reputation-lists-impacts-status-white-full {
    background-color: #3b3;
    color: #000;
}

.reputation-lists-impacts-status-black-none {
    background-color: #444;
    color: #2f2f2f;
}

.reputation-lists-impacts-status-black-partial {
    background-image: repeating-linear-gradient(-45deg, #a11, #a11 2px, #0000 3px, #0000 5px, #a11 6px);
}

.reputation-lists-impacts-status-black-full {
    background-color: #a11;
    color: #eee;
}
</style>