<template>
    <div class="page-controller" v-if="pagination.itemsCount > 0">
        <div class="total">Всего: {{ pagination.itemsCount }}</div>
        <div class="pages">
            <div v-for="pageNumber in pageNumbers" v-bind:key="pageNumber" class="page-container">
                <div
                    class="page-link"
                    v-bind:class="{ 'page-link-current': pageNumber == pagination.page }"
                    v-if="pageNumber != null"
                    v-on:click.prevent="pageChangeHandler(pageNumber)">
                    {{ pageNumber }}
                </div>
                <div class="page-gap" v-else>...</div>
            </div>
        </div>
        <div class="page-sizes">
            <div
                v-for="pageSize in pageSizes"
                v-bind:key="pageSize"
                class="page-size"
                v-bind:class="{ 'page-size-current': pageSize == pagination.pageSize }"
                v-on:click.prevent="pageSizeChangeHandler(pageSize)">
                ≡ {{ pageSize }}
            </div>
        </div>
        <input
            type="number"
            v-bind:value="pagination.page"
            min="1"
            v-bind:max="pagination.pagesCount"
            v-bind:style="{ width: 'calc(' + String(pagination.pagesCount).length + 'em + 20px)' }"
            v-on:change="pageChangeHandler($event.target.value)" />
    </div>
</template>

<script>
export default {
    name: 'PageController',
    props: {
        pagination: Object,
    },
    data: function() {
        // текущая страница отображается всегда
        let pageNumbers = [this.pagination.page]

        // одна страница у начала и конца
        pageNumbers.push(1)
        pageNumbers.push(this.pagination.pagesCount)

        // одна страница до и после текущей
        pageNumbers.push(this.pagination.page - 1)
        pageNumbers.push(this.pagination.page + 1)

        // удаляем всё что вылезло на края
        for (let i = pageNumbers.length - 1; i >= 0; i--) {
            if (pageNumbers[i] >= 1 && pageNumbers[i] <= this.pagination.pagesCount) continue
            pageNumbers.splice(i, 1)
        }

        // сортируем и удаляем дубликаты
        pageNumbers = pageNumbers.sort((a, b) => a - b)
        pageNumbers = Array.from(new Set(pageNumbers))

        // добавляем "многоточия" в промежутках
        for (let i = pageNumbers.length - 2; i >= 0; i--) {
            let currPage = pageNumbers[i]
            let nextPage = pageNumbers[i + 1]
            if (nextPage - currPage === 1) continue
            pageNumbers.splice(i + 1, 0, null)
        }

        return {
            pageNumbers: pageNumbers,
            pageSizes: [10, 20, 50],
        }
    },
    methods: {
        pageChangeHandler: function(page) {
            if (page == this.pagination.page) return
            this.emitPageChange(page, this.pagination.pageSize)
        },
        pageSizeChangeHandler: function(pageSize) {
            if (pageSize == this.pagination.pageSize) return
            this.emitPageChange(this.pagination.page, pageSize)
        },
        emitPageChange: function(page, pageSize) {
            this.$emit('pageChange', page, pageSize)
        },
    },
}
</script>

<style scoped>
.page-controller {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.page-controller > * {
    margin-bottom: 5px;
}

.page-controller > *:not(:first-child) {
    margin-left: 10px;
}

.total {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.pages {
    max-width: 360px;
}

.pages,
.page-sizes {
    display: flex;
}

.page-container {
    display: flex;
}

.page-container > *,
.page-size {
    padding: 5px 5px 3px 5px;
    min-width: 20px;
    text-align: center;
    color: #718dab;
    border-bottom: 2px solid transparent;
}

.page-link,
.page-size {
    background-color: #181818;
}

.page-link:not(.page-link-current):hover,
.page-size:not(.page-size-current):hover {
    cursor: pointer;
}

.page-link:not(.page-current):hover,
.page-link-current,
.page-size:not(.page-size-current):hover,
.page-size-current {
    border-bottom-color: #0f7db8;
}

.page-link:not(.page-link-current):hover,
.page-size:not(.page-size-current):hover {
    color: #99bade;
    background-color: #12171a;
}

.page-gap {
    background-color: #111;
}

.page-container:first-child > *,
.page-size:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.page-container:last-child > *,
.page-size:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

input {
    flex-grow: 0;
    padding: 5px;
    font-size: 1em;
    color: #a2bfdb;
    background-color: #131a21;
    border: 2px solid #41556b;
    border-radius: 6px;
    outline: none;
}

input:hover {
    border-color: #7491b0;
}
</style>