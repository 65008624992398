<template>
    <div>
        <h1 id="title-source">ASN {{ asnId }}</h1>

        <RequestWrapper v-bind:data="asn" v-bind:errorText="asnErrorText" v-bind:errorCode="asnErrorCode">
            <template v-slot:default>
                <WrappedContent>
                    <ItemDetail name="Организация">
                        {{ asn.organization }}
                    </ItemDetail>
                </WrappedContent>

                <AsnMenu v-bind:asnId="asnId" />

                <h2>Блоки IP-адресов</h2>
                <div v-if="blocks == undefined">
                    Нажмите на кнопку для загрузки полного списка блоков и поиска статистики веб-сессий по отдельным
                    блокам.
                    <ButtonsBlock>
                        <Button
                            text="Загрузить список блоков"
                            v-on:click="loadBlocks"
                            v-bind:failureText="loadBlocksFailure" />
                    </ButtonsBlock>
                </div>
                <div v-else>
                    <ItemsList v-if="blocks.length > 0">
                        <div class="item" v-for="block in blocks" v-bind:key="block.asnBlockId">
                            <div class="item-details">{{ block.startIpAddress }} – {{ block.endIpAddress }}</div>
                            <div class="item-links">
                                <AsnBlockPopupMenu
                                    v-bind:startIp="block.startIpAddress"
                                    v-bind:endIp="block.endIpAddress"
                                    title="Меню" />
                            </div>
                        </div>
                    </ItemsList>
                    <div v-else>
                        <i>Записи о блоках отсутствуют.</i>
                    </div>
                </div>
            </template>
        </RequestWrapper>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'
import WrappedContent from '../../misc/WrappedContent.vue'
import ItemDetail from '../ItemDetail.vue'
import AsnMenu from '../reputation/AsnMenu.vue'
import Button from '../../inputs/Button.vue'
import ButtonsBlock from '../../misc/ButtonsBlock.vue'
import ItemsList from '../../pages/ItemsList.vue'
import AsnBlockPopupMenu from '../reputation/AsnBlockPopupMenu.vue'
import RequestWrapper from '../../misc/RequestWrapper.vue'
import { getAuthorizedRequest } from '../../../common.js'

export default {
    name: 'AsnViewPage',

    components: {
        WrappedContent,
        ItemDetail,
        AsnMenu,
        Button,
        ButtonsBlock,
        ItemsList,
        AsnBlockPopupMenu,
        RequestWrapper,
    },

    data: function() {
        return {
            asnId: Number(this.$route.params.asnId),
            asn: undefined,
            asnErrorText: undefined,
            asnErrorCode: undefined,
            blocks: undefined,
            loadBlocksFailure: '',
        }
    },

    created: function() {
        this.$emit('update:layout', Layout)

        getAuthorizedRequest(`/api/ipinfo/asns/${this.asnId}`)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(data => (this.asn = data))
                        break
                    default:
                        this.asnErrorCode = res.status
                        break
                }
            })
            .catch(error => (this.asnErrorText = error))
    },

    methods: {
        loadBlocks: function(callback) {
            this.loadBlocksFailure = ''

            getAuthorizedRequest(`/api/ipinfo/asns/${this.asnId}/blocks`)
                .then(res => {
                    switch (res.status) {
                        case 200:
                            res.json().then(data => (this.blocks = data))
                            break
                        default:
                            this.loadBlocksFailure = `Плохой ответ на запрос (${res.status})`
                            break
                    }
                })
                .catch(() => (this.loadBlocksFailure = 'Не удалось выполнить запрос'))
                .then(callback)
        },
    },
}
</script>

<style scoped>
.expandable ul {
    margin: 0px !important;
}
</style>