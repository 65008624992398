<template>
    <div>
        <h1 v-if="code != undefined" id="title-source">Ошибка {{ code }}</h1>
        <h1 v-else id="title-source">Запрошена неведомая страница</h1>
        <p>Что-то явно пошло не так :(</p>
        <p>
            Если эта ошибка серьёзно ломает ваши планы, и попытка повторить то что вы делали не увенчалась успехом, то
            не ждите, сообщите уже кому-нибудь об этом!
        </p>
    </div>
</template>

<script>
import Layout from '../../layout/Layout.vue'

export default {
    name: 'ErrorPage',
    data: function() {
        return {
            code: this.$route.params.code,
        }
    },
    created: function() {
        this.$emit('update:layout', Layout)
    },
}
</script>